<supy-grid
  class="supy-stock-movement-grid"
  primaryKey="id"
  [data]="stockMovement"
  [paginationMode]="paginationMode"
  [page]="requestMetadata.page"
  [perPage]="requestMetadata.limit"
  [prevPageDisabled]="requestMetadata.page === 0"
  [nextPageDisabled]="responseMetadata.count < requestMetadata.limit"
  (pageChange)="pageChange.emit($event)"
>
  <supy-grid-column header="Date" i18n-header="@@common.date" field="eventDate" width="15%" [sortable]="true">
    <ng-template supyGridCell let-value>
      {{ value | timezone: ianaTimeZone | date: 'dd/MM/yyyy' }}
    </ng-template>
  </supy-grid-column>
  <supy-grid-column header="Event Type" i18n-header="@@common.eventType" field="event" width="15%">
    <ng-template supyGridCell let-value let-rowData="rowData">
      <a *ngIf="getEventLink(rowData); else labelTmpl" href="{{ getEventLink(rowData) }}" target="_blank">
        <small
          class="supy-inventory-item-stock-movement__underlined"
          [title]="rowData.reference?.name?.en ?? getEventType(rowData)"
        >
          {{ getEventType(rowData) }}
        </small>
      </a>
      <ng-template #labelTmpl>{{ getEventType(rowData) }}</ng-template>
    </ng-template>
  </supy-grid-column>
  <supy-grid-column
    header="Event Cost ({{ currency }})"
    i18n-header="@@grid.headers.eventCost.label"
    field="cost"
    width="15%"
    [sortable]="true"
  >
    <ng-template supyGridCell let-rowData="rowData">
      {{ getComputedCost(rowData) | supyPrecision: currencyPrecision }}
    </ng-template>
  </supy-grid-column>
  <supy-grid-column
    header="Event Quantity"
    i18n-header="@@grid.headers.eventQuantity.label"
    field="quantity.change"
    width="15%"
    [sortable]="true"
  >
    <ng-template supyGridCell let-rowData="rowData">
      {{ getComputedQuantity(rowData) | supyPrecision }} {{ baseUnit.name }}
    </ng-template>
  </supy-grid-column>
  <supy-grid-column header="Item Cost ({{ currency }})" i18n-header="@@grid.headers.itemCost.label" width="15%">
    <ng-template supyGridCell let-rowData="rowData">
      {{ getComputedItemCost(rowData) | supyPrecision: currencyPrecision }}
    </ng-template>
  </supy-grid-column>
  <supy-grid-column header="Item Quantity" i18n-header="@@grid.headers.itemQuantity.label" width="15%">
    <ng-template supyGridCell let-rowData="rowData">
      {{ getComputedTotal(rowData) | supyPrecision: currencyPrecision }} {{ baseUnit.name }}
    </ng-template>
  </supy-grid-column>
  <supy-grid-column header="User" i18n-header="@@user" width="10%" field="createdBy">
    <ng-template supyGridCell let-value>{{ value?.firstName }} {{ value?.lastName?.[0] }}.</ng-template>
  </supy-grid-column>
</supy-grid>
