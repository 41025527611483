import produce from 'immer';

import { ViewBranch } from '@supy/common';
import { getLocalizedName } from '@supy/settings';

import {
  CreateInventoryLocationRequest,
  CreateInventoryStorageRequest,
  InventoryLocation,
  InventoryLocationGridItem,
  InventoryStorage,
  UpdateInventoryLocationRequest,
  UpdateInventoryStorageRequest,
  UpdateLocationWithNestedStorages,
} from '../core';

export class InventoryLocationsHelper {
  static checkStoragePriority(
    locations: InventoryLocationGridItem[],
    storage: InventoryLocationGridItem,
  ): InventoryLocationGridItem[] {
    const locationIndex = locations.findIndex(({ id }) => id === storage.branch.id);

    return produce(locations, draft => {
      draft[locationIndex].storages.forEach(newStorage => {
        newStorage.isPriority = newStorage.id === storage.id;
      });
    });
  }

  static activateLocationOrdering(
    locations: InventoryLocationGridItem[],
    location: InventoryLocationGridItem,
  ): InventoryLocationGridItem[] {
    const locationIndex = locations.findIndex(({ id }) => id === location.id);

    return produce(locations, draft => {
      draft[locationIndex].canOrder = true;
    });
  }

  static updateLocationStorage(
    locations: InventoryLocationGridItem[],
    storage: InventoryLocationGridItem,
  ): InventoryLocationGridItem[] {
    const locationIndex = locations.findIndex(({ branch }) => branch.id === storage.branch.id);
    const storageIndex = locations[locationIndex].storages.findIndex(({ id }) => id === storage.id);

    return produce(locations, draft => {
      draft[locationIndex].storages[storageIndex] = { ...storage };
    });
  }

  static addLocationStorages(
    locations: InventoryLocationGridItem[],
    location: InventoryLocationGridItem,
    branches: ViewBranch[] = [],
  ): InventoryLocationGridItem[] {
    const locationIndex = locations.findIndex(({ branch }) => branch.id === location.branch.id);
    const { min, max, price, ...rest } = location;
    const newStorages: InventoryLocationGridItem[] = location.storages.map(storage => ({
      ...rest,
      min,
      max,
      id: storage.id,
      isPriority: false,
      storages: [],
    }));

    return produce(locations, draft => {
      if (locationIndex === -1) {
        draft.push({
          ...rest,
          id: location.branch.id,
          name: branches.find(({ id }) => id === location.branch.id)?.name ?? '',
          price,
          canOrder: false,
          storages: newStorages,
        });
      } else {
        draft[locationIndex].storages.push(...newStorages);
      }
    });
  }

  static transformInventoryLocationsToGridItems(locations: InventoryLocation[]): InventoryLocationGridItem[] {
    return locations.map(location => ({
      ...location,
      name: location.branch.name,
      storages: location.storages.map(storage => ({
        ...storage,
        ...storage.levels,
        id: storage.storage.id,
        name: getLocalizedName(storage.storage.name),
        branch: location.branch,
        storages: [],
      })),
    }));
  }

  static generateStoragesCreatePayload(storages: InventoryLocationGridItem[]): CreateInventoryStorageRequest[] {
    return storages.map(storage => ({
      isPriority: storage?.isPriority ?? false,
      quantity: storage?.quantity ?? 0,
      levels: { min: storage?.min ?? 0, max: storage?.max ?? 0 },
      storage: { id: storage.id },
    }));
  }

  static generateStoragesUpdatePayload(
    newStorages: InventoryLocationGridItem[],
    oldStorages: InventoryStorage[],
  ): UpdateInventoryStorageRequest {
    const oldStoragesMap = new Map<string, InventoryStorage>(
      oldStorages.map(oldStorage => [oldStorage.storage.id, oldStorage]),
    );

    return newStorages.reduce<UpdateInventoryStorageRequest>((acc, storage) => {
      const storageApi: CreateInventoryStorageRequest = {
        isPriority: storage?.isPriority ?? false,
        quantity: storage?.quantity ?? 0,
        storage: { id: storage.id },
        levels: { min: storage?.min ?? 0, max: storage?.max ?? 0 },
      };

      if (oldStoragesMap.has(storage.id)) {
        acc.update = [...(acc?.update ?? []), { ...storageApi, id: storage.id }];
      } else {
        acc.create = [...(acc?.create ?? []), storageApi];
      }

      return acc;
    }, {});
  }

  static generateLocationCreatePayload(location: InventoryLocationGridItem): CreateInventoryLocationRequest {
    return {
      branch: { id: location.branch.id },
      canOrder: location?.canOrder ?? false,
      price: location?.price ?? 0,
      storages: InventoryLocationsHelper.generateStoragesCreatePayload(location.storages),
    };
  }

  static generateLocationsCreatePayload(locations: InventoryLocationGridItem[]): CreateInventoryLocationRequest[] {
    return locations.map(location => InventoryLocationsHelper.generateLocationCreatePayload(location));
  }

  static generateLocationUpdatePayload(
    location: InventoryLocationGridItem,
    oldStorages: InventoryStorage[],
  ): UpdateLocationWithNestedStorages {
    return {
      id: location.id,
      branch: { id: location.branch.id },
      canOrder: location?.canOrder ?? false,
      price: location?.price ?? 0,
      storages: InventoryLocationsHelper.generateStoragesUpdatePayload(location.storages, oldStorages),
    };
  }

  static generateLocationsUpdatePayload<T extends { locations?: InventoryLocation[] }>(
    locations: InventoryLocationGridItem[],
    item: T,
  ): UpdateInventoryLocationRequest {
    const itemLocations = item?.locations ?? [];
    const oldLocationsMap = new Map<string, InventoryLocation>(
      itemLocations.map(currentItemLocation => [currentItemLocation.id, currentItemLocation]),
    );

    const oldStorages = itemLocations.map(location => location.storages).flat();

    return locations.reduce<UpdateInventoryLocationRequest>((acc, location) => {
      if (oldLocationsMap.has(location.id)) {
        acc.update = [
          ...(acc?.update ?? []),
          InventoryLocationsHelper.generateLocationUpdatePayload(location, oldStorages),
        ];
      } else {
        acc.create = [...(acc?.create ?? []), InventoryLocationsHelper.generateLocationCreatePayload(location)];
      }

      return acc;
    }, {});
  }
}
