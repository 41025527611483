<supy-dialog
  class="supy-package-item-create"
  initialState="open"
  position="center"
  width="50rem"
  (dialogClosed)="onDialogClosed($event)"
  (dialogOpened)="onDialogOpened($event)"
  (dialogOpening)="onDialogOpening($event)"
  (dialogClosing)="onDialogClosing($event)"
>
  <supy-dialog-header class="supy-package-item-create__header">
    <h3>
      {{ packageItem ? 'Edit' : 'Add' }} Packaging for
      {{ itemName }}
      {{ packageItem ? packageItem.packageName : basePackageItem ? basePackageItem.packageName : '' }}
    </h3>
  </supy-dialog-header>
  <supy-dialog-content>
    <form class="supy-package-item-create__form" [formGroup]="form">
      <div class="supy-package-item-create__form-row">
        <div class="supy-package-item-create__form-control">
          <label>Package name</label>
          <supy-input placeholder="ex. Bottle" name="packageName" formControlName="packageName"></supy-input>
        </div>

        <div class="supy-package-item-create__form-control">
          <label>Base unit*</label>
          <supy-combo-box
            *ngIf="!basePackageItem && (!packageItem || packageItem?.level === 0)"
            formControlName="baseUnit"
            name="baseUnit"
            displayKey="name"
            valueKey="id"
            titleKey="name"
            [list]="units"
            [itemHeight]="35"
            [localSearch]="true"
            placeholder="Base Unit"
          ></supy-combo-box>
          <supy-input
            *ngIf="basePackageItem || (packageItem && packageItem.level !== 0)"
            name="baseUnit"
            formControlName="baseUnit"
            class="supy-package-item-create__form-control--disabled"
            [title]="basePackageItem?.unitName ?? baseUom?.name"
          ></supy-input>
        </div>

        <div class="supy-package-item-create__form-control">
          <label>Qty in this package*</label>
          <supy-input
            type="number"
            [step]="0.001"
            placeholder="Quantity"
            name="quantity"
            formControlName="amount"
          ></supy-input>
        </div>
      </div>

      <div class="supy-package-item-create__form-row">
        <div class="supy-package-item-create__form-control-package-item-name">
          <label>Package item name</label>
          <supy-input
            name="packageItemName"
            formControlName="itemName"
            class="supy-package-item-create__form-control--disabled"
          >
          </supy-input>
        </div>
      </div>
      <div class="supy-package-item-create__form-row">
        <div class="supy-package-item-create__form-control">
          <supy-switch formControlName="isPreferred" name="isPreferred">
            <strong>Preferred Packaging</strong>
            <br />
            <small class="supy-package-item-create__form-control-hint"> This will be default UOM for stock page </small>
          </supy-switch>
        </div>

        <div class="supy-package-item-create__form-control">
          <supy-switch
            *ngIf="!packageItem && !basePackageItem && !selectedBaseUom?.isPiece && !isItemUsedAsPiece"
            formControlName="usedAsPiece"
            name="usedAsPiece"
          >
            <strong>Used as Piece?</strong>
            <br />
            <small class="supy-package-item-create__form-control-hint">
              This will allow the quantity to be depleted by piece
            </small>
          </supy-switch>
        </div>
      </div>
    </form>
  </supy-dialog-content>
  <supy-dialog-footer class="supy-package-item-create__footer">
    <supy-button
      name="cancel-adding"
      class="supy-package-item-create__submit"
      color="secondary"
      width="full"
      (buttonClick)="closeDialog()"
    >
      <span>Cancel</span>
    </supy-button>
    <supy-button
      *ngIf="packageItem && isDeletable"
      class="supy-package-item-create__submit"
      name="delete"
      width="full"
      color="error"
      (buttonClick)="deleteClicked.emit()"
    >
      <span>Delete</span>
    </supy-button>
    <supy-button
      name="submit"
      class="supy-package-item-create__submit"
      color="primary"
      width="full"
      [disabled]="isLoading()"
      (buttonClick)="packageItem ? onSaveClick() : onCreateClick()"
    >
      <supy-loader color="primary" *ngIf="!!isLoading?.()"></supy-loader>
      <span>{{ packageItem ? 'Save' : 'Add' }}</span>
    </supy-button>
  </supy-dialog-footer>
</supy-dialog>
