import { first } from 'rxjs';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Output, signal, ViewChild } from '@angular/core';

import { BaseSelectItem, trackByProperty } from '@supy/common';
import { ConfirmDialogWithIconComponent, DialogService, DrawerComponent } from '@supy/components';

import { RecipeStateAction } from '../../core';

@Component({
  selector: 'supy-update-recipes-state-drawer',
  templateUrl: './update-recipes-state-drawer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService],
})
export class UpdateRecipesStateDrawerComponent extends DrawerComponent {
  @ViewChild(DrawerComponent, { static: true }) private readonly drawer: DrawerComponent;
  @Output() readonly confirmed = new EventEmitter<RecipeStateAction | null>();

  protected readonly trackByValue = trackByProperty<BaseSelectItem>('value');
  protected readonly state = signal<RecipeStateAction | null>(null);
  protected readonly states: BaseSelectItem[] = [
    { label: 'Published', value: RecipeStateAction.Activate },
    { label: 'Archived', value: RecipeStateAction.Archive },
  ];

  readonly #dialogService = inject(DialogService);

  protected save(): void {
    this.#dialogService
      .openDialog(ConfirmDialogWithIconComponent, {
        title: 'Update State',
        message: 'Are you sure you want to update the state for the chosen recipes?',
        confirmText: 'Proceed',
      })
      .confirm.pipe(first())
      .subscribe(() => this.confirmed.emit(this.state()));
  }

  closeDialog(): void {
    this.drawer.closeDialog();
  }
}
