import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IANATimezone } from '@supy.api/dictionaries';

import { SimpleUser } from '@supy/common';

export interface RecipeCreationDetails {
  readonly createdBy: SimpleUser;
  readonly lastModifiedBy: SimpleUser;
  readonly createdAt: Date;
  readonly lastModifiedAt: Date;
}

@Component({
  selector: 'supy-recipe-creation-details',
  templateUrl: './recipe-creation-details.component.html',
  styleUrls: ['./recipe-creation-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecipeCreationDetailsComponent {
  @Input() readonly details?: RecipeCreationDetails;
  @Input() readonly ianaTimeZone: IANATimezone;
}
