import { SkeletonObjectType } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';

import { InventoryEvent, InventoryEventItem, InventoryEventSearchItem } from './inventory-event.entity';
import { InventoryEventStateEnum } from './inventory-event.model';

export class InventoryProductionEvent extends InventoryEvent {
  readonly location: SkeletonObjectType;
  readonly items: InventoryProductionEventItem[];
  readonly state: InventoryEventStateEnum;

  constructor(args: NonFunctionProperties<InventoryProductionEvent>) {
    super(args);

    this.items = args.items;
  }
}

export class InventoryProductionEventItem extends InventoryEventItem {
  static deserialize(args: NonFunctionProperties<InventoryProductionEventItem>): InventoryProductionEventItem {
    return new InventoryProductionEventItem({ ...args, ...InventoryEventItem.deserialize(args) });
  }

  static deserializeList(data: NonFunctionProperties<InventoryProductionEventItem>[]): InventoryProductionEventItem[] {
    return data.map(inventoryProductionEventItem =>
      InventoryProductionEventItem.deserialize(inventoryProductionEventItem),
    );
  }
}

export class InventoryProductionSearchItem extends InventoryEventSearchItem {
  constructor(args: NonFunctionProperties<InventoryProductionSearchItem>) {
    super(args);
    Object.assign(this, args);
  }

  static deserialize(data: NonFunctionProperties<InventoryProductionSearchItem>): InventoryProductionSearchItem {
    return new InventoryProductionSearchItem({ ...data });
  }

  static deserializeList(
    data: NonFunctionProperties<InventoryProductionSearchItem>[],
  ): InventoryProductionSearchItem[] {
    return data.map(inventoryProductionSearchItem =>
      InventoryProductionSearchItem.deserialize(inventoryProductionSearchItem),
    );
  }

  static from(args: NonFunctionProperties<InventoryProductionEventItem>): InventoryProductionSearchItem {
    return InventoryProductionSearchItem.deserialize({
      ...InventoryEventSearchItem.from(args),
    });
  }
}

export class ProductionItemGrid extends InventoryProductionEventItem {
  readonly item?: InventoryProductionSearchItem;

  constructor(args: NonFunctionProperties<ProductionItemGrid>) {
    super(args);
    Object.assign(this, args);
  }

  static deserialize(args: NonFunctionProperties<ProductionItemGrid>): ProductionItemGrid {
    return new ProductionItemGrid({ ...args });
  }

  static deserializeList(data: NonFunctionProperties<ProductionItemGrid>[]): ProductionItemGrid[] {
    return data.map(productionItemGrid => ProductionItemGrid.deserialize(productionItemGrid));
  }

  static from(args: NonFunctionProperties<InventoryProductionEventItem>): ProductionItemGrid {
    return ProductionItemGrid.deserialize({
      ...InventoryProductionEventItem.deserialize(args),
      item: InventoryProductionSearchItem.from(args),
    });
  }
}
