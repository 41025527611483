import { IdType, LocalizedData, LocalizedSkeletonObjectType, SkeletonObjectType, Uom } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';
import { PackagingGroup } from '@supy/packaging';

import {
  InventoryIngredientQuantity,
  InventoryRecipePortionSize,
  InventoryRecipeType,
  RepositoryRecipeLocation,
} from '../modules';
import { BaseItemSupplierApi } from './base-item-supplier.model';

export enum BaseItemState {
  Available = 'available',
  Draft = 'draft',
  Archived = 'archived',
}

export class BaseItem {
  readonly id: string;
  readonly name: LocalizedData;
  readonly code: string;
  readonly state: BaseItemState;
  readonly category: LocalizedSkeletonObjectType;
  readonly scope: BaseItemScope;
  readonly retailer: SkeletonObjectType;
  readonly accountingCategory?: SkeletonObjectType;
  readonly locations: IdType[];
  readonly suppliers: BaseItemSupplierApi[];
  readonly baseUnit?: Uom;
  readonly packages: PackagingGroup[];
  readonly costingMethod: FixedCosting | PurchaseOrderCosting;
  readonly inventoryItem?: IdType;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly isLocked: boolean;
  readonly taxCode?: string;

  constructor(args: NonFunctionProperties<BaseItem>) {
    Object.assign(this, args);
  }

  isEditable(): boolean {
    return this.state !== BaseItemState.Archived && !this.isLocked;
  }

  canEditPackages(): boolean {
    return this.isEditable() && this.scope.type !== BaseItemScopeTypeEnum.SemiFinishedItem;
  }
}

export interface FixedCosting {
  readonly method: CostingMethod.Fixed;
  readonly cost: number;
}

export interface PurchaseOrderCosting {
  readonly method: CostingMethod.PurchaseOrder;
}

export enum CostingMethod {
  PurchaseOrder = 'purchase-order',
  Fixed = 'fixed',
}

export interface BaseItemRecipe {
  readonly id: string;
  readonly name: LocalizedData;
  readonly cost: number;
  readonly type: InventoryRecipeType;
  readonly category: IdType;
  readonly portionSize?: InventoryRecipePortionSize;
  readonly item: RecipeItemDetails;
  readonly locations: RepositoryRecipeLocation[];
}

export interface GetRetailerItemsRecipes {
  readonly id: string;
  readonly hasRecipes: boolean;
}

export interface RecipeItemDetails {
  readonly id: string;
  readonly cost?: number;
  readonly quantity?: InventoryIngredientQuantity;
}

export enum BaseItemScopeTypeEnum {
  Item = 'item',
  SemiFinishedItem = 'semi-finished-item',
}

export interface BaseItemScope {
  readonly type: BaseItemScopeTypeEnum;
  readonly referenceId?: string;
}
