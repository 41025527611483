<footer class="inventory-event-details-footer">
  <div *ngIf="isStockCountedAfterEventDate" class="inventory-event-details-footer__hint-container">
    <div class="inventory-event-details-footer__hint">
      <supy-icon name="exclamation-hex" color="warn"></supy-icon>
      <span class="inventory-event-details-footer__hint-label" i18n="@@inventory.stockCountFooterHint">
        There has been a stock count after this date for the item and it won't be updated in inventory.
      </span>
    </div>
  </div>
  <div class="inventory-event-details-footer__actions">
    <ng-container *ngIf="eventType === inventoryEventType.Transfer; else notTransfer">
      <supy-button
        *ngIf="eventState === inventoryTransferStateEnum.Draft"
        type="button"
        color="error-tertiary"
        name="discard"
        (buttonClick)="discardClicked.emit()"
        i18n="@@discard"
        >Discard</supy-button
      >
      <supy-button
        *ngIf="canSave && eventState === inventoryTransferStateEnum.Submitted"
        type="button"
        color="secondary"
        name="save"
        [disabled]="isDisabled"
        (buttonClick)="saveClicked.emit()"
        i18n="@@save"
        >Save</supy-button
      >
      <supy-button
        *ngIf="canReceive && eventState === inventoryTransferStateEnum.Submitted"
        type="button"
        color="secondary"
        name="receive"
        [disabled]="isDisabled"
        (buttonClick)="receiveClicked.emit()"
        i18n="@@receive"
        >Receive</supy-button
      >
    </ng-container>
    <ng-template #notTransfer>
      <ng-container *ngIf="eventState !== inventoryEventStateEnum.Submitted">
        <supy-button
          *ngIf="eventState === inventoryEventStateEnum.Draft"
          type="button"
          color="secondary"
          name="save"
          [disabled]="isDisabled"
          (buttonClick)="saveClicked.emit()"
          i18n="@@save"
          >Save</supy-button
        >
        <supy-button
          *ngIf="!eventState"
          type="button"
          color="secondary"
          name="save-as-draft"
          [disabled]="isDisabled"
          (buttonClick)="saveAsDraftClicked.emit()"
          i18n="@@draftSave"
          >Save as Draft</supy-button
        >
      </ng-container>
    </ng-template>
    @if (eventState === inventoryEventStateEnum.Submitted) {
      <supy-button
        type="button"
        color="secondary"
        name="submit"
        (buttonClick)="repeatClicked.emit()"
        i18n="@@common.actions.repeatEntity"
        >Repeat {{ eventType | titlecase }}</supy-button
      >
    }

    <supy-button
      *ngIf="!isSubmitHidden && (!eventState || eventState === inventoryEventStateEnum.Draft)"
      type="button"
      color="primary"
      name="submit"
      [disabled]="isDisabled"
      (buttonClick)="submitClicked.emit()"
      i18n="@@common.actions.submitEntity"
      >Submit {{ eventType | titlecase }}</supy-button
    >
  </div>
</footer>
