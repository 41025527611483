<supy-drawer width="50vw" [showPagination]="false">
  <h4 header>Update Category</h4>

  <div content>
    <supy-dropdown-tree
      name="category"
      placeholder="Category"
      selection="single"
      [autoExpanded]="true"
      [data]="categories"
      (valueChange)="category.set($event)"
    ></supy-dropdown-tree>
  </div>

  <ng-container footer>
    <supy-button color="default-outline" name="cancel" (buttonClick)="closeDialog()">Cancel</supy-button>
    <supy-button color="primary" name="save" [disabled]="!category()" (buttonClick)="save()">Save</supy-button>
  </ng-container>
</supy-drawer>
