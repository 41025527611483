import { LocalizedSkeletonObjectType, SimpleUser, SkeletonObjectType, Uom, ViewBranch } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';
import { PackagingUnit, PackagingUnitScope } from '@supy/packaging';

import { CostingMethod } from './base-item.entity';
import {
  BaseItemInventoryApi,
  BaseItemInventoryLastStockCountApi,
  BaseItemInventoryLocationApi,
  BaseItemInventoryLocationRequest,
  InventoryItemType,
} from './base-item-inventory.model';

export enum BaseItemInventoryState {
  Available = 'available',
  Draft = 'draft',
  Archived = 'archived',
}

export class BaseItemInventoryCostingMethod {
  readonly method: CostingMethod;
  readonly fixedCost?: number;

  static default(): BaseItemInventoryCostingMethod {
    return { method: CostingMethod.PurchaseOrder };
  }
}

export interface BaseItemInventoryQuantityLevel {
  readonly min: number;
  readonly par: number;
}

export class ItemScope {
  type: InventoryItemType;
  referenceId?: string;

  static default(): ItemScope {
    return { type: InventoryItemType.Item };
  }
}

export class RetailerItemSnapshot extends LocalizedSkeletonObjectType {
  code: string;
  scope: ItemScope;
  baseUnit: Uom;
  category?: LocalizedSkeletonObjectType;
  isLocked?: boolean;

  static default(): RetailerItemSnapshot {
    return {
      id: crypto.randomUUID(),
      name: { en: '' },
      code: '',
      scope: ItemScope.default(),
      baseUnit: Uom.default(),
    };
  }
}

export interface BranchUnit {
  name: string;
  uomId: string;
  packagingId: string;
  toBaseUom: number;
}

export interface BranchSnapshot {
  id: string;
  name: string;
  country?: { code: string };
}

export interface QuantityLevel {
  min: number;
  par: number;
}

export interface CountingPackagings {
  name: string;
  packagingId: string;
  toBaseUom: number;
  toAtomUom: number;
  excludedLocations: string[];
  defaultScope: 'all' | 'partial' | 'none';
  isDefault?: boolean;
  isPartial?: boolean;
  uomId: string;
  customId: string;
}

export class BaseItemInventory {
  private constructor(args: NonFunctionProperties<BaseItemInventory>) {
    this.id = args.id;
    this.retailer = args.retailer;
    this.retailerItem = args.retailerItem;
    this.state = args.state;
    this.costingMethod = args.costingMethod;
    this.cost = args.cost;
    this.onHand = args.onHand;
    this.wastagePercentage = args.wastagePercentage;
    this.baseUnit = args.baseUnit;
    this.locations = args.locations;
    this.storages = args.storages;
    this.lastStockCount = args.lastStockCount;
    this.updatedBy = args.updatedBy;
    this.updatedAt = args.updatedAt;
    this.createdBy = args.createdBy;
    this.createdAt = args.createdAt;
    this.notAffectCogs = args.notAffectCogs;
    this.packagings = args.packagings;
    this.countingPackagings = args.countingPackagings;
  }

  readonly id: string;
  readonly retailer: SkeletonObjectType;
  readonly retailerItem: RetailerItemSnapshot;
  readonly state: BaseItemInventoryState;
  readonly costingMethod: BaseItemInventoryCostingMethod;
  readonly cost: number;
  readonly onHand: number;
  readonly wastagePercentage?: number;
  readonly baseUnit: Uom;
  locations: BaseItemInventoryLocationApi[];
  readonly storages: LocalizedSkeletonObjectType[];
  readonly lastStockCount?: BaseItemInventoryLastStockCount;
  readonly lastPurchaseCost?: number;
  readonly updatedBy: SimpleUser;
  readonly createdBy: SimpleUser;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly notAffectCogs: boolean;
  readonly countingPackagings: CountingPackagings[];
  readonly packagings: PackagingUnit[];
  readonly suppliers: SkeletonObjectType[];

  static deserialize(data: BaseItemInventoryApi): BaseItemInventory {
    return new BaseItemInventory({
      id: data.id,
      retailer: data.retailer,
      retailerItem: data.retailerItem,
      state: data.state,
      costingMethod: data.costingMethod,
      cost: data.cost,
      onHand: data.onHand,
      wastagePercentage: data.wastagePercentage,
      baseUnit: data.baseUnit,
      locations: data.locations,
      storages: data.storages,
      lastStockCount: data.lastStockCount && BaseItemInventoryLastStockCount.deserialize(data.lastStockCount),
      notAffectCogs: data.notAffectCogs ?? false,
      updatedBy: data.updatedBy,
      updatedAt: data.updatedAt,
      createdBy: data.createdBy,
      createdAt: data.createdAt,
      lastPurchaseCost: data.lastPurchaseCost,
      countingPackagings: CountingPackaging.fromPackagings(data.countingPackagings),
      packagings: data.packagings,
      suppliers: data.suppliers,
    });
  }

  static deserializeList(data: BaseItemInventoryApi[]): BaseItemInventory[] {
    return data.map(itemInventory => BaseItemInventory.deserialize(itemInventory));
  }

  static default(): BaseItemInventory {
    return {
      id: crypto.randomUUID(),
      retailer: SkeletonObjectType.default(),
      retailerItem: RetailerItemSnapshot.default(),
      state: BaseItemInventoryState.Available,
      costingMethod: BaseItemInventoryCostingMethod.default(),
      cost: 0,
      onHand: 0,
      wastagePercentage: 0,
      baseUnit: Uom.default(),
      locations: [],
      storages: [],
      updatedBy: SimpleUser.default(),
      createdBy: SimpleUser.default(),
      updatedAt: new Date(),
      createdAt: new Date(),
      notAffectCogs: false,
      countingPackagings: [],
      packagings: [],
      suppliers: [],
    };
  }
}

export class BaseItemInventoryLocation {
  private constructor(args: NonFunctionProperties<BaseItemInventoryLocation>) {
    this.id = args.id;
    this.itemLocationId = args.itemLocationId;
    this.packagingUnit = args.packagingUnit;
    this.parLevel = args.parLevel;
    this.minLevel = args.minLevel;
  }

  readonly id: string;
  readonly itemLocationId: string | null;
  readonly packagingUnit: PackagingUnit | null;
  readonly parLevel: number;
  readonly minLevel: number;

  static deserialize(data: BaseItemInventoryLocationApi): BaseItemInventoryLocation {
    return new BaseItemInventoryLocation({
      id: data.location.id,
      itemLocationId: data.id,
      minLevel: data.quantityLevel?.min,
      parLevel: data.quantityLevel?.par,
      packagingUnit: data.packagingUnit && PackagingUnit.deserialize(data.packagingUnit),
    });
  }

  static deserializeList(data: BaseItemInventoryLocationApi[]): BaseItemInventoryLocation[] {
    return data.map(location => BaseItemInventoryLocation.deserialize(location));
  }

  static deserializeUsersList(userBranches: ViewBranch[]): BaseItemInventoryLocation[] {
    return userBranches.map(({ id, name }) => ({
      id,
      name,
      itemLocationId: null,
      minLevel: 0,
      parLevel: 0,
      packagingUnit: null,
    }));
  }

  static serializeUpdate(data: BaseItemInventoryLocation): BaseItemInventoryLocationRequest {
    return {
      id: data.itemLocationId,
      packagingUnit: data.packagingUnit && {
        id: data.packagingUnit.packagingId ?? data.packagingUnit.uomId,
        type: data.packagingUnit.packagingId ? PackagingUnitScope.Packaging : PackagingUnitScope.Uom,
      },
      quantityLevel: {
        min: data.minLevel,
        par: data.parLevel,
      },
    };
  }

  static serializeUpdateList(data: BaseItemInventoryLocation[]): BaseItemInventoryLocationRequest[] {
    return data.map(location => BaseItemInventoryLocation.serializeUpdate(location));
  }

  static default(): BaseItemInventoryLocation {
    return {
      id: crypto.randomUUID(),
      itemLocationId: null,
      minLevel: 0,
      parLevel: 0,
      packagingUnit: PackagingUnit.default(),
    };
  }
}

export class BaseItemInventoryLastStockCount {
  private constructor(args: NonFunctionProperties<BaseItemInventoryLastStockCount>) {
    this.onHandQuantity = args.onHandQuantity;
    this.countedQuantity = args.countedQuantity;
    this.eventDate = args.eventDate;
  }

  readonly onHandQuantity: number;
  readonly countedQuantity: number;
  readonly eventDate: Date;

  static deserialize(data: BaseItemInventoryLastStockCountApi): BaseItemInventoryLastStockCount {
    return new BaseItemInventoryLastStockCount({
      ...data,
      eventDate: new Date(data.eventDate),
    });
  }
}

export class CountingPackaging {
  readonly name: string;
  readonly packagingId: string;
  readonly toBaseUom: number;
  readonly toAtomUom: number;
  readonly excludedLocations: string[];
  readonly defaultScope: 'all' | 'partial' | 'none';
  readonly isDefault: boolean;
  readonly isPartial: boolean;
  readonly uomId: string;
  readonly customId: string;

  constructor(args: Omit<CountingPackagings, 'isDefault' | 'isPartial' | 'customId'>) {
    this.name = args.name;
    this.packagingId = args.packagingId;
    this.toBaseUom = args.toBaseUom;
    this.toAtomUom = args.toAtomUom;
    this.excludedLocations = args.excludedLocations;
    this.defaultScope = args.defaultScope === undefined ? 'none' : args.defaultScope;
    this.isDefault = args.defaultScope === 'all';
    this.isPartial = args.defaultScope === 'partial';
    this.uomId = args.uomId;
    this.customId = `${args.uomId}${args.packagingId}`;
  }

  static deserialize(data: CountingPackagings): CountingPackaging {
    return new CountingPackaging(data);
  }

  static deserializeList(data: CountingPackagings[]): CountingPackaging[] {
    return data.map(packaging => CountingPackaging.deserialize(packaging));
  }

  static fromPackagings(packagings: CountingPackagings[]): CountingPackaging[] {
    return packagings.map(packaging => new CountingPackaging(packaging));
  }
}
