<form class="supy-create-recipe__costing" [formGroup]="form">
  <div class="supy-create-recipe__costing-column">
    <div class="supy-create-recipe__costing-price-section">
      <span i18n="@@common.sellingPriceInclusive">Selling Price Inclusive of Tax</span>
      <supy-input
        type="number"
        [step]="0.001"
        formControlName="sellingPrice"
        name="sellingPrice"
        [addonText]="currency"
      >
        <supy-input-error
          *ngIf="!form.get('sellingPrice')?.valid && (formSubmitted || form.get('sellingPrice')?.touched)"
          i18n="@@inventory.recipe.costing.fieldRequired"
          >Field is required</supy-input-error
        >
      </supy-input>
    </div>
    <div class="supy-create-recipe__costing-vat-section">
      <div *ngFor="let recipeCostingTax of recipeCostingTaxes" class="supy-create-recipe__costing-vat-section-row">
        <supy-switch
          size="small"
          [disabled]="taxesDisabled"
          [value]="isRecipeCostingTaxActive(recipeCostingTax)"
          (valueChange)="onRecipeCostingTaxActiveChange($event, recipeCostingTax)"
        >
          {{ recipeCostingTax.name }} {{ recipeCostingTax.rate }}%
        </supy-switch>
        <span *ngIf="isRecipeCostingTaxActive(recipeCostingTax)">
          {{
            (sellingPrice ? sellingPriceExclVat * (recipeCostingTax.rate / 100) : 0) | supyPrecision: currencyPrecision
          }}
        </span>
      </div>
      <div class="supy-create-recipe__costing-vat-section-divider"></div>
      <div class="supy-create-recipe__costing-vat-section-row primary">
        <strong i18n="@@common.sellingPriceExcludingTax">Selling Price Excluding Tax</strong>
        <ng-container [ngTemplateOutlet]="sellingPriceTmpl"></ng-container>
      </div>
    </div>
    <div *ngIf="costTresholdDisplayed" class="supy-create-recipe__costing-notify-section">
      <span i18n="@@inventory.recipe.costing.notify">Notify when food costs exceeds</span>
      <supy-input
        type="number"
        formControlName="costThreshold"
        name="cost-exceed-notification"
        addonText="%"
      ></supy-input>
    </div>
  </div>
</form>
<ng-template #sellingPriceTmpl>
  <strong>{{ sellingPriceExclVat | supyPrecision: currencyPrecision }}</strong>
</ng-template>
