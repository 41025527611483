import produce from 'immer';
import { catchError, EMPTY, tap, withLatestFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';

import { EntityListState, EntityListStateModel, QueryBuilder, UomType } from '@supy/common';
import { DropdownTreeNode, StatisticsCard } from '@supy/components';
import { RetailerItemCategoriesState } from '@supy/retailer-item-categories';
import { CurrentRetailerState } from '@supy/retailers';
import { SettingsState } from '@supy/settings';

import {
  BaseItemInventory,
  BaseItemInventoryApi,
  BaseItemInventoryRequestProps,
  BaseItemInventoryState,
} from '../../../../core';
import { InventoryItemsService } from '../../../../services';
import { InventoryStockCountService } from '../../../stock-count';
import { StockStatisticsCardType } from '../../core';
import { downloadInventoryStocksList } from '../../helpers';
import {
  InventoryStocksGet,
  InventoryStocksGetMany,
  InventoryStocksGetStatistics,
  InventoryStocksInitFilters,
  InventoryStocksListExport,
  InventoryStocksPatchFilter,
  InventoryStocksPatchRequestMeta,
  InventoryStocksReset,
  InventoryStocksResetFilter,
} from '../actions';

export interface InventoryStocksStateModel extends EntityListStateModel<BaseItemInventory> {
  readonly filters: InventoryStocksFilters;
  readonly requestMetadata: InventoryStocksRequestMetadata;
  readonly responseMetadata: InventoryStocksResponseMetadata;
  readonly statistics: StatisticsCard[];
  readonly selectedInventoryItem: BaseItemInventory | null;
  readonly exportLoading: boolean;
  readonly stockCount: CountingPackagingTab[];
}

export interface InventoryStocksFilters {
  readonly codeName: string | null;
  readonly storages: string[];
  readonly locationId: string | null;
  readonly categories: string[];
  readonly retailer: string | null;
  readonly archived: boolean;
  readonly selectedStatistics: string | null;
}

export interface PackagingIdentifier {
  readonly uomId?: string;
  readonly packagingId?: string;
}

export interface SetLocationCountingPackagingsRequest {
  readonly excludedPackagings: PackagingIdentifier[];
  readonly includedPackagings: PackagingIdentifier[];
  readonly defaultPackaging: PackagingIdentifier;
}

interface PackagingRequest {
  readonly uomId?: string;
  readonly packagingId?: string;
  readonly excludedLocations: string[];
  readonly defaultScope: 'all' | 'partial' | 'none';
}

export interface SetInventoryCountingPackagingsRequest {
  readonly packagings: PackagingRequest[];
}

export interface CountingPackagingTab {
  readonly uomId: string;
  readonly packagingId: string;
  readonly name: string;
  readonly toBaseUom: number;
  readonly isDefault: boolean;
  readonly isVisible: boolean;
}

export interface InventoryStocksRequestMetadata {
  readonly limit: number;
  readonly page: number;
  readonly retailerId: string | null;
  readonly locationId: string | null;
  readonly itemIds: string[] | null;
}

export interface InventoryStocksResponseMetadata {
  readonly total: number;
  readonly count: number;
}

const INVENTORY_ITEM_STATE_TOKEN = new StateToken<InventoryStocksStateModel[]>('inventoryStocks');

const FILTERS_DEFAULT: InventoryStocksFilters = {
  codeName: null,
  storages: [],
  locationId: null,
  categories: [],
  retailer: null,
  archived: false,
  selectedStatistics: null,
};

const REQUEST_META_DEFAULT: InventoryStocksRequestMetadata = {
  page: 0,
  limit: 25,
  retailerId: null,
  locationId: null,
  itemIds: [],
};
const RESPONSE_META_DEFAULT: InventoryStocksResponseMetadata = { total: 0, count: 0 };

const DEFAULT_STATE = {
  filters: FILTERS_DEFAULT,
  requestMetadata: REQUEST_META_DEFAULT,
  responseMetadata: RESPONSE_META_DEFAULT,
  statistics: [],
  selectedInventoryItem: null,
  exportLoading: false,
  stockCount: [],
};

@State<InventoryStocksStateModel>({
  name: INVENTORY_ITEM_STATE_TOKEN,
  defaults: {
    ...EntityListState.default(),
    ...DEFAULT_STATE,
  },
})
@Injectable()
export class InventoryStocksState extends EntityListState {
  constructor(
    protected readonly router: Router,
    private readonly store: Store,
    private readonly inventoryItemsService: InventoryItemsService,
    private readonly stockCountService: InventoryStockCountService,
  ) {
    super(router);
  }

  @Selector()
  static items(state: InventoryStocksStateModel) {
    return EntityListState.all(state);
  }

  @Selector()
  static currentItem(state: InventoryStocksStateModel) {
    return EntityListState.current(state);
  }

  static item(id: string, next?: boolean) {
    return createSelector([InventoryStocksState], (state: InventoryStocksStateModel) => {
      return EntityListState.one(id, next)(state);
    });
  }

  @Selector()
  static appliedFiltersCount(state: InventoryStocksStateModel) {
    return EntityListState.appliedFiltersCount(state, FILTERS_DEFAULT, ['locationId']);
  }

  @Selector()
  static filters(state: InventoryStocksStateModel) {
    return EntityListState.currentFilters<InventoryStocksFilters>(state);
  }

  @Selector()
  static locationId(state: InventoryStocksStateModel) {
    return EntityListState.currentFilters<InventoryStocksFilters>(state).locationId;
  }

  @Selector()
  static requestMetadata(state: InventoryStocksStateModel) {
    return state.requestMetadata;
  }

  @Selector()
  static responseMetadata(state: InventoryStocksStateModel) {
    return state.responseMetadata;
  }

  @Selector()
  static statisticCards(state: InventoryStocksStateModel) {
    return state.statistics;
  }

  @Selector()
  static isFirst(state: InventoryStocksStateModel) {
    return EntityListState.isFirst(state);
  }

  @Selector()
  static isLast(state: InventoryStocksStateModel) {
    return EntityListState.isLast(state);
  }

  @Selector()
  static selectedInventoryItem(state: InventoryStocksStateModel) {
    return state.selectedInventoryItem;
  }

  @Selector()
  static exportLoading(state: InventoryStocksStateModel) {
    return state.exportLoading;
  }

  @Action(InventoryStocksInitFilters)
  inventoryStocksInitFilter(ctx: StateContext<InventoryStocksStateModel>) {
    super.initFilter(ctx, FILTERS_DEFAULT);
  }

  @Action(InventoryStocksResetFilter)
  inventoryStocksResetFilter(ctx: StateContext<InventoryStocksStateModel>) {
    super.resetFilter(ctx, FILTERS_DEFAULT);
  }

  @Action(InventoryStocksPatchFilter)
  patchInventoryStocksFilter(ctx: StateContext<InventoryStocksStateModel>, action: InventoryStocksPatchFilter) {
    super.patchFilter(ctx, action.payload, FILTERS_DEFAULT, action.options);
  }

  @Action(InventoryStocksGet)
  getInventoryStock(ctx: StateContext<InventoryStocksStateModel>, action: InventoryStocksGet) {
    return this.getOne<BaseItemInventory, InventoryStocksStateModel>(ctx, {
      ...action.payload,
      fetchApi: () => this.inventoryItemsService.getById(action.payload.id),
    }).pipe(tap(selectedInventoryItem => ctx.patchState({ selectedInventoryItem })));
  }

  @Action(InventoryStocksReset)
  resetInventoryStock(ctx: StateContext<InventoryStocksStateModel>) {
    return ctx.setState({
      ...EntityListState.default(),
      ...DEFAULT_STATE,
    });
  }

  @Action(InventoryStocksGetMany, { cancelUncompleted: true })
  getInventoryStocks(ctx: StateContext<InventoryStocksStateModel>) {
    return this.inventoryItemsService.getMany(this.getQuery(ctx.getState())).pipe(
      tap(({ data, metadata }) => {
        ctx.patchState({ responseMetadata: metadata });
        super.setMany(ctx, []);
        super.setMany(ctx, data);
      }),
    );
  }

  @Action(InventoryStocksGetStatistics)
  getInventoryStatistics(ctx: StateContext<InventoryStocksStateModel>) {
    const locationId = ctx.getState().filters.locationId ?? '';

    return this.stockCountService.getStatisticsBff(locationId).pipe(
      tap(({ onHand, notLinked, negative, abovePar, belowMinimum }) => {
        ctx.patchState({
          statistics: [
            {
              type: StockStatisticsCardType.OnHand,
              title: $localize`:@@inventory.stocks.statistics.onHand:Stock On Hand`,
              icon: { name: 'box', color: 'info' },
              value: onHand.value ?? 0,
            },
            {
              type: StockStatisticsCardType.NotLinked,
              title: $localize`:@@inventory.stocks.statistics.notLInked:Not Linked To Recipe`,
              icon: { name: 'link-vertical', color: 'success' },
              value: notLinked.value ?? 0,
              clickable: true,
            },
            {
              type: StockStatisticsCardType.Negative,
              title: $localize`:@@inventory.stocks.statistics.negative:Negative Stock`,
              icon: { name: 'box-remove', color: 'error' },
              value: negative.value ?? 0,
              clickable: true,
            },
            {
              type: StockStatisticsCardType.AbovePar,
              title: $localize`:@@inventory.stocks.statistics.abovePar:Above Par`,
              icon: { name: 'above-line', color: 'warn' },
              value: abovePar.value ?? 0,
              clickable: true,
            },
            {
              type: StockStatisticsCardType.BelowMinimum,
              title: $localize`:@@inventory.stocks.statistics.belowMinimum:Below Minimum`,
              icon: { name: 'below-line', color: 'error' },
              value: belowMinimum.value ?? 0,
              clickable: true,
            },
          ],
        });
      }),
    );
  }

  @Action(InventoryStocksPatchRequestMeta)
  patchRequestMetadata(ctx: StateContext<InventoryStocksStateModel>, { payload }: InventoryStocksPatchRequestMeta) {
    ctx.setState(
      produce(draft => {
        draft.requestMetadata = {
          ...ctx.getState().requestMetadata,
          ...payload,
        };
      }),
    );
  }

  @Action(InventoryStocksListExport, { cancelUncompleted: true })
  downloadItems(ctx: StateContext<InventoryStocksStateModel>) {
    ctx.patchState({
      exportLoading: true,
    });

    const state = ctx.getState();
    const query = this.getQuery(state, {
      paginationLimit: -1,
      offset: 0,
    });

    return this.inventoryItemsService.getMany(query).pipe(
      withLatestFrom(
        this.store.select(SettingsState.currency),
        this.store.select(SettingsState.ianaTimeZone),
        this.store.select(CurrentRetailerState.locationsWithRegionsBranches),
        this.store.select(RetailerItemCategoriesState.getRetailerItemCategoriesTree),
      ),
      tap(([{ data }, currency, ianaTimeZone, branches, categoriesTree]) => {
        ctx.patchState({
          exportLoading: false,
        });

        const selectedLocationId = query.filtering.filtering.find(filter => filter.by === 'locations.id')
          ?.match as string;

        void downloadInventoryStocksList(
          data as unknown as BaseItemInventory[],
          currency,
          selectedLocationId,
          branches,
          categoriesTree as DropdownTreeNode<string>[],
          ianaTimeZone,
        );
      }),
      catchError(() => {
        ctx.patchState({
          exportLoading: false,
        });

        return EMPTY;
      }),
    );
  }

  private getQuery(
    state: InventoryStocksStateModel,
    options?: { readonly paginationLimit?: number; readonly offset?: number },
  ) {
    const { selectedStatistics, categories, storages, codeName, locationId } = state.filters;

    const qb = new QueryBuilder<BaseItemInventoryApi & BaseItemInventoryRequestProps>({
      filtering: [
        {
          by: 'state',
          match: BaseItemInventoryState.Available,
          op: 'eq',
        },
        {
          by: 'retailerItem.baseUnit.type',
          match: UomType.Base,
          op: 'eq',
        },
      ],
      paging: {
        limit: options?.paginationLimit ? options.paginationLimit : state.requestMetadata.limit,
        offset: options?.offset ? options.offset : state.requestMetadata.page * state.requestMetadata.limit,
      },
      ordering: [
        {
          by: 'createdAt',
          dir: 'desc',
        },
        { by: 'id', dir: 'desc' },
      ],
    });

    if (selectedStatistics) {
      qb.filtering.setFilter({
        by: 'tileType',
        match: selectedStatistics,
        op: 'eq',
      });
    }

    if (codeName) {
      qb.filtering.withGroup('or', [
        {
          by: 'retailerItem.code',
          match: codeName,
          op: 'like',
        },
        {
          by: 'retailerItem.name.en',
          match: codeName,
          op: 'like',
        },
      ]);
    }

    if (categories?.length) {
      qb.filtering.setFilter({
        by: 'retailerItem.category.id',
        match: categories,
        op: 'in',
      });
    }

    if (storages?.length) {
      qb.filtering.setFilter({
        by: 'storages.id',
        match: storages,
        op: 'in',
      });
    }

    if (state.requestMetadata.retailerId) {
      qb.filtering.setFilter({
        by: 'retailer.id',
        match: state.requestMetadata.retailerId,
        op: 'eq',
      });
    }

    if (locationId || state.requestMetadata.locationId) {
      qb.filtering.setFilter({
        by: 'locations.id',
        match: locationId ?? state.requestMetadata.locationId ?? '',
        op: 'eq',
      });
    }

    return qb.build();
  }
}
