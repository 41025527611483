<supy-dialog
  class="supy-add-location"
  (dialogClosed)="onDialogClosed($event)"
  (dialogOpened)="onDialogOpened($event)"
  (dialogOpening)="onDialogOpening($event)"
  (dialogClosing)="onDialogClosing($event)"
>
  <supy-dialog-header class="supy-add-location__header">
    <h3 class="supy-add-location__header-title">Add location(s)</h3>
  </supy-dialog-header>
  <supy-dialog-content>
    <div class="supy-add-location__form">
      <form [formGroup]="form">
        <div class="supy-add-location__form-row">
          <div class="supy-add-location__form-control" formGroupName="branch">
            <span>Branch*</span>
            <supy-select
              formControlName="id"
              placeholder="Branch"
              name="select-branch"
              (selected)="onBranchSelect($event)"
            >
              <supy-select-item *ngFor="let branch of branches; trackBy: trackById" [value]="branch.id">
                {{ branch.name | titlecase }}
              </supy-select-item>
              <supy-input-error *supyErrorIf="'required'">Country is required</supy-input-error>
            </supy-select>
          </div>

          <div class="supy-add-location__form-control">
            <span>Locations*</span>
            <supy-combo-box
              titleKey="name.en"
              valueKey="id"
              placeholder="Locations"
              name="select-locations"
              [disabled]="locations.length === 0"
              [multiple]="true"
              [list]="locations"
              (changed)="onLocationsChange($event)"
            ></supy-combo-box>
          </div>
        </div>

        <div class="supy-add-location__form-row">
          <div class="supy-add-location__form-control">
            <span>Cost</span>
            <supy-input type="number" placeholder="Type cost" name="price" formControlName="price"></supy-input>
          </div>

          <div class="supy-add-location__form-control">
            <span>Quantity</span>
            <supy-input
              type="number"
              placeholder="Type quantity"
              name="quantity"
              formControlName="quantity"
            ></supy-input>
          </div>
        </div>

        <div class="supy-add-location__form-row">
          <div class="supy-add-location__form-control">
            <span class="supy-add-location__flex-row">
              <span>PAR Level</span>
              <supy-icon
                name="info"
                color="primary"
                size="small"
                [supyTooltipDeprecated]="parInfo"
                position="top"
              ></supy-icon>
            </span>
            <supy-input type="number" placeholder="PAR Level" name="min" formControlName="min"></supy-input>
          </div>

          <div class="supy-add-location__form-control">
            <span class="supy-add-location__flex-row">
              <span>Max Level</span>
              <supy-icon
                name="info"
                color="primary"
                size="small"
                [supyTooltipDeprecated]="maxInfo"
                position="top"
              ></supy-icon>
            </span>
            <supy-input type="number" placeholder="Max Level" name="max" formControlName="max"></supy-input>
          </div>
        </div>
      </form>
    </div>
  </supy-dialog-content>
  <supy-dialog-footer class="supy-add-location__footer">
    <supy-button
      type="button"
      class="supy-add-location__danger-btn"
      color="secondary"
      name="cancel"
      (buttonClick)="dialog.closeDialog()"
    >
      <supy-icon name="close" size="extra-small"></supy-icon>
      <span>Cancel</span>
    </supy-button>
    <supy-button color="primary" name="add-location" [disabled]="form.invalid" (buttonClick)="onAddLocation()">
      Add Location
    </supy-button>
  </supy-dialog-footer>
</supy-dialog>
<supy-tooltip-deprecated #parInfo>This is PAR level</supy-tooltip-deprecated>
<supy-tooltip-deprecated #maxInfo>This is Max level</supy-tooltip-deprecated>
