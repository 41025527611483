import { createWorksheet, DataColumn, saveWorkbook } from '@supy/common';

export async function downloadVarianceReport(
  reportData: {
    itemName: string;
    itemType: string;
    storageUnit: string;
    onHandValue: number;
    countedValue: number;
    valueVariance: number;
    category: string;
    subCategory: string;
  }[],
  reportMetadata: {
    locationName: string;
    date: Date;
    time: string;
  },
  categoryData: {
    categoryName: string;
    onHand: number;
    counted: number;
    variance: number;
  }[],
  subCategoryData: {
    categoryName: string;
    subCategoryName: string;
    onHand: number;
    counted: number;
    variance: number;
  }[],
): Promise<void> {
  const columns: DataColumn[] = [
    { header: $localize`:@@inventory.excelExport.itemName:Item Name`, key: 'itemName' },
    { header: $localize`:@@inventory.excelExport.itemType:Item Type`, key: 'itemType' },
    { header: $localize`:@@inventory.excelExport.storageUnit:Storage Unit`, key: 'storageUnit' },
    { header: $localize`:@@inventory.excelExport.category:Category`, key: 'category' },
    { header: $localize`:@@inventory.excelExport.subCategory:Sub-Category`, key: 'subCategory' },
    { header: $localize`:@@inventory.excelExport.onHandQuantity:On-Hand Quantity`, key: 'onHandQuantity' },
    { header: $localize`:@@inventory.excelExport.countedQuantity:Counted Quantity`, key: 'countedQuantity' },
    { header: $localize`:@@inventory.excelExport.quantityVariance:Quantity Variance`, key: 'quantityVariance' },
    { header: $localize`:@@inventory.excelExport.onHandValue:On-Hand Value`, key: 'onHandValue' },
    { header: $localize`:@@inventory.excelExport.countedValue:Counted Value`, key: 'countedValue' },
    { header: $localize`:@@inventory.excelExport.valueVariance:Value Variance`, key: 'valueVariance' },
  ];

  const categoryColumns: DataColumn[] = [
    { header: $localize`:@@inventory.excelExport.category:Category`, key: 'categoryName' },
    { header: $localize`:@@inventory.excelExport.onHandValue:On-Hand Value`, key: 'onHand' },
    { header: $localize`:@@inventory.excelExport.countedValue:Counted Value`, key: 'counted' },
    { header: $localize`:@@inventory.excelExport.valueVariance:Value Variance`, key: 'variance' },
  ];

  const subCategoryColumns: DataColumn[] = [
    { header: $localize`:@@inventory.excelExport.category:Category`, key: 'categoryName' },
    { header: $localize`:@@inventory.excelExport.subCategory:Sub-Category`, key: 'subCategoryName' },
    { header: $localize`:@@inventory.excelExport.onHandValue:On-Hand Value`, key: 'onHand' },
    { header: $localize`:@@inventory.excelExport.countedValue:Counted Value`, key: 'counted' },
    { header: $localize`:@@inventory.excelExport.valueVariance:Value Variance`, key: 'variance' },
  ];

  const { Workbook } = (await import('exceljs')).default;
  const workbook = new Workbook();

  const metadataSheetValues: string[][] = [
    ['Variance Report Details'],
    ['Location:', reportMetadata.locationName],
    ['Time:', reportMetadata.time],
    ['Date:', new Date(reportMetadata.date).toDateString()],
  ];

  await createWorksheet(workbook, 'Overview', { data: metadataSheetValues });
  await createWorksheet(workbook, 'By Category', { data: categoryData, columns: categoryColumns });
  await createWorksheet(workbook, 'By Sub-Category', { data: subCategoryData, columns: subCategoryColumns });
  await createWorksheet(workbook, 'By Item', { data: reportData, columns });

  saveWorkbook(workbook, `VarianceReport_${Date.now()}.xlsx`);
}
