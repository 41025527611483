import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { IQueryResponse, Query } from '@supy/common';

import { INVENTORY_PRODUCTIONS_BFF_URI } from '../../../config';
import {
  CreateInventoryProductionRequest,
  InventoryEventItemProps,
  InventoryEventRepeatStandardProps,
  InventoryEventRequestProps,
  InventoryProductionEvent,
  InventoryProductionSearchItem,
  ReplicationResponse,
  UpdateInventoryProductionRequest,
  UploadInventoryEventAttachmentQueryParams,
  UploadInventoryEventAttachmentResponse,
} from '../core';

@Injectable({ providedIn: 'root' })
export class InventoryProductionService {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(INVENTORY_PRODUCTIONS_BFF_URI) private readonly bffUrl: string,
  ) {}

  getByIdBff(id: string): Observable<InventoryProductionEvent> {
    return this.httpClient.get<InventoryProductionEvent>(`${this.bffUrl}/${id}`);
  }

  getManyBff(query: Query<InventoryEventRequestProps>): Observable<IQueryResponse<InventoryProductionEvent>> {
    return this.httpClient.get<IQueryResponse<InventoryProductionEvent>>(this.bffUrl, {
      params: new HttpParams({ fromObject: query.toQueryParams() }),
    });
  }

  createBff(body: CreateInventoryProductionRequest): Observable<InventoryProductionEvent> {
    return this.httpClient.post<InventoryProductionEvent>(this.bffUrl, body);
  }

  updateBff(body: UpdateInventoryProductionRequest): Observable<InventoryProductionEvent> {
    const { id, ...rest } = body;

    return this.httpClient.patch<InventoryProductionEvent>(`${this.bffUrl}/${id}`, rest);
  }

  deleteBff(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.bffUrl}/${id}`);
  }

  getItemsBff(query: InventoryEventItemProps): Observable<IQueryResponse<InventoryProductionSearchItem>> {
    return this.httpClient.get<IQueryResponse<InventoryProductionSearchItem>>(`${this.bffUrl}/items`, {
      params: new HttpParams({
        fromObject: { ...query, eventDate: new Date(query.eventDate).toISOString() },
      }),
    });
  }

  uploadAttachment(
    body: FormData,
    queryParams: UploadInventoryEventAttachmentQueryParams,
  ): Observable<UploadInventoryEventAttachmentResponse> {
    return this.httpClient.post<UploadInventoryEventAttachmentResponse>(`${this.bffUrl}/upload`, body, {
      params: new HttpParams({ fromObject: { ...queryParams } }),
    });
  }

  repeat(id: string, values: InventoryEventRepeatStandardProps): Observable<ReplicationResponse> {
    return this.httpClient.post<ReplicationResponse>(`${this.bffUrl}/${id}/repeat`, values);
  }
}
