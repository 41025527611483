<div class="supy-package-item level-{{ packageItem.level }}">
  <div class="supy-package-item__header">
    <h6 class="supy-package-tem__title">{{ itemName }} {{ packageItem.packageName }}</h6>
    <div class="supy-package-item__header-section">
      <supy-icon *ngIf="packageItem.isPreferred" name="star" color="warn"></supy-icon>
      <supy-dropdown-toggle
        *ngIf="!isReadonly && (canUpdatePackaging() || (canDeletePackaging() && isDeletable))"
        action
        [dropdownRef]="supyDropdown"
      >
        <supy-icon name="dots" size="small"></supy-icon>
      </supy-dropdown-toggle>
      <supy-dropdown #supyDropdown action>
        <supy-dropdown-item
          name="edit-packaging"
          type="icon"
          (click)="editPackageItem.emit(packageItem)"
          *ngIf="canUpdatePackaging()"
        >
          <supy-icon name="edit" [color]="getLevelColor(packageItem.level)"></supy-icon>
          <span i18n="@@edit">Edit</span>
        </supy-dropdown-item>
        <supy-dropdown-item
          *ngIf="isDeletable && canDeletePackaging()"
          name="delete-packaging"
          type="icon"
          color="error-tertiary"
          (click)="deletePackageItem.emit(packageItem)"
        >
          <supy-icon name="delete" color="error"></supy-icon>
          <span i18n="@@delete">Delete</span>
        </supy-dropdown-item>
      </supy-dropdown>
    </div>
  </div>
  <div
    *ngIf="packageItem.usedAsPiece"
    class="supy-package-item__piece-label level-{{ packageItem.level }}"
    i18n="@@packaging.item.asPiece"
  >
    Used as Piece
  </div>
  <supy-button
    *ngIf="allowedToAdd(packageItem.level) && canCreatePackaging()"
    variant="link"
    class="supy-package-item__add-packaging"
    name="add-packaging"
    (buttonClick)="addPackageItem.emit(packageItem)"
  >
    <supy-icon name="plus-flat-circle" color="primary"></supy-icon>
    <span i18n="@@common.actions.addPackaging">Add Packaging</span>
  </supy-button>
</div>
