import { Query } from '@supy/common';

import { StockMovementRequestProps } from '../../core';
import { StockMovementFilters, StockMovementRequestMetadata } from '../state';

export class StockMovementGetMany {
  static readonly type = '[StockMovement] GetMany';

  constructor(readonly query?: Query<StockMovementRequestProps>) {}
}

export class StockMovementInitFilters {
  static readonly type = '[StockMovement] InitFilters';
}

export class StockMovementPatchFilter {
  static readonly type = '[StockMovement] PatchFilter';
  constructor(readonly payload: Partial<StockMovementFilters>) {}
}

export class StockMovementResetFilter {
  static readonly type = '[StockMovement] ResetFilter';
}

export class StockMovementPatchRequestMeta {
  static readonly type = '[StockMovement] PatchRequestMeta';
  constructor(readonly payload: Partial<StockMovementRequestMetadata>) {}
}

export class StockMovementExport {
  static readonly type = '[StockMovement] Export';
}

export class StockMovementResetExportData {
  static readonly type = '[StockMovement] ResetExportData';
}
