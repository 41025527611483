import { SkeletonObjectType } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';

export interface PriceHistoryRecordApi {
  readonly item: SkeletonObjectType;
  readonly supplier: SkeletonObjectType;
  readonly newPrice: string;
  readonly oldPrice: string | null;
  readonly updatedAt: Date;
}

export class PriceHistoryRecord {
  readonly id: string;
  readonly item: SkeletonObjectType;
  readonly supplier: SkeletonObjectType;
  readonly newPrice: number;
  readonly oldPrice: number;
  readonly updatedAt: Date;

  constructor(args: NonFunctionProperties<PriceHistoryRecord>) {
    this.id = args.id;
    this.item = args.item;
    this.supplier = args.supplier;
    this.newPrice = args.newPrice;
    this.oldPrice = args.oldPrice;
    this.updatedAt = args.updatedAt;
  }

  static deserialize(args: PriceHistoryRecordApi): PriceHistoryRecord {
    return new PriceHistoryRecord({
      ...args,
      newPrice: Number(args.newPrice),
      oldPrice: args.oldPrice !== null ? Number(args.oldPrice) : Number(args.newPrice),
      id: crypto.randomUUID(),
    });
  }

  static deserializeList(data: PriceHistoryRecordApi[]): PriceHistoryRecord[] {
    return data.map(priceHistoryRecordApi => PriceHistoryRecord.deserialize(priceHistoryRecordApi));
  }
}

export interface PriceHistoryRequestProps {
  readonly 'location.id': string;
  readonly 'supplier.id': string;
  readonly 'retailerItem.id': string;
  readonly fromDate: Date;
  readonly toDate: Date;
}

export class PriceHistoryGridItem extends PriceHistoryRecord {
  readonly changeAmount: number;
  readonly changePercentage: number;

  constructor(args: NonFunctionProperties<PriceHistoryGridItem>) {
    super(args);

    this.changeAmount = args.changeAmount;
    this.changePercentage = args.changePercentage;
  }

  static from(priceHistoryRecord: PriceHistoryRecord): PriceHistoryGridItem {
    const { oldPrice, newPrice } = priceHistoryRecord;
    const changeAmount = newPrice - oldPrice;
    const changePercentage = oldPrice ? (changeAmount / oldPrice) * 100 : 0;

    return { ...priceHistoryRecord, changeAmount, changePercentage };
  }

  static fromList(priceHistory: PriceHistoryRecord[]): PriceHistoryGridItem[] {
    return priceHistory.map(priceHistoryRecord => PriceHistoryGridItem.from(priceHistoryRecord));
  }
}
