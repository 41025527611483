import { ChannelItemState } from '@supy/channel-items';
import {
  IdType,
  InvoiceUnit,
  InvoiceUnitTypeEnum,
  LocalizedData,
  removeEmpty,
  Supplier,
  SupplierPartnershipTypeEnum,
} from '@supy/common';
import { NonFunctionProperties } from '@supy/core';
import { Packaging } from '@supy/packaging';
import { getLocalizedName } from '@supy/settings';

import {
  BaseItemSupplierApi,
  BaseSupplierItemApi,
  CreateBaseItemSupplierRequest,
  ManageBaseItemSuppliersRequest,
  UpdateBaseItemSupplierRequest,
} from './base-item-supplier.model';

export class BaseItemSupplier {
  private constructor(args: NonFunctionProperties<BaseItemSupplier>) {
    this.id = args.id;
    this.packaging = args.packaging;
    this.supplier = args.supplier;
    this.itemName = args.itemName;
    this.itemCode = args.itemCode;
    this.price = args.price;
    this.pricingUom = args.pricingUom;
    this.totalPrice = args.totalPrice;
    this.isPriority = args.isPriority;
    this.channelItems = args.channelItems;
    this.state = args.state;
    this.isAccurate = args.isAccurate;
    this.retailerItem = args.retailerItem;
    this.ckItem = args.ckItem;
    this.hasCustomName = args.hasCustomName;
    this.isNew = args.isNew;
    this.supplierItem = args.supplierItem;
  }

  id: string;
  packaging: Packaging;
  supplier: Supplier;
  itemName: LocalizedData;
  itemCode: string;
  price: number;
  pricingUom: InvoiceUnit;
  totalPrice: number;
  isPriority: boolean;
  channelItems: string[];
  state: ChannelItemState;
  isAccurate: boolean;
  retailerItem: IdType;
  ckItem?: IdType;
  hasCustomName?: boolean;
  isNew?: boolean;
  supplierItem?: BaseSupplierItemApi;
  isDisabled?: boolean;

  static deserialize(data: BaseItemSupplierApi): BaseItemSupplier {
    return new BaseItemSupplier({
      id: crypto.randomUUID().slice(0, 6),
      packaging: data.packaging,
      supplier: data.supplier,
      itemName: data.supplierItem.name,
      itemCode: data.supplierItem.code,
      price: data.price,
      pricingUom: data.supplierItem.invoiceUnit,
      totalPrice: data.totalPrice,
      isPriority: data.isPriority,
      channelItems: data.channelItems,
      state: data.state,
      isAccurate: data.isAccurate,
      retailerItem: data.retailerItem,
      ckItem: data.ckItem,
      hasCustomName: data.supplierItem.hasCustomName,
      supplierItem: data.supplierItem,
    });
  }

  static from(data: BaseItemSupplier): BaseItemSupplier {
    return new BaseItemSupplier({ ...data });
  }

  static deserializeList(data: BaseItemSupplierApi[]): BaseItemSupplier[] {
    return data.map(itemSupplier => BaseItemSupplier.deserialize(itemSupplier));
  }

  static serialize(data: CachedBaseItemSuppliers): ManageBaseItemSuppliersRequest {
    return {
      create: data?.create?.map(this.serializeCreate),
      update: data?.update?.map(this.serializeUpdate),
      delete: data?.delete?.map(({ channelItems: channelItemIds }) => channelItemIds).flat(),
    };
  }

  static default(): BaseItemSupplier {
    return new BaseItemSupplier({
      id: crypto.randomUUID(),
      channelItems: [],
      isPriority: false,
      itemCode: '',
      itemName: LocalizedData.default(),
      price: 0,
      totalPrice: 0,
      supplier: {} as Supplier,
      packaging: {} as Packaging,
      pricingUom: { type: InvoiceUnitTypeEnum.Uom, name: '', conversionToAtom: 0, uom: IdType.default() },
      state: ChannelItemState.Available,
      isAccurate: true,
      retailerItem: {} as IdType,
      ckItem: {} as IdType,
      hasCustomName: false,
      isNew: true,
    });
  }

  private static serializeCreate(data: BaseItemSupplier): CreateBaseItemSupplierRequest {
    const invoiceUnitId = data.pricingUom?.type
      ? data.pricingUom?.type === InvoiceUnitTypeEnum.Package
        ? data?.pricingUom?.packaging?.id
        : data?.pricingUom?.uom?.id
      : undefined;

    return removeEmpty({
      packaging: data?.packaging?.id && {
        id: data.packaging.id,
      },
      supplier: data?.supplier?.id && {
        id: data.supplier.id,
      },
      supplierItem: (data?.itemCode || data?.itemName || invoiceUnitId) && {
        code: data.itemCode || undefined,
        name: getLocalizedName(data.itemName) || undefined,
        hasCustomName: data.hasCustomName ?? false,
        invoiceUnit: invoiceUnitId
          ? {
              id:
                data.pricingUom.type === InvoiceUnitTypeEnum.Package
                  ? data.pricingUom.packaging.id
                  : data.pricingUom.uom?.id,
              scope: data.pricingUom.type,
              ...(data.supplier?.metadata?.partnershipType === SupplierPartnershipTypeEnum.Integrated && {
                name: data.pricingUom.name,
              }),
            }
          : undefined,
      },
      price: data?.price,
      isPriority: data?.isPriority,
      ckItem: data?.ckItem?.id ? { id: data?.ckItem.id } : undefined,
    });
  }

  static serializeUpdate(data: BaseItemSupplier): UpdateBaseItemSupplierRequest {
    return {
      ...BaseItemSupplier.serializeCreate(data),
      channelItems: data.channelItems,
      state: data.state,
    };
  }

  static calculateTotalPrice(baseItemSupplier: BaseItemSupplier): number {
    if (
      baseItemSupplier &&
      baseItemSupplier.price &&
      Number(baseItemSupplier.packaging?.baseUnit?.conversionToAtom) &&
      Number(baseItemSupplier.pricingUom?.conversionToAtom)
    ) {
      return (
        (baseItemSupplier.price *
          (baseItemSupplier.packaging?.baseUnit?.conversionToAtom ?? 0) *
          baseItemSupplier.packaging?.quantity?.baseUnit) /
        baseItemSupplier.pricingUom?.conversionToAtom
      );
    }

    return 0;
  }

  static isValid(baseItemSupplier: BaseItemSupplier): boolean {
    if (!baseItemSupplier || (baseItemSupplier.supplier?.isExposed && !baseItemSupplier.ckItem?.id)) {
      return false;
    }

    return (
      !!baseItemSupplier.supplier?.id &&
      !!baseItemSupplier.pricingUom?.conversionToAtom &&
      !!baseItemSupplier.packaging?.baseUnit?.conversionToAtom &&
      baseItemSupplier.price >= 0
    );
  }
}

export interface CachedBaseItemSuppliers {
  create?: BaseItemSupplier[];
  update?: BaseItemSupplier[];
  delete?: BaseItemSupplier[];
}
