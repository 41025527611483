<supy-grid
  #grid
  primaryKey="id"
  class="supy-create-recipe__cost-centers-multiple-grid"
  [data]="multipleCostCentersData"
  [height]="null"
  [allowFiltering]="true"
  [showAddRowEmptyButton]="false"
  [emptyGridTemplate]="emptyGridTemplate"
>
  <supy-grid-column width="35%" field="branch.name" header="Branch Name">
    <ng-template supyGridCell let-value>
      <strong>{{ value }}</strong>
    </ng-template>
  </supy-grid-column>
  <supy-grid-column width="15%" header="Revenue Percentage (%)" [filterable]="false"></supy-grid-column>

  <supy-grid-column width="15%" header="Cost Centers" [filterable]="false">
    <ng-template supyGridCell let-rowData="rowData">
      <span>{{ getLocationsLength(rowData) }}</span>
    </ng-template>
  </supy-grid-column>

  <supy-grid-column width="15%" header="Selling Price ({{ currency }})" [filterable]="false">
    <ng-template supyGridCell let-rowData="rowData">
      <supy-input
        class="supy-create-recipe__cost-centers-multiple-grid-input-control"
        name="selling-price"
        placeholder="Selling Price"
        type="number"
        [min]="0"
        [step]="0.001"
        [value]="rowData.sellingPrice"
        (valueChange)="rowData.sellingPrice = $event"
        (focusOut)="onCellEditDone()"
      ></supy-input>
    </ng-template>
  </supy-grid-column>

  <supy-grid-column width="15%" header="Taxes" [filterable]="false">
    <ng-template supyGridCell let-rowData="rowData">
      <supy-combo-box
        class="supy-create-recipe__cost-center-grid-selector"
        placeholder="Taxes"
        name="select-tax"
        valueKey="id"
        displayKey="name"
        titleKey="name"
        [value]="rowData.taxes"
        [list]="taxes"
        [multiple]="true"
        (changed)="rowData.taxes = $event"
        (closed)="onCellEditDone()"
      >
      </supy-combo-box>
    </ng-template>
  </supy-grid-column>

  <ng-template supyGridDetail let-dataItem>
    <div class="supy-create-recipe__cost-centers-multiple-grid-detail">
      <ng-container *ngIf="dataItem.locations?.length">
        <div
          *ngFor="let costCenter of dataItem.locations; let index = index; trackBy: trackByIndex"
          class="supy-create-recipe__cost-centers-multiple-grid-detail-item"
        >
          <div [style.width]="'44%'" class="supy-create-recipe__cost-centers-multiple-grid-detail-item-cell">
            <supy-select
              class="supy-create-recipe__cost-centers-grid-selector"
              placeholder="Select Location"
              name="select-location"
              [value]="costCenter?.location"
              (selected)="onLocationSelect($event, costCenter, dataItem)"
              (closed)="onCellEditDone()"
            >
              <supy-select-item
                *ngFor="let location of dataItem.branch.children; trackBy: trackById"
                [value]="location"
              >
                {{ location.name }}
              </supy-select-item>
            </supy-select>
          </div>
          <div [style.width]="'20%'" class="supy-create-recipe__cost-centers-multiple-grid-detail-item-cell">
            <supy-input
              class="supy-create-recipe__cost-centers-multiple-grid-input-control"
              name="revenue-percentage"
              placeholder="Revenue %"
              type="number"
              [min]="0"
              [step]="0.001"
              [value]="costCenter.revenuePercentage"
              (valueChange)="costCenter.revenuePercentage = $event"
              (focusOut)="onCellEditDone()"
            ></supy-input>
          </div>
          <div [style.width]="'23%'" class="supy-create-recipe__cost-centers-multiple-grid-detail-item-cell"></div>

          <div [style.width]="'7%'" class="supy-create-recipe__cost-centers-multiple-grid-detail-item-cell">
            <span>{{
              getCostCenterSellingPrice(costCenter.revenuePercentage, dataItem.sellingPrice)
                | supyPrecision: currencyPrecision
            }}</span>
          </div>

          <div [style.width]="'30%'" class="supy-create-recipe__cost-centers-multiple-grid-detail-item-cell"></div>

          <div [style.width]="'5%'" class="supy-create-recipe__cost-centers-multiple-grid-detail-item-cell">
            <supy-button type="icon" color="default" name="delete" (buttonClick)="onModify(dataItem.id, index)">
              <supy-icon name="delete" color="error"></supy-icon>
            </supy-button>
          </div>
        </div>
      </ng-container>
      <a class="supy-create-recipe__cost-centers-multiple-grid-detail-link" (click)="onModify(dataItem.id)"
        ><span>+ Add Cost Center</span></a
      >

      <div
        [style.visibility]="!isRevenuePercentageValid(dataItem.id) ? 'visible' : 'hidden'"
        class="supy-create-recipe__cost-centers-multiple-grid-detail-error"
      >
        Revenue percentage per branch must be 100%
      </div>
    </div>
  </ng-template>

  <ng-template #emptyGridTemplate>
    <span class="supy-create-recipe__cost-centers-grid-empty">There are no valid locations for this recipe</span>
  </ng-template>
</supy-grid>
