<supy-drawer [showPagination]="false" (dialogClosed)="closeDialog()">
  <div header>
    <h4>{{ headerName() }}</h4>

    @if (this.recipePlace() === 'inventory') {
      <small> {{ locationInfo() }}</small>
    }
  </div>

  <div content>
    <supy-tabs [items]="tabs()" queryParamsHandling="preserve" />

    <router-outlet />
  </div>

  <ng-container footer>
    <supy-button name="go-back" color="secondary" (buttonClick)="closeDialog()" i18n="@@goBack"> Go Back </supy-button>
  </ng-container>
</supy-drawer>
