<supy-grid
  class="supy-price-history-grid"
  primaryKey="id"
  [data]="priceHistoryGridItems()"
  [paginationMode]="paginationMode"
  [page]="requestMetadata.page"
  [perPage]="requestMetadata.limit"
  [prevPageDisabled]="requestMetadata.page === 0"
  [nextPageDisabled]="responseMetadata.count < requestMetadata.limit"
  (pageChange)="pageChange.emit($event)"
>
  <supy-grid-column
    header="Price Change Date"
    i18n-header="@@grid.headers.priceChangeDate.label"
    field="updatedAt"
    width="20%"
  >
    <ng-template supyGridCell let-value>
      {{ value | timezone: ianaTimeZone | date: 'dd/MM/yyyy' }}
    </ng-template>
  </supy-grid-column>
  <supy-grid-column header="Item Name" i18n-header="@@common.itemName" field="item.name" width="16%"></supy-grid-column>
  <supy-grid-column
    header="Supplier Name"
    i18n-header="@@grid.headers.supplierName.label"
    field="supplier.name"
    width="16%"
  ></supy-grid-column>
  <supy-grid-column
    header="New Price ({{ currency }})"
    i18n-header="@@grid.headers.newPrice.label"
    field="newPrice"
    width="16%"
  >
    <ng-template supyGridCell let-value>
      <span>
        {{ value | supyPrecision: currencyPrecision }}
      </span>
    </ng-template>
  </supy-grid-column>
  <supy-grid-column
    header="Change Amount ({{ currency }})"
    i18n-header="@@grid.headers.changeAmount.label"
    field="changeAmount"
    width="16%"
  >
    <ng-template supyGridCell let-value>
      <span [ngClass]="[value < 0 ? 'supy-price-history-grid__cell--success' : 'supy-price-history-grid__cell--error']">
        {{ value | supyPrecision: currencyPrecision }}
      </span>
    </ng-template>
  </supy-grid-column>
  <supy-grid-column
    header="Change Percentage"
    i18n-header="@@grid.headers.changePercentage.label"
    field="changePercentage"
    width="16%"
  >
    <ng-template supyGridCell let-value let-rowData="rowData">
      <span
        *ngIf="rowData.oldPrice"
        [ngClass]="[value < 0 ? 'supy-price-history-grid__cell--success' : 'supy-price-history-grid__cell--error']"
      >
        {{ value | supyPrecision: 2 }}%
      </span>
    </ng-template>
  </supy-grid-column>
</supy-grid>
