import produce from 'immer';
import { tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, createSelector, Selector, State, StateContext, StateToken } from '@ngxs/store';

import {
  BaseRequestMetadata,
  BaseResponseMetadata,
  EntityListDateRangeFilter,
  EntityListState,
  EntityListStateModel,
  Query,
  QueryBuilder,
  QueryPaging,
} from '@supy/common';

import { InventoryItemsService } from '../../../../services';
import { ItemStockMovement, StockMovementRequestProps } from '../../core';
import {
  StockMovementExport,
  StockMovementGetMany,
  StockMovementInitFilters,
  StockMovementPatchFilter,
  StockMovementPatchRequestMeta,
  StockMovementResetExportData,
  StockMovementResetFilter,
} from '../actions';

export interface StockMovementStateModel extends EntityListStateModel<ItemStockMovement> {
  readonly exportData: ItemStockMovement[];
  readonly filters: StockMovementFilters;
  readonly requestMetadata: StockMovementRequestMetadata;
  readonly responseMetadata: StockMovementResponseMetadata;
}

export interface StockMovementFilters extends EntityListDateRangeFilter {
  readonly eventType: string | null;
}

export interface StockMovementMappedFilters {
  readonly eventType: string;
  readonly dateRange: { readonly start: Date; readonly end: Date };
}

export interface StockMovementRequestMetadata extends BaseRequestMetadata {
  readonly retailerId: string | null;
  readonly locationId: string | null;
  readonly inventoryItemId: string | null;
}

export interface StockMovementResponseMetadata extends BaseResponseMetadata {}

const STOCK_MOVEMENT_STATE_TOKEN = new StateToken<StockMovementStateModel[]>('stockMovement');

const FILTERS_DEFAULT: StockMovementFilters = {
  eventType: null,
  start: null,
  end: null,
};

const REQUEST_META_DEFAULT: StockMovementRequestMetadata = {
  page: 0,
  limit: 25,
  retailerId: null,
  locationId: null,
  inventoryItemId: null,
};
const RESPONSE_META_DEFAULT: StockMovementResponseMetadata = { total: 0, count: 0 };

@State<StockMovementStateModel>({
  name: STOCK_MOVEMENT_STATE_TOKEN,
  defaults: {
    ...EntityListState.default(),
    exportData: [],
    filters: FILTERS_DEFAULT,
    requestMetadata: REQUEST_META_DEFAULT,
    responseMetadata: RESPONSE_META_DEFAULT,
  },
})
@Injectable()
export class StockMovementState extends EntityListState {
  constructor(
    protected readonly router: Router,
    private readonly inventoryItemService: InventoryItemsService,
  ) {
    super(router);
  }

  @Selector()
  static items(state: StockMovementStateModel) {
    return EntityListState.all(state);
  }

  @Selector()
  static currentItem(state: StockMovementStateModel) {
    return EntityListState.current(state);
  }

  static item(id: string, next?: boolean) {
    return createSelector([StockMovementState], (state: StockMovementStateModel) => {
      return EntityListState.one(id, next)(state);
    });
  }

  @Selector()
  static exportData(state: StockMovementStateModel) {
    return state.exportData;
  }

  @Selector()
  static appliedFiltersCount(state: StockMovementStateModel) {
    return EntityListState.appliedFiltersCount(state, FILTERS_DEFAULT);
  }

  @Selector()
  static filters(state: StockMovementStateModel) {
    return EntityListState.currentFilters<StockMovementFilters>(state);
  }

  @Selector()
  static requestMetadata(state: StockMovementStateModel) {
    return state.requestMetadata;
  }

  @Selector()
  static responseMetadata(state: StockMovementStateModel) {
    return state.responseMetadata;
  }

  @Selector()
  static isFirst(state: StockMovementStateModel) {
    return EntityListState.isFirst(state);
  }

  @Selector()
  static isLast(state: StockMovementStateModel) {
    return EntityListState.isLast(state);
  }

  @Action(StockMovementInitFilters)
  inventoryStocksInitFilter(ctx: StateContext<StockMovementStateModel>) {
    super.initFilter(ctx, FILTERS_DEFAULT);
  }

  @Action(StockMovementPatchFilter)
  patchStockMovementFilter(ctx: StateContext<StockMovementStateModel>, action: StockMovementPatchFilter) {
    super.patchFilter(ctx, action.payload, FILTERS_DEFAULT);
  }

  @Action(StockMovementResetFilter)
  resetStockMovementFilter(ctx: StateContext<StockMovementStateModel>) {
    super.resetFilter(ctx, FILTERS_DEFAULT);
  }

  @Action(StockMovementGetMany, { cancelUncompleted: true })
  getStockMovement(ctx: StateContext<StockMovementStateModel>) {
    return this.inventoryItemService.getStockMovement(this.getQuery(ctx.getState())).pipe(
      tap(({ data, metadata }) => {
        ctx.patchState({ responseMetadata: metadata });
        super.setMany(ctx, data);
      }),
    );
  }

  @Action(StockMovementExport, { cancelUncompleted: true })
  exportStockMovement(ctx: StateContext<StockMovementStateModel>) {
    return this.inventoryItemService.getStockMovement(this.getQuery(ctx.getState(), true)).pipe(
      tap(({ data }) => {
        ctx.patchState({ exportData: data });
      }),
    );
  }

  @Action(StockMovementResetExportData)
  resetExportData(ctx: StateContext<StockMovementStateModel>) {
    ctx.patchState({ exportData: [] });
  }

  @Action(StockMovementPatchRequestMeta)
  patchRequestMetadata(ctx: StateContext<StockMovementStateModel>, { payload }: StockMovementPatchRequestMeta) {
    ctx.setState(
      produce(draft => {
        draft.requestMetadata = {
          ...ctx.getState().requestMetadata,
          ...payload,
        };
      }),
    );
  }

  private getQuery(state: StockMovementStateModel, noPagination = false): Query<StockMovementRequestProps> {
    const { eventType, start, end } = state.filters;

    const qb = new QueryBuilder<StockMovementRequestProps>({
      filtering: [
        {
          by: 'item.id',
          match: state.requestMetadata.inventoryItemId,
          op: 'eq',
        },
        {
          by: 'location.id',
          match: state.requestMetadata.locationId,
          op: 'eq',
        },
      ],
      paging: !noPagination
        ? { offset: state.requestMetadata.page * state.requestMetadata.limit, limit: state.requestMetadata.limit }
        : QueryPaging.NoLimit,
      ordering: [],
    });

    if (eventType) {
      qb.filtering.setFilter({
        by: 'issuer.type',
        match: eventType,
        op: 'in',
      });
    }

    if (start && end) {
      qb.filtering.withFiltering([
        { by: 'eventDate', op: 'gte', match: start },
        { by: 'eventDate', op: 'lte', match: end },
      ]);
    }

    return qb.build();
  }
}
