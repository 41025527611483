import { getLocalizedName } from '@supy/settings';

import {
  CostAdjustmentData,
  CostAdjustmentDetail,
  CostAdjustmentGroup,
  CostAdjustmentItemIngredientDetailType,
  CostAdjustmentRecipeIngredientDetailType,
  ItemIngredientDetail,
  RecipeIngredientDetail,
} from './cost-adjustment.model';

export const CostAdjustmentsGroupRowType = 'costAdjustmentsGroup';

export class EnhancedCostAdjustmentsGroup implements CostAdjustmentGroup {
  readonly id: string;
  readonly cost: number;
  readonly date: string;
  readonly percentage?: number;
  readonly updatedAt: string;
  readonly details: string = '';
  isExpanded = false;
  readonly rowType = CostAdjustmentsGroupRowType;

  detailsData?: {
    detailsChanged: boolean;
    itemIngredients: CostAdjustmentItemIngredientDetail[];
    recipeIngredients: CostAdjustmentRecipeIngredientDetail[];
  };

  private constructor(args: CostAdjustmentData) {
    this.id = crypto.randomUUID();
    this.cost = args.cost;
    this.date = args.date;
    this.percentage = args.percentage;
    this.updatedAt = args.updatedAt;
  }

  static deserialize(data: CostAdjustmentData): EnhancedCostAdjustmentsGroup {
    return new EnhancedCostAdjustmentsGroup(data);
  }
}

export class CostAdjustmentsDetailGroup implements CostAdjustmentDetail {
  readonly detailsChanged: boolean;
  readonly itemIngredients: CostAdjustmentItemIngredientDetailType[];
  readonly recipeIngredients: CostAdjustmentRecipeIngredientDetailType[];

  private constructor(args: {
    detailsChanged: boolean;
    itemIngredients: CostAdjustmentItemIngredientDetailType[];
    recipeIngredients: CostAdjustmentRecipeIngredientDetailType[];
  }) {
    this.detailsChanged = args.detailsChanged;
    this.itemIngredients = args.itemIngredients;
    this.recipeIngredients = args.recipeIngredients;
  }

  static deserialize(data: {
    detailsChanged: boolean;
    itemIngredients: ItemIngredientDetail[];
    recipeIngredients: RecipeIngredientDetail[];
  }): CostAdjustmentsDetailGroup {
    return new CostAdjustmentsDetailGroup({
      detailsChanged: data.detailsChanged,
      itemIngredients: data.itemIngredients.map(item => CostAdjustmentItemIngredientDetail.deserialize(item)),
      recipeIngredients: data.recipeIngredients.map(item => CostAdjustmentRecipeIngredientDetail.deserialize(item)),
    });
  }
}

export class CostAdjustmentItemIngredientDetail implements CostAdjustmentItemIngredientDetailType {
  readonly itemId: string;
  readonly cost: number;
  readonly name: string;
  readonly percentage: number;

  private constructor(args: ItemIngredientDetail) {
    this.itemId = args.itemId;
    this.cost = args.cost;
    this.name = $localize`:@@costAdjustments.ingredientsCostUpdate:Ingredients cost update - ${getLocalizedName(args.name)}`;
    this.percentage = args.percentage;
  }

  static deserialize(data: ItemIngredientDetail): CostAdjustmentItemIngredientDetail {
    return new CostAdjustmentItemIngredientDetail(data);
  }
}

export class CostAdjustmentRecipeIngredientDetail implements CostAdjustmentRecipeIngredientDetailType {
  readonly recipeId: string;
  readonly cost: number;
  readonly name: string;

  readonly percentage: number;

  private constructor(args: RecipeIngredientDetail) {
    this.recipeId = args.recipeId;
    this.cost = args.cost;
    this.name = $localize`:@@costAdjustments.subRecipeDetailsChanged:Sub recipe details changed - ${getLocalizedName(args.name)}`;
    this.percentage = args.percentage;
  }

  static deserialize(data: RecipeIngredientDetail): CostAdjustmentRecipeIngredientDetail {
    return new CostAdjustmentRecipeIngredientDetail(data);
  }
}
