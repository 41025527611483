import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { IQueryResponse, Query } from '@supy/common';

import { INVENTORY_TRANSFERS_BFF_URI } from '../../../config';
import {
  CreateInventoryTransferRequest,
  DownloadTransferPdfResponse,
  InventoryEventItemProps,
  InventoryEventRepeatTransferProps,
  InventoryTransferCountPerState,
  InventoryTransferEvent,
  InventoryTransferSearchItem,
  InventoryTransfersRequestProps,
  ReplicationResponse,
  UpdateInventoryTransferRequest,
  UploadInventoryEventAttachmentQueryParams,
  UploadInventoryEventAttachmentResponse,
} from '../core';

@Injectable({ providedIn: 'root' })
export class InventoryTransferService {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(INVENTORY_TRANSFERS_BFF_URI) private readonly bffUrl: string,
  ) {}

  getByIdBff(id: string): Observable<InventoryTransferEvent> {
    return this.httpClient.get<InventoryTransferEvent>(`${this.bffUrl}/${id}`);
  }

  getManyBff(query: Query<InventoryTransfersRequestProps>): Observable<IQueryResponse<InventoryTransferEvent>> {
    return this.httpClient.get<IQueryResponse<InventoryTransferEvent>>(this.bffUrl, {
      params: new HttpParams({ fromObject: query.toQueryParams() }),
    });
  }

  getStats(retailerId: string): Observable<InventoryTransferCountPerState[]> {
    return this.httpClient.get<InventoryTransferCountPerState[]>(`${this.bffUrl}/stats`, {
      params: new HttpParams({ fromObject: { retailerId } }),
    });
  }

  createBff(body: CreateInventoryTransferRequest): Observable<InventoryTransferEvent> {
    return this.httpClient.post<InventoryTransferEvent>(this.bffUrl, body);
  }

  updateBff(body: UpdateInventoryTransferRequest): Observable<InventoryTransferEvent> {
    const { id, ...rest } = body;

    return this.httpClient.patch<InventoryTransferEvent>(`${this.bffUrl}/${id}`, rest);
  }

  deleteBff(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.bffUrl}/${id}`);
  }

  getItemsBff(query: InventoryEventItemProps): Observable<IQueryResponse<InventoryTransferSearchItem>> {
    return this.httpClient.get<IQueryResponse<InventoryTransferSearchItem>>(`${this.bffUrl}/items`, {
      params: new HttpParams({
        fromObject: { ...query, eventDate: new Date(query.eventDate).toISOString() },
      }),
    });
  }

  uploadAttachment(
    body: FormData,
    queryParams: UploadInventoryEventAttachmentQueryParams,
  ): Observable<UploadInventoryEventAttachmentResponse> {
    return this.httpClient.post<UploadInventoryEventAttachmentResponse>(`${this.bffUrl}/upload`, body, {
      params: new HttpParams({ fromObject: { ...queryParams } }),
    });
  }

  repeat(id: string, values: InventoryEventRepeatTransferProps): Observable<ReplicationResponse> {
    return this.httpClient.post<ReplicationResponse>(`${this.bffUrl}/${id}/repeat`, values);
  }

  getTransferPdf(transferId: string): Observable<DownloadTransferPdfResponse> {
    return this.httpClient.get<DownloadTransferPdfResponse>(`${this.bffUrl}/${transferId}/download`);
  }
}
