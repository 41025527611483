import { InventoryStockCountPackaging, UpdateInventorySubStockCountInput } from '../../core';
import { InventoryStockCountItemsFilters, InventoryStockCountItemsRequestMetadata } from '../state';

export class InventoryStockCountItemsGetMany {
  static readonly type = '[InventoryStockCountItems] GetMany';
  constructor(readonly payload?: { id?: string; fromCache?: boolean; noLimit?: boolean }) {}
}

export class InventoryStockCountItemsGetExportable {
  static readonly type = '[InventoryStockCountItems] GetExportable';
}

export class InventoryStockCountItemsInitFilters {
  static readonly type = '[InventoryStockCountItems] InitFilters';
}

export class InventoryStockCountItemsSetFilters {
  static readonly type = '[InventoryStockCountItems] SetFilters';
  constructor(readonly payload: InventoryStockCountItemsFilters) {}
}

export class InventoryStockCountItemsPatchFilters {
  static readonly type = '[InventoryStockCountItems] PatchFilters';
  constructor(readonly payload: Partial<InventoryStockCountItemsFilters>) {}
}

export class InventoryStockCountItemsResetFilters {
  static readonly type = '[InventoryStockCountItems] ResetFilters';
}

export class InventoryStockCountItemsPatchRequestMeta {
  static readonly type = '[InventoryStockCountItems] PatchRequestMeta';
  constructor(readonly payload: Partial<InventoryStockCountItemsRequestMetadata>) {}
}

export class InventoryStockCountItemsSetType {
  static readonly type = '[InventoryStockCountItems] SetType';
  constructor(readonly isSubStockCount: boolean) {}
}

export class InventoryStockCountItemsUnHideAllPackages {
  static readonly type = '[InventoryStockCountItems] UnHideAllPackages';
  constructor(readonly retailerItemId: string) {}
}

export class InventoryStockCountItemsSetPackageAsDefault {
  static readonly type = '[InventoryStockCountItems] SetPackageAsDefault';
  constructor(
    readonly retailerItemId: string,
    readonly itemPackage: InventoryStockCountPackaging,
  ) {}
}

export class InventoryStockCountItemsHidePackage {
  static readonly type = '[InventoryStockCountItems] HidePackage';
  constructor(
    readonly retailerItemId: string,
    readonly itemPackage: InventoryStockCountPackaging,
  ) {}
}

export class InventoryStockCountItemsSave {
  static readonly type = '[InventoryStockCountItems] Save';
  constructor(
    readonly payload: {
      readonly stockCountId: string;
      readonly subStockCountId: string;
      readonly body: UpdateInventorySubStockCountInput;
    },
  ) {}
}

export class InventoryStockCountItemsReopen {
  static readonly type = '[InventoryStockCountItems] Reopen';
  constructor(
    readonly payload: {
      readonly stockCountId: string;
    },
  ) {}
}

export class InventoryStockCountItemsDiscard {
  static readonly type = '[InventoryStockCountItems] Discard';
  constructor(
    readonly id: string,
    readonly subCountId: string,
  ) {}
}

export class InventoryStockCountItemsResetStockCount {
  static readonly type = '[InventoryStockCountItems] ResetStockCount';
}

export class InventoryStockCountItemsToggleZeroingUncounted {
  static readonly type = '[InventoryStockCountItems] ToggleZeroingUncounted';

  constructor(readonly id: string) {}
}
