import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { InventoryEventType } from '@supy/common';
import { BadgeStatus } from '@supy/components';

import { InventoryEventStateEnum, InventoryTransferMode, InventoryTransferStateEnum } from '../../core';

@Component({
  selector: 'supy-inventory-event-status',
  templateUrl: './inventory-event-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryEventStatusComponent {
  @Input() readonly status: string;
  @Input() readonly circle: boolean;
  @Input() readonly eventType: InventoryEventType;

  protected readonly eventStatusMapper: Record<string, BadgeStatus> = {
    [InventoryEventStateEnum.Submitted]: 'success',
    [InventoryEventStateEnum.Draft]: 'warn',
    [InventoryEventStateEnum.Archived]: 'error',
  };

  protected readonly transferStatusMapper: Record<string, BadgeStatus> = {
    [InventoryTransferStateEnum.Draft]: 'grey',
    [InventoryTransferStateEnum.Submitted]: 'warn',
    [InventoryTransferStateEnum.Received]: 'success',
    [InventoryTransferStateEnum.Archived]: 'error',
    [InventoryTransferStateEnum.Rejected]: 'error',
    [InventoryTransferMode.Incoming]: 'info',
    [InventoryTransferMode.Outgoing]: 'success',
  };

  get eventStatus(): BadgeStatus {
    return this.eventType === InventoryEventType.Transfer
      ? this.transferStatusMapper[this.status]
      : this.eventStatusMapper[this.status];
  }
}
