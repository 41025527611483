import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { AuthzModule } from '@supy/authz';
import {
  ButtonModule,
  ComboboxModule,
  DialogModule,
  DropdownModule,
  IconModule,
  InputModule,
  LoaderModule,
  SwitchModule,
} from '@supy/components';

import {
  CreatePackageItemDialogComponent,
  PackageItemComponent,
  PackageLevelPipe,
  PackagesGridComponent,
} from './components';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    IconModule,
    InputModule,
    SwitchModule,
    ComboboxModule,
    DialogModule,
    LoaderModule,
    DropdownModule,
    AuthzModule,
  ],
  declarations: [PackagesGridComponent, PackageItemComponent, CreatePackageItemDialogComponent, PackageLevelPipe],
  exports: [PackagesGridComponent, PackageItemComponent, CreatePackageItemDialogComponent],
})
export class PackagingModule {}
