<div class="supy-recipe-portion">
  <form
    [formGroup]="form"
    class="supy-recipe-packaging-tab__form-settings"
    [ngClass]="{ 'supy-recipe-packaging-tab__form-settings--disabled': form.disabled }"
  >
    <div class="supy-recipe-portion__title" i18n="@@inventory.recipe.costing.portion">Portion</div>
    <div class="supy-recipe-portion__controls">
      <div>
        <span i18n="@@common.baseUom">Base UOM</span>
        <supy-select name="portionUom" placeholder="UOM" formControlName="uom" (selected)="setComputedSizeValue()">
          <supy-select-item *ngFor="let baseUom of baseUoms" [value]="baseUom">
            {{ baseUom.name }}
          </supy-select-item>
        </supy-select>
      </div>
      <div>
        <span i18n="@@inventory.recipe.costing.portionSize">Portion Size</span>
        <supy-input
          name="portionSize"
          formControlName="size"
          type="number"
          placeholder="Portion Size"
          i18n-placeholder="@@inventory.recipe.costing.portionSize"
          [step]="0.001"
          [addonText]="portionUomValue?.name"
          (focusIn)="onFocusIn()"
          (focusOut)="setComputedYieldValue()"
          >Portion Size</supy-input
        >
      </div>

      <div *ngIf="!portionUomValue?.isPiece">
        <span
          title="This is automatically calculated based on the ingredients weight/volume"
          i18n-title="@@inventory.recipe.costing.yieldTitle"
          i18n="@@inventory.recipe.costing.yield"
          >Yield</span
        >
        <supy-input
          name="yield"
          formControlName="yield"
          type="number"
          placeholder="Yield"
          i18n-placeholder="@@inventory.recipe.costing.yield"
          addonText="%"
          [step]="0.01"
          (focusIn)="onFocusIn()"
          (focusOut)="setComputedSizeValue()"
        ></supy-input>
      </div>

      <div *ngIf="canStock">
        <supy-switch name="mark-stockable" formControlName="isStockable" i18n="@@allowRecipeStockable">
          Allow this recipe to be stockable
        </supy-switch>
      </div>
    </div>
  </form>
</div>
