import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxsModule } from '@ngxs/store';

import { SupyCommonModule } from '@supy/common';
import {
  ButtonModule,
  CheckboxModule,
  ComboboxModule,
  DateInputModule,
  DialogModule,
  GridModule,
  IconModule,
  InputModule,
  SelectModule,
  SwitchModule,
  TooltipModule,
} from '@supy/components';

import {
  AddLocationDialogComponent,
  BaseItemInventoryLocationsGridComponent,
  EditLocationDialogComponent,
  EffectiveDateConfirmationDialogComponent,
  InventoryLocationsComponent,
} from './components';
import { InventoryStockCountsState } from './modules';

@NgModule({
  imports: [
    CommonModule,
    SupyCommonModule,
    RouterModule,
    CheckboxModule,
    ButtonModule,
    IconModule,
    DialogModule,
    SelectModule,
    ReactiveFormsModule,
    InputModule,
    ComboboxModule,
    TooltipModule,
    GridModule,
    SwitchModule,
    DateInputModule,
    NgxsModule.forFeature([InventoryStockCountsState]),
  ],
  declarations: [
    InventoryLocationsComponent,
    AddLocationDialogComponent,
    EditLocationDialogComponent,
    BaseItemInventoryLocationsGridComponent,
    EffectiveDateConfirmationDialogComponent,
  ],
  exports: [
    InventoryLocationsComponent,
    AddLocationDialogComponent,
    EditLocationDialogComponent,
    BaseItemInventoryLocationsGridComponent,
    EffectiveDateConfirmationDialogComponent,
  ],
})
export class InventoryModule {}
