<div class="base-item-inventory-branches-grid">
  <supy-input
    name="search"
    suffix="search"
    placeholder="Search by location name"
    [latency]="100"
    (valueChange)="locationNameSearchText$.next($event)"
  ></supy-input>

  <supy-grid
    #grid
    primaryKey="id"
    [data]="data$ | async"
    [showAddRowEmptyButton]="false"
    (cellEditDone)="onCellEditDone()"
    (cellClick)="$event.cell.editMode = true"
  >
    <supy-grid-column width="20%" header="Location Name" [editable]="false">
      <ng-template supyGridCell let-rowData="rowData">
        {{ getLocationName(rowData.id) ?? '' }}
      </ng-template>
    </supy-grid-column>

    <supy-grid-column width="10%" header="UOM" field="packagingUnit" [editable]="!isReadonly">
      <ng-template supyGridCell let-value>
        {{ value?.name || 'Select UOM' }}
      </ng-template>
      <ng-template supyGridCellEditor let-cell="cell">
        <supy-select
          placeholder="Select UOM"
          name="select-uom"
          (selected)="cell.editValue = $event"
          (closed)="grid.endEdit()"
        >
          <ng-container>
            <supy-select-item *ngFor="let packagingUnit of packagingUnitsList$ | async" [value]="packagingUnit">
              {{ packagingUnit.name }}
            </supy-select-item>
          </ng-container>
        </supy-select>
      </ng-template>
    </supy-grid-column>
    <supy-grid-column width="10%" header="PAR Level" field="parLevel" dataType="number" [editable]="!isReadonly">
      <ng-template supyGridCellEditor let-cell="cell" let-rowData="rowData">
        <supy-input
          type="number"
          [focusOnInit]="true"
          [value]="cell.editValue"
          (valueChange)="onNumberValueChange(cell, $event)"
          (focusOut)="grid.endEdit()"
        ></supy-input>
      </ng-template>
    </supy-grid-column>
    <supy-grid-column width="10%" header="Min Level" field="minLevel" dataType="number" [editable]="!isReadonly">
      <ng-template supyGridCellEditor let-cell="cell" let-rowData="rowData">
        <supy-input
          type="number"
          [focusOnInit]="true"
          [value]="cell.editValue"
          (valueChange)="onNumberValueChange(cell, $event)"
          (focusOut)="grid.endEdit()"
        ></supy-input>
      </ng-template>
    </supy-grid-column>
    <supy-grid-column width="15%" header="Storages" field="storages" [editable]="!isReadonly">
      <ng-template supyGridCell let-value>
        <ng-container *ngIf="value?.length; else placeHolder">
          <ng-container *ngFor="let storage of value; first as isFirst">
            <span>{{ isFirst ? '' : ', ' }}{{ getLocalizedName(storage.name) ?? storage.name }}</span>
          </ng-container>
        </ng-container>
        <ng-template #placeHolder>
          {{ 'Select Storages' }}
        </ng-template>
      </ng-template>
      <ng-template supyGridCellEditor let-cell="cell" let-rowData="rowData">
        <supy-combo-box
          [list]="isInventoryItem ? branchStorageLocationsMap.get(rowData.id) : rowData.baseStorages"
          [multiple]="true"
          (closed)="grid.endEdit()"
          (changed)="onStorageValueChange(cell, rowData, $event)"
          [value]="cell.editValue ?? [] | pluck: 'id'"
          placeholder="Select Storages"
          name="select-storages"
          valueKey="id"
          titleKey="name.en"
          displayKey="name.en"
        ></supy-combo-box>
      </ng-template>
    </supy-grid-column>
  </supy-grid>
</div>
