<supy-drawer width="50vw" [showPagination]="false">
  <h4 header>Update State</h4>

  <div content>
    <supy-select name="state" placeholder="State" [value]="state()" (selected)="state.set($event)">
      <supy-select-item *ngFor="let st of states; trackBy: trackByValue" [value]="st.value">
        {{ st.label }}
      </supy-select-item>
    </supy-select>
  </div>

  <ng-container footer>
    <supy-button color="default-outline" name="cancel" (buttonClick)="closeDialog()">Cancel</supy-button>
    <supy-button color="primary" name="save" [disabled]="!state()" (buttonClick)="save()">Save</supy-button>
  </ng-container>
</supy-drawer>
