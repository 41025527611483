import produce from 'immer';
import { catchError, EMPTY, forkJoin, Observable, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';

import {
  BASE_REQUEST_META_DEFAULT,
  BASE_RESPONSE_META_DEFAULT,
  BaseRequestMetadata,
  BaseResponseMetadata,
  EntityListState,
  EntityListStateModel,
  LocalFile,
  Query,
  QueryBuilder,
} from '@supy/common';
import { SnackbarService } from '@supy/components';
import { CurrentRetailerState } from '@supy/retailers';
import { SettingsState } from '@supy/settings';

import {
  InventoryTransferEvent,
  InventoryTransferFilters,
  InventoryTransferMode,
  InventoryTransferSearchItem,
  InventoryTransfersRequestProps,
  ReplicationResponse,
  UploadInventoryEventAttachmentResponse,
} from '../../../core';
import { downloadTransfersList } from '../../../helpers';
import { InventoryTransferService } from '../../../services';
import {
  InventoryTransferCheckExistence,
  InventoryTransferCreate,
  InventoryTransferDelete,
  InventoryTransferDownload,
  InventoryTransferGet,
  InventoryTransferGetItems,
  InventoryTransferGetMany,
  InventoryTransferGetStats,
  InventoryTransferInitFilters,
  InventoryTransferListExport,
  InventoryTransferPatchFilter,
  InventoryTransferPatchRequestMeta,
  InventoryTransferRepeat,
  InventoryTransferRepeatSuccess,
  InventoryTransferResetFilter,
  InventoryTransferResetSequence,
  InventoryTransferSetMode,
  InventoryTransferUpdate,
  InventoryTransferUploadAttachment,
} from '../actions';

export interface InventoryTransferStateModel extends EntityListStateModel<InventoryTransferEvent> {
  readonly filters: InventoryTransferFilters;
  readonly requestMetadata: BaseRequestMetadata;
  readonly responseMetadata: BaseResponseMetadata;
  readonly items: InventoryTransferSearchItem[];
  readonly mode: InventoryTransferMode;
  readonly eventExist: boolean;
  readonly exportLoading: boolean;
  readonly stats: Map<InventoryTransferMode, number>;
}

export const INVENTORY_TRANSFER_TOKEN = new StateToken<InventoryTransferStateModel>('inventoryTransfer');

const FILTERS_DEFAULT: InventoryTransferFilters = {
  code: null,
  start: null,
  end: null,
  locationIds: [],
  status: null,
};

interface GetTransfersQueryOptions {
  readonly checkExistence?: boolean;
  readonly paginationLimit?: number;
  readonly offset?: number;
}

@State<InventoryTransferStateModel>({
  name: INVENTORY_TRANSFER_TOKEN,
  defaults: {
    ...EntityListState.default(),
    filters: FILTERS_DEFAULT,
    requestMetadata: BASE_REQUEST_META_DEFAULT,
    responseMetadata: BASE_RESPONSE_META_DEFAULT,
    items: [],
    mode: InventoryTransferMode.Outgoing,
    eventExist: true,
    exportLoading: false,
    stats: new Map(),
  },
})
@Injectable()
export class InventoryTransferState extends EntityListState {
  constructor(
    protected readonly router: Router,
    private readonly inventoryTransferService: InventoryTransferService,
    private readonly store: Store,
    private readonly snackBar: SnackbarService,
  ) {
    super(router);
  }

  @Selector()
  static events(state: InventoryTransferStateModel) {
    return EntityListState.all(state);
  }

  @Selector()
  static currentEvent(state: InventoryTransferStateModel) {
    return EntityListState.current(state);
  }

  @Selector()
  static isFirst(state: InventoryTransferStateModel) {
    return EntityListState.isFirst(state);
  }

  @Selector()
  static isLast(state: InventoryTransferStateModel) {
    return EntityListState.isLast(state);
  }

  static event(id: string, next?: boolean) {
    return createSelector([InventoryTransferState], (state: InventoryTransferStateModel) => {
      return EntityListState.one(id, next)(state);
    });
  }

  @Selector()
  static appliedFiltersCount(state: InventoryTransferStateModel) {
    return EntityListState.appliedFiltersCount(state, FILTERS_DEFAULT);
  }

  @Selector()
  static filters(state: InventoryTransferStateModel) {
    return EntityListState.currentFilters<InventoryTransferFilters>(state);
  }

  @Selector()
  static requestMetadata(state: InventoryTransferStateModel) {
    return state.requestMetadata;
  }

  @Selector()
  static responseMetadata(state: InventoryTransferStateModel) {
    return state.responseMetadata;
  }

  @Selector()
  static items(state: InventoryTransferStateModel) {
    return state.items;
  }

  @Selector()
  static mode(state: InventoryTransferStateModel) {
    return state.mode;
  }

  @Selector()
  static eventExist(state: InventoryTransferStateModel) {
    return state.eventExist;
  }

  @Selector()
  static exportLoading(state: InventoryTransferStateModel) {
    return state.exportLoading;
  }

  @Selector()
  static stats(state: InventoryTransferStateModel) {
    return state.stats;
  }

  @Action(InventoryTransferInitFilters)
  inventoryTransferInitFilter(ctx: StateContext<InventoryTransferStateModel>) {
    super.initFilter(ctx, FILTERS_DEFAULT);
  }

  @Action(InventoryTransferRepeat)
  inventoryTransferRepeat(ctx: StateContext<InventoryTransferStateModel>, { payload }: InventoryTransferRepeat) {
    return this.inventoryTransferService.repeat(payload.id, payload.values).pipe(
      switchMap((response: ReplicationResponse) => {
        return ctx.dispatch([
          new InventoryTransferRepeatSuccess({
            id: response.id,
            skippedItems: response.skippedItems,
          }),
          new Navigate([`/inventory/transfers/${InventoryTransferMode.Outgoing}`, response.id]),
          new InventoryTransferPatchRequestMeta({ page: 0 }),
          new InventoryTransferGetMany(),
        ]);
      }),
    );
  }

  @Action(InventoryTransferPatchFilter)
  patchInventoryTransferFilter(ctx: StateContext<InventoryTransferStateModel>, action: InventoryTransferPatchFilter) {
    super.patchFilter(ctx, action.payload, FILTERS_DEFAULT);
  }

  @Action(InventoryTransferResetFilter)
  resetInventoryTransferFilter(ctx: StateContext<InventoryTransferStateModel>) {
    super.resetFilter(ctx, FILTERS_DEFAULT);
  }

  @Action(InventoryTransferGet)
  getOrder(ctx: StateContext<InventoryTransferStateModel>, action: InventoryTransferGet) {
    return super.getOne(ctx, {
      ...action.payload,
      fetchApi: () => this.inventoryTransferService.getByIdBff(action.payload.id),
    });
  }

  @Action(InventoryTransferGetMany, { cancelUncompleted: true })
  getInventoryTransfers(ctx: StateContext<InventoryTransferStateModel>) {
    return this.inventoryTransferService.getManyBff(this.getQuery(ctx.getState())).pipe(
      tap(({ data, metadata }) => {
        ctx.patchState({ responseMetadata: metadata });
        super.setMany(ctx, data);
        ctx.dispatch(new InventoryTransferGetStats(this.store.selectSnapshot(CurrentRetailerState.get) ?? ''));
      }),
    );
  }

  @Action(InventoryTransferPatchRequestMeta)
  patchRequestMetadata(ctx: StateContext<InventoryTransferStateModel>, { payload }: InventoryTransferPatchRequestMeta) {
    ctx.setState(
      produce(draft => {
        draft.requestMetadata = {
          ...ctx.getState().requestMetadata,
          ...payload,
        };
      }),
    );
  }

  @Action(InventoryTransferSetMode)
  setMode(ctx: StateContext<InventoryTransferStateModel>, { value }: InventoryTransferSetMode) {
    ctx.patchState({ mode: value });
  }

  @Action(InventoryTransferGetItems)
  getItems(ctx: StateContext<InventoryTransferStateModel>, { query }: InventoryTransferGetItems) {
    return this.inventoryTransferService.getItemsBff(query).pipe(
      tap(({ data }) => {
        ctx.patchState({ items: InventoryTransferSearchItem.deserializeList(data) });
      }),
    );
  }

  @Action(InventoryTransferResetSequence)
  resetSequenceHandler(ctx: StateContext<InventoryTransferStateModel>) {
    super.resetSequence(ctx);
  }

  @Action(InventoryTransferCreate)
  create(ctx: StateContext<InventoryTransferStateModel>, { payload }: InventoryTransferCreate) {
    const { localFiles, ...rest } = payload;

    return this.getUploadAttachmentsStream(ctx, localFiles).pipe(
      switchMap(uploadedAttachments =>
        this.inventoryTransferService.createBff({ ...rest, attachments: uploadedAttachments }),
      ),
      tap(() => {
        this.snackBar.success(
          $localize`:@@inventory.transfers.create.createSuccess:Transfer event created successfully`,
        );
      }),
      tap(() => ctx.patchState({ eventExist: true })),
    );
  }

  @Action(InventoryTransferUpdate)
  update(ctx: StateContext<InventoryTransferStateModel>, { payload }: InventoryTransferUpdate) {
    const { localFiles, ...rest } = payload;

    return this.getUploadAttachmentsStream(ctx, localFiles).pipe(
      switchMap(uploadedAttachments =>
        this.inventoryTransferService.updateBff({
          ...rest,
          attachments: [...(rest.attachments ?? []), ...uploadedAttachments],
        }),
      ),
      tap(() => {
        this.snackBar.success($localize`:@@inventory.transfers.save.saveSuccess:Transfer event saved successfully`);
      }),
    );
  }

  @Action(InventoryTransferDelete)
  delete(ctx: StateContext<InventoryTransferStateModel>, { id }: InventoryTransferDelete) {
    return this.inventoryTransferService.deleteBff(id).pipe(
      tap(() => {
        ctx.setState(
          produce(draft => {
            draft.entities = draft.entities.filter(event => event.id !== id);
          }),
        );
      }),
    );
  }

  @Action(InventoryTransferCheckExistence)
  checkExistence(ctx: StateContext<InventoryTransferStateModel>) {
    return this.inventoryTransferService.getManyBff(this.getQuery(ctx.getState(), { checkExistence: true })).pipe(
      tap(({ data }) => {
        ctx.patchState({ eventExist: data.length > 0 });
      }),
    );
  }

  @Action(InventoryTransferGetStats)
  getStats(ctx: StateContext<InventoryTransferStateModel>, { retailerId }: InventoryTransferGetStats) {
    return this.inventoryTransferService
      .getStats(retailerId)
      .pipe(tap(stats => ctx.patchState({ stats: new Map(stats.map(stat => [stat.state, stat.count])) })));
  }

  @Action(InventoryTransferUploadAttachment)
  uploadAttachment(
    ctx: StateContext<InventoryTransferStateModel>,
    { payload, queryParams }: InventoryTransferUploadAttachment,
  ) {
    return this.inventoryTransferService.uploadAttachment(payload, queryParams);
  }

  @Action(InventoryTransferDownload)
  downloadDetails(ctx: StateContext<InventoryTransferStateModel>, { transferId }: InventoryTransferDownload) {
    return this.inventoryTransferService.getTransferPdf(transferId).pipe(
      tap(({ signedUrl }) => {
        window.open(signedUrl);
      }),
    );
  }

  @Action(InventoryTransferListExport, { cancelUncompleted: true })
  downloadItems(ctx: StateContext<InventoryTransferStateModel>) {
    ctx.patchState({
      exportLoading: true,
    });

    const state = ctx.getState();
    const query = this.getQuery(state, {
      paginationLimit: -1,
      offset: 0,
    });

    return this.inventoryTransferService.getManyBff(query).pipe(
      withLatestFrom(this.store.select(SettingsState.currency), this.store.select(SettingsState.ianaTimeZone)),
      tap(([{ data }, currency, ianaTimeZone]) => {
        ctx.patchState({
          exportLoading: false,
        });

        void downloadTransfersList(data, currency, ianaTimeZone);
      }),
      catchError(() => {
        ctx.patchState({
          exportLoading: false,
        });

        return EMPTY;
      }),
    );
  }

  private getQuery(state: InventoryTransferStateModel, options?: GetTransfersQueryOptions) {
    const { code, start, end, locationIds, status } = state.filters;
    const retailerId = this.store.selectSnapshot(CurrentRetailerState.get) ?? '';
    const userLocationIds = this.store.selectSnapshot(CurrentRetailerState.branches)?.map(({ id }) => id) ?? [];
    const filteredLocationIds = locationIds?.length ? locationIds : userLocationIds;

    if (options?.checkExistence) {
      return new Query<InventoryTransfersRequestProps>({
        filtering: [{ by: 'retailer.id', match: retailerId, op: 'eq' }],
        paging: { offset: 0, limit: 1 },
      });
    }

    const qb = new QueryBuilder<InventoryTransfersRequestProps>({
      filtering: [
        { by: 'retailer.id', match: retailerId, op: 'in' },
        {
          by: 'state',
          op: 'in',
          match: InventoryTransferEvent.getStatusesFilter(state.mode).map(({ value }) => value),
        },
      ],
      paging: {
        limit: options?.paginationLimit ? options.paginationLimit : state.requestMetadata.limit,
        offset: options?.offset ? options.offset : state.requestMetadata.page * state.requestMetadata.limit,
      },
      ordering: [
        { by: 'eventDate', dir: 'desc' },
        { by: 'id', dir: 'desc' },
      ],
    });

    if (code) {
      qb.filtering.setFilter({ by: 'code', op: 'like', match: code });
    }

    if (start && end) {
      qb.filtering.withFiltering([
        { by: 'eventDate', op: 'gte', match: new Date(start) },
        { by: 'eventDate', op: 'lte', match: new Date(end) },
      ]);
    }

    if (status) {
      qb.filtering.setFilter({ by: 'state', op: 'eq', match: status });
    }

    switch (state.mode) {
      case InventoryTransferMode.Requested:
        qb.filtering.withGroup('or', [
          { by: 'fromLocation.id', op: 'in', match: filteredLocationIds },
          { by: 'toLocation.id', op: 'in', match: filteredLocationIds },
        ]);
        break;

      case InventoryTransferMode.Incoming:
        qb.filtering.setFilter({ by: 'toLocation.id', op: 'in', match: filteredLocationIds });
        break;

      case InventoryTransferMode.Outgoing:
        qb.filtering.setFilter({ by: 'fromLocation.id', op: 'in', match: filteredLocationIds });
        break;
    }

    return qb.build();
  }

  private getUploadAttachmentsStream(
    ctx: StateContext<InventoryTransferStateModel>,
    localFiles?: LocalFile[],
  ): Observable<UploadInventoryEventAttachmentResponse[]> {
    return localFiles?.length
      ? forkJoin(
          localFiles.map(localFile => {
            const formData = new FormData();

            formData.append('attachment', localFile.file);

            return this.uploadAttachment(ctx, {
              payload: formData,
              queryParams: { retailerId: this.store.selectSnapshot(CurrentRetailerState.get) ?? '' },
            });
          }),
        )
      : of(new Array<UploadInventoryEventAttachmentResponse>());
  }
}
