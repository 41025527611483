import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { GridPagingMode } from '@infragistics/igniteui-angular';
import { Currency, IANATimezone } from '@supy.api/dictionaries';

import { PriceHistoryGridItem, PriceHistoryRecord } from '../../core';
import { PriceHistoryRequestMetadata, PriceHistoryResponseMetadata } from '../../store';

@Component({
  selector: 'supy-price-history-grid',
  templateUrl: './price-history-grid.component.html',
  styleUrls: ['./price-history-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceHistoryGridComponent {
  @Input({ required: true }) set priceHistory(value: PriceHistoryRecord[]) {
    this.priceHistoryGridItems.set(PriceHistoryGridItem.fromList(value));
  }

  @Input({ required: true }) readonly currency: Currency;
  @Input({ required: true }) readonly currencyPrecision: number;
  @Input({ required: true }) readonly requestMetadata: PriceHistoryRequestMetadata;
  @Input({ required: true }) readonly responseMetadata: PriceHistoryResponseMetadata;
  @Input({ required: true }) readonly ianaTimeZone: IANATimezone;

  @Output() readonly pageChange = new EventEmitter<number>();

  protected readonly paginationMode = GridPagingMode.Remote;
  protected readonly priceHistoryGridItems = signal<PriceHistoryGridItem[]>([]);
}
