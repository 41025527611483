import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { InventoryEventType } from '@supy/common';

import { InventoryEventStateEnum, InventoryTransferStateEnum } from '../../core';

@Component({
  selector: 'supy-inventory-event-details-footer',
  templateUrl: './inventory-event-details-footer.component.html',
  styleUrls: ['./inventory-event-details-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryEventDetailsFooterComponent {
  @Input() readonly eventType: InventoryEventType;
  @Input() readonly eventState: InventoryEventStateEnum | InventoryTransferStateEnum;
  @Input() readonly isDisabled: boolean;
  @Input() readonly isSubmitHidden: boolean;
  @Input() readonly canReceive: boolean;
  @Input() readonly canSave: boolean;
  @Input() readonly isStockCountedAfterEventDate: boolean;

  @Output() readonly saveAsDraftClicked = new EventEmitter<void>();
  @Output() readonly submitClicked = new EventEmitter<void>();
  @Output() readonly saveClicked = new EventEmitter<void>();
  @Output() readonly receiveClicked = new EventEmitter<void>();
  @Output() readonly discardClicked = new EventEmitter<void>();
  @Output() readonly repeatClicked = new EventEmitter<void>();

  protected readonly inventoryEventStateEnum = InventoryEventStateEnum;
  protected readonly inventoryTransferStateEnum = InventoryTransferStateEnum;
  protected readonly inventoryEventType = InventoryEventType;
}
