import { capital } from 'case';
import { Workbook } from 'exceljs';
import { IANATimezone } from '@supy.api/dictionaries';

import {
  createWorksheet,
  DataColumn,
  DEFAULT_IANA_TIMEZONE,
  EventTime,
  getUTCOverriddenDateTime,
  saveWorkbook,
  SimpleUser,
} from '@supy/common';
import { DropdownTreeNode } from '@supy/components';

import { InventoryStockCount } from '../core';

export async function downloadStockCountsList(
  items: InventoryStockCount[],
  branches: DropdownTreeNode<string>[],
  ianaTimeZone: IANATimezone = DEFAULT_IANA_TIMEZONE,
): Promise<void> {
  const columns: DataColumn[] = [
    { header: 'Branch & Location', key: 'location' },
    { header: 'Date', key: 'date' },
    { header: `Time`, key: 'time' },
    { header: '# of SubCounts', key: 'numSubCounts' },
    { header: 'Created By', key: 'createdBy' },
    { header: 'Status', key: 'state' },
  ];

  const currencyCols = new Set(['cost', 'stockValue']);
  const qtyCols = new Set(['onHand']);
  const dateCols = new Set(['date']);

  const workbook = new Workbook();

  const data = items.map(item => {
    return {
      ...item,
      location: getName(item?.location?.id, branches),
      date: getUTCOverriddenDateTime(new Date(item.eventDate.creationDate), ianaTimeZone),
      time: item?.eventDate.time === EventTime.StartOfDay ? 'Start of day' : 'End of day',
      numSubCounts: item?.subStockCounts?.length ?? 0,
      createdBy: item?.createdBy ? getUserFullName(item.createdBy) : '',
      state: capital(item.state),
    };
  });

  await createWorksheet(workbook, `Stock Counts`, { data, columns, dateCols, currencyCols, qtyCols });

  const fileName = `stock-counts-${new Date().toISOString()}.xlsx`;

  saveWorkbook(workbook, fileName);
}

function getName(locationId: string, branches: DropdownTreeNode<string>[]): string {
  return branches.reduce(
    (name: string, storage) => {
      if (name) {
        return name;
      }

      const location = storage.children?.find(child => child.id === locationId);

      if (location) {
        return `${storage.name} - ${location.name}`;
      }

      return name;
    },
    null as unknown as string,
  );
}

function getUserFullName(user: SimpleUser): string {
  return `${user.firstName} ${user.lastName}`;
}
