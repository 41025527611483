import { takeUntil } from 'rxjs';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { Currency } from '@supy.api/dictionaries';

import { Destroyable, IdType } from '@supy/common';
import { TaxRate } from '@supy/settings';

import { computedSellingPriceExclVat, computedVatFromSellingPrice } from '../../core';

export interface RecipeCostingForm {
  readonly sellingPrice: number;
  readonly costThreshold: number;
  readonly taxes: IdType[];
}

@Component({
  selector: 'supy-recipe-costing',
  templateUrl: './recipe-costing.component.html',
  styleUrls: ['./recipe-costing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecipeCostingComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RecipeCostingComponent),
      multi: true,
    },
  ],
})
export class RecipeCostingComponent
  extends Destroyable
  implements OnInit, OnChanges, AfterViewInit, ControlValueAccessor, Validator
{
  @Input() set recipeCostingTaxes(value: TaxRate[]) {
    if (Array.isArray(value)) {
      this.#recipeCostingTaxes = value;
      this.setActiveRecipeCostingTaxRates(
        this.#recipeExistingTaxes?.length ? this.#recipeExistingTaxes : value.map(({ id }) => ({ id })),
      );
      this.taxesTotalChange.emit(this.recipeCostingTotalTaxes);
    }
  }

  get recipeCostingTaxes(): TaxRate[] {
    return this.#recipeCostingTaxes;
  }

  @Input() readonly currency: Currency;
  @Input() readonly currencyPrecision: number;
  @Input() readonly isReadonly: boolean;
  @Input() readonly taxesDisabled: boolean;
  @Input() readonly costTresholdDisplayed: boolean = true;
  @Input() readonly isValidate: boolean;
  @Input() readonly formSubmitted: boolean;

  @Output() readonly taxesTotalChange = new EventEmitter<number>();

  protected readonly form = new FormGroup({
    sellingPrice: new FormControl<number | null>(null),
    costThreshold: new FormControl<number | null>(null),
    taxes: new FormControl<IdType[]>([]),
  });

  protected readonly computedVatFromSellingPrice = computedVatFromSellingPrice;
  protected readonly computedSellingPriceExclVat = computedSellingPriceExclVat;

  get sellingPrice(): number {
    return this.form.getRawValue().sellingPrice;
  }

  get recipeCostingTotalTaxes(): number {
    return (this.recipeCostingTaxes ?? []).reduce(
      (acc, cur) => acc + (this.isRecipeCostingTaxActive(cur) ? cur.rate : 0),
      0,
    );
  }

  get sellingPriceExclVat(): number {
    return this.sellingPrice ? computedSellingPriceExclVat(this.recipeCostingTotalTaxes, this.sellingPrice) : 0;
  }

  #recipeCostingTaxes: TaxRate[] = [];
  #recipeExistingTaxes: IdType[];

  onTouched: () => void;
  onChange: (value: RecipeCostingForm) => void;

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value: RecipeCostingForm) => {
      this.onChange?.(value);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isReadonly) {
      if (changes.isReadonly.currentValue) {
        this.form.disable();
      } else {
        this.form.enable();
      }
    }

    if (changes.isValidate) {
      this.form.get('sellingPrice')?.addValidators([Validators.required]);
      this.form.updateValueAndValidity();
    }
  }

  ngAfterViewInit(): void {
    this.onChange(this.form.getRawValue());
  }

  setActiveRecipeCostingTaxRates(recipeTaxes: IdType[]): void {
    this.form.patchValue({ taxes: recipeTaxes?.map(({ id }) => ({ id })) });
  }

  isRecipeCostingTaxActive(recipeCostingTax: TaxRate): boolean {
    return this.form.get('taxes').value?.some(({ id }) => id === recipeCostingTax.id);
  }

  onRecipeCostingTaxActiveChange(event: boolean, recipeCostingTax: TaxRate): void {
    if (event && this.isRecipeCostingTaxActive(recipeCostingTax)) {
      return;
    } else if (event) {
      this.form.get('taxes').setValue([...this.form.get('taxes').value, { id: recipeCostingTax.id }]);
    } else {
      this.form.get('taxes').setValue(this.form.get('taxes').value.filter(({ id }) => id !== recipeCostingTax.id));
    }

    this.taxesTotalChange.emit(this.recipeCostingTotalTaxes);
  }

  writeValue(value: RecipeCostingForm): void {
    if (value) {
      this.form.patchValue(value);
      this.#recipeExistingTaxes = value.taxes;
      this.setActiveRecipeCostingTaxRates(value.taxes);
      this.taxesTotalChange.emit(this.recipeCostingTotalTaxes);
    }
  }

  validate(): ValidationErrors | null {
    if (this.form.valid) {
      return null;
    }

    return { valid: false };
  }

  registerOnChange(onChange: (value: RecipeCostingForm) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }
}
