<div class="supy-recipe__parent-info">
  <span class="supy-recipe__parent-info-tooltip">
    <h4 class="supy-recipe__parent-info-title" i18n="@@parentRecipes">Parent Recipes</h4>
    <supy-tooltip [content]="parentRecipeTooltipMessage">
      <supy-icon name="info" size="medium" />
    </supy-tooltip>
  </span>

  <div class="supy-recipe__parent-info-header">
    <h4 class="supy-recipe__parent-info-subtitle" i18n="@@name">Name</h4>
    <h4 class="supy-recipe__parent-info-subtitle" i18n="@@level">Level</h4>
    <h4 class="supy-recipe__parent-info-subtitle" i18n="@@type">Type</h4>
  </div>

  <div class="supy-recipe__creation-details-container">
    @for (parentRecipe of parentRecipes(); track $index) {
      <div class="supy-recipe__creation-details-row">
        <div class="supy-recipe__creation-details-content-url" (click)="onRecipeClick(parentRecipe.id)">
          {{ getLocalizedName(parentRecipe.name) | titlecase }}
        </div>
        <div class="supy-recipe__creation-details-content">
          {{ parentRecipe.level }}
        </div>
        <div class="supy-recipe__creation-details-content">
          {{ parentRecipe.type | titlecase }}
        </div>
      </div>
    }
  </div>
</div>
