import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Currency } from '@supy.api/dictionaries';

import { GridComponent } from '@supy/components';
import { TaxRate } from '@supy/settings';

import { computedFoodCost, CostCenterGridItem, CostCenterPayload, RecipeStatement, SalesTypeCost } from '../../core';

@Component({
  selector: 'supy-cost-centers-grid',
  templateUrl: 'cost-centers-grid.component.html',
  styleUrls: ['./cost-centers-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CostCentersGridComponent implements OnChanges {
  @Input() currency: Currency;
  @Input() readonly currencyPrecision: number;
  @Input() set data(value: CostCenterGridItem[]) {
    this.gridData = value;
  }

  @Input() readonly isReadonly: boolean;

  @Input() taxes: TaxRate[];

  @Input() set defaultSellingPrice(value: number) {
    if (typeof value === 'number') {
      this.gridData.forEach(costCenter => this.setSellingPrice(value, costCenter));
      this.onCellEditDone();
    }
  }

  @Input() set defaultTaxes(value: string[]) {
    if (Array.isArray(value)) {
      this.gridData.forEach(costCenter => this.setTaxes(value, costCenter));
      this.onCellEditDone();
    }
  }

  @Input() set statements(value: RecipeStatement[]) {
    if (value?.length) {
      this.setDefaultCostMany(value);
    }
  }

  @Output() readonly costCenterChange = new EventEmitter<CostCenterPayload[]>();
  @Output() readonly locationChange = new EventEmitter<string>();
  @ViewChild(GridComponent, { static: true }) private readonly supyGrid: GridComponent;

  protected gridData: CostCenterGridItem[];

  ngOnChanges(changes: SimpleChanges) {
    this.supyGrid.markForCheck();
  }

  setSellingPrice(sellingPriceValue: number, item: CostCenterGridItem): void {
    item.sellingPrice = sellingPriceValue;

    this.setComputedTargetCost(item);
  }

  setTaxes(taxes: string[], item: CostCenterGridItem): void {
    item.taxes = taxes;
    this.setComputedTargetCost(item);
  }

  onCellEditDone(): void {
    this.costCenterChange.emit(
      this.gridData
        .filter(costCenter => costCenter.location)
        .map(costCenter => ({
          location: { id: costCenter.location?.id },
          branch: { id: costCenter.branch.id },
          targetCost: costCenter.targetCost,
          costThreshold: costCenter.costThreshold,
          taxes: costCenter.taxes?.map(id => ({ id })),
          sellingPrice: costCenter.sellingPrice,
        })),
    );
  }

  onLocationSelected(item: CostCenterGridItem, location: { id: string; name: string }, index: number): void {
    item.location = location;

    if (location?.id) {
      this.locationChange.emit(location.id);
    } else {
      this.setDefaultCost(null, null, index);
      this.onCellEditDone();

      item.costThreshold = undefined;
    }
  }

  private setDefaultCost(locationId: string | null, cost: number | null, index?: number): void {
    this.gridData.forEach((costCenter, currIndex) => {
      if (locationId === costCenter.location?.id || index === currIndex) {
        costCenter.cost = cost;

        this.setComputedTargetCost(costCenter);
      }
    });
  }

  private setDefaultCostMany(statements: RecipeStatement[]): void {
    statements.forEach(({ locationId, cost }) => {
      this.setDefaultCost(locationId, cost);
    });

    this.onCellEditDone();
  }

  private getTaxesAmount(taxes: string[]): number {
    return this.taxes.filter(tax => taxes?.includes(tax.id))?.reduce((sum, tax) => sum + tax.rate, 0);
  }

  private setComputedTargetCost(item: CostCenterGridItem): void {
    if (!item.location && !item.sellingPrice && !item.cost) {
      return;
    }

    item.targetCost = computedFoodCost(item.cost, this.getTaxesAmount(item.taxes), item.sellingPrice);
  }

  getSalesTypesCostsTooltip(rowData: CostCenterGridItem): string {
    if (rowData.salesTypesCosts?.length) {
      return rowData.salesTypesCosts.map((cost: SalesTypeCost) => `${cost.salesTypeName}: ${cost.cost}`).join('\n');
    }

    return $localize`:@@costCenter.noSalesTypeCost:No sales type costs available`;
  }
}
