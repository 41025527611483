<div class="supy-cost-adjustments-drawer__content" content>
  @if (isLoading()) {
    <supy-loading-overlay />
  }

  <supy-cost-adjustments-grid-filters
    class="supy-cost-adjustments-drawer__filters"
    [retailerId]="retailerId()"
    [locationsWithRegionsBranches]="locationsWithRegionsBranches()"
    [recipePlace]="recipePlace()"
    [locations]="recipe()?.locations"
    [defaultLocationId]="locationIds()?.at(0)"
    [recipeType]="recipe()?.type"
    [activeSalesTypes]="activeSalesTypes()"
    (filtersChanged)="onFiltersChange($event)"
    (filtersCleared)="onFiltersClear()"
  ></supy-cost-adjustments-grid-filters>

  @if (data()) {
    <supy-cost-adjustments-grid
      [currencyPrecision]="currencyPrecision()"
      [currencySymbol]="currency()"
      [data]="data()"
      [expandedRowId]="expandedRowId()"
      [loadingRowId]="loadingRowId()"
      (toggleRow)="onToggleRow($event)"
      (toggleDetails)="onToggleDetails($event)"
    />
  }
</div>
