<div class="inventory-event-empty-details">
  <supy-icon name="empty" [customStyle]="{ height: '10rem', width: '10rem' }"></supy-icon>
  <p i18n="@@inventory.event.empty.details">
    Start adding your {{ eventType }} reports to perform inventory <br />
    transactions and use the items as your menu ingredients to <br />
    track your business cost.
  </p>
  <supy-button type="button" color="primary" name="create-event" routerLink="create">
    <supy-icon name="plus-flat" size="small"></supy-icon>
    <span i18n="@@common.actions.createEntity">Create {{ eventType | titlecase }}</span>
  </supy-button>
</div>
