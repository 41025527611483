import { InventoryIngredientNormalized } from './inventory-recipe.model';

export const quantityGross = (ingredient: InventoryIngredientNormalized) =>
  canBeCalculated(ingredient)
    ? (ingredient.quantityNet ?? 0) / (1 - ((ingredient.prepWastage || ingredient.item?.wastagePercentage) ?? 0) / 100)
    : ingredient?.quantityNet ?? 0;

const canBeCalculated = (ingredient: InventoryIngredientNormalized) =>
  ingredient && ingredient.quantityNet && (ingredient.prepWastage ?? 0);

export const averageIngredientCost = (ingredient: InventoryIngredientNormalized) => {
  if (!ingredient || !ingredient.item?.cost || !getIngredientPackageUnitAtom(ingredient)) {
    return 0;
  }

  const usedAsPiecePackaging = ingredient.item?.packagings?.find(({ isPiece }) => isPiece);
  const usedAsPieceToAtomUom =
    ingredient?.packagingUnit?.isPiece && usedAsPiecePackaging ? usedAsPiecePackaging?.toAtomUom ?? 1 : null;
  const conversionToAtom = usedAsPieceToAtomUom
    ? usedAsPieceToAtomUom
    : ingredient.packagingUnit?.toAtomUom ?? getIngredientPackageUnitAtom(ingredient);

  return quantityGross(ingredient) * ingredient.item?.cost * conversionToAtom;
};

export const lastPurchaseIngredientCost = (ingredient: InventoryIngredientNormalized) =>
  ingredient && ingredient.item?.lastPurchaseCost && getIngredientPackageUnitAtom(ingredient)
    ? quantityGross(ingredient) *
      ingredient.item?.lastPurchaseCost *
      (ingredient.packagingUnit?.toAtomUom ?? getIngredientPackageUnitAtom(ingredient))
    : 0;

export const computedVatFromSellingPrice = (recipeCostingVat: number, sellingPrice: number) =>
  recipeCostingVat ? sellingPrice - sellingPrice / (1 + recipeCostingVat / 100) : 0;

export const computedSellingPriceExclVat = (recipeCostingVat: number, sellingPrice: number) =>
  sellingPrice - computedVatFromSellingPrice(recipeCostingVat, sellingPrice);

export const computedFoodCost = (foodCost: number, recipeCostingVat: number, sellingPrice: number) =>
  computedSellingPriceExclVat(recipeCostingVat, sellingPrice)
    ? Math.round((foodCost * 100) / computedSellingPriceExclVat(recipeCostingVat, sellingPrice))
    : 100;

export const computedProfit = (foodCost: number, recipeCostingVat: number, sellingPrice: number) =>
  100 - computedFoodCost(foodCost, recipeCostingVat, sellingPrice);

const getIngredientPackageUnitAtom = (ingredient: InventoryIngredientNormalized) =>
  ingredient.item?.packagingUnit?.toAtomUom ?? 0;
