import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ResourceEnum, TransferActionEnum } from '@supy.api/permissions';

import { IsAllowedRequest, PermissionHelper, PermissionStrategy, Principal, Resource } from '@supy/authz';

@Injectable({ providedIn: 'root' })
class Create extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Transfer,
      action: TransferActionEnum.Create,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class View extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Transfer,
      action: TransferActionEnum.View,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class Update extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Transfer,
      action: TransferActionEnum.Update,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class Receive extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Transfer,
      action: TransferActionEnum.Receive,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class Request extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Transfer,
      action: TransferActionEnum.Request,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class Submit extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Transfer,
      action: TransferActionEnum.Submit,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class Archive extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Transfer,
      action: TransferActionEnum.Archive,
      resource,
      principal,
    });
  }
}

export class TransferPermissionStrategy {
  static Create = Create;
  static View = View;
  static Update = Update;
  static Receive = Receive;
  static Request = Request;
  static Submit = Submit;
  static Archive = Archive;
}
