import { map, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { IQueryResponse, Query } from '@supy/common';

import { INVENTORY_STOCK_COUNT_BFF_URI } from '../../../config';
import { StockStatisticsResponse } from '../../stocks';
import {
  CreateInventoryStockCountInput,
  CreateInventorySubStockCountInput,
  DateGroupedStockCounts,
  GetCommonStockCountDatesOutput,
  GetCommonStockCountDatesRequest,
  GetFirstEventDateResponse,
  GetLastOpeningCountDateResponse,
  GetStockCountItemsResponse,
  GetSubStockCountItemsResponse,
  InventoryStockCount,
  InventoryStockCountItem,
  InventoryStockCountRequestProps,
  UpdateInventorySubStockCountInput,
} from '../core';

@Injectable({ providedIn: 'root' })
export class InventoryStockCountService {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(INVENTORY_STOCK_COUNT_BFF_URI) private readonly bffUri: string,
  ) {}

  getByIdItems(
    id: string,
    query: Query<InventoryStockCountItem & InventoryStockCountRequestProps>,
  ): Observable<GetStockCountItemsResponse> {
    return this.httpClient.get<GetStockCountItemsResponse>(`${this.bffUri}/${id}/items`, {
      params: new HttpParams({
        fromObject: query.toQueryParams(),
      }),
    });
  }

  getByIdRecipes(
    id: string,
    query: Query<InventoryStockCountItem & InventoryStockCountRequestProps>,
  ): Observable<GetStockCountItemsResponse> {
    return this.httpClient.get<GetStockCountItemsResponse>(`${this.bffUri}/${id}/recipes`, {
      params: new HttpParams({
        fromObject: query.toQueryParams(),
      }),
    });
  }

  getSubByIdItems(
    id: string,
    subCountId: string,
    query: Query<InventoryStockCountItem & InventoryStockCountRequestProps>,
  ): Observable<GetSubStockCountItemsResponse> {
    return this.httpClient.get<GetSubStockCountItemsResponse>(`${this.bffUri}/${id}/sub-counts/${subCountId}/items`, {
      params: new HttpParams({
        fromObject: query.toQueryParams(),
      }),
    });
  }

  getSubByIdRecipes(
    id: string,
    subCountId: string,
    query: Query<InventoryStockCountItem & InventoryStockCountRequestProps>,
  ): Observable<GetSubStockCountItemsResponse> {
    return this.httpClient.get<GetSubStockCountItemsResponse>(`${this.bffUri}/${id}/sub-counts/${subCountId}/recipes`, {
      params: new HttpParams({
        fromObject: query.toQueryParams(),
      }),
    });
  }

  getStatisticsBff(locationId: string): Observable<StockStatisticsResponse> {
    return this.httpClient.get<StockStatisticsResponse>(`${this.bffUri}/${locationId}/stats`);
  }

  create(body: CreateInventoryStockCountInput): Observable<InventoryStockCount> {
    return this.httpClient.post<InventoryStockCount>(this.bffUri, body);
  }

  changeEventDate(id: string, eventDate: Date): Observable<void> {
    return this.httpClient.patch<void>(`${this.bffUri}/${id}/date`, { eventDate: eventDate });
  }

  createSub(stockCountId: string, body: CreateInventorySubStockCountInput): Observable<InventoryStockCount> {
    return this.httpClient.patch<InventoryStockCount>(`${this.bffUri}/${stockCountId}/sub-counts`, body);
  }

  getManyBff(
    query: Query<InventoryStockCount & InventoryStockCountRequestProps>,
  ): Observable<IQueryResponse<InventoryStockCount>> {
    return this.httpClient.get<IQueryResponse<InventoryStockCount>>(this.bffUri, {
      params: new HttpParams({
        fromObject: query.toQueryParams(),
      }),
    });
  }

  getCommonDatesBff(body: GetCommonStockCountDatesRequest): Observable<GetCommonStockCountDatesOutput> {
    return this.httpClient.post<GetCommonStockCountDatesOutput>(`${this.bffUri}/common-dates`, body).pipe(
      map(({ allCountDates, someCountDates }) => ({
        allCountDates: allCountDates?.sort(sortByEventDate),
        someCountDates: someCountDates?.sort(sortByEventDate),
      })),
    );
  }

  updateSub(id: string, subStockCountId: string, payload: UpdateInventorySubStockCountInput) {
    return this.httpClient.patch<void>(`${this.bffUri}/${id}/sub-counts/${subStockCountId}/items`, payload);
  }

  reopen(id: string) {
    return this.httpClient.patch<void>(`${this.bffUri}/${id}/reopen`, null);
  }

  discard(stockCountId: string, subStockCountId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.bffUri}/${stockCountId}/sub-counts/${subStockCountId}/discard`);
  }

  toggleZeroingUncounted(stockCountId: string): Observable<void> {
    return this.httpClient.patch<void>(`${this.bffUri}/${stockCountId}/toggle-zeroing-uncounted`, null);
  }

  getFirstEvent(locationId: string): Observable<GetFirstEventDateResponse> {
    return this.httpClient.get<GetFirstEventDateResponse>(`${this.bffUri}/locations/first-event`, {
      params: { locationId },
    });
  }

  getLastOpeningCount(locationId: string): Observable<GetLastOpeningCountDateResponse> {
    return this.httpClient.get<GetLastOpeningCountDateResponse>(`${this.bffUri}/locations/last-opening-count`, {
      params: { locationId },
    });
  }
}

function sortByEventDate(a: DateGroupedStockCounts, b: DateGroupedStockCounts): number {
  return new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime();
}
