import { Observable, pluck } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { BaseHttpService, Query, QueryPaging, Uom, UomType } from '@supy/common';
import { DropdownTreeNode } from '@supy/components';

import { UOM_URI } from '../config';

@Injectable({
  providedIn: 'root',
})
export class UomService extends BaseHttpService {
  constructor(
    @Inject(UOM_URI) protected readonly uri: string,
    protected readonly httpClient: HttpClient,
  ) {
    super(uri);
  }

  getBaseUoms(): Observable<Uom[]> {
    const query = new Query<Uom>({
      paging: QueryPaging.NoLimit,
      filtering: [{ by: 'type', match: UomType.Base, op: 'eq' }],
    });

    return this.get('', query.toQueryParams()).pipe(pluck('data')) as Observable<Uom[]>;
  }

  getUoms(): Observable<Uom[]> {
    return this.get().pipe(pluck('data')) as Observable<Uom[]>;
  }

  getBaseUomsAsTree(): Observable<DropdownTreeNode<string>[]> {
    const query = new Query<Uom>({
      paging: QueryPaging.NoLimit,
      filtering: [{ by: 'type', match: UomType.Base, op: 'eq' }],
    });

    return this.get('tree', query.toQueryParams()).pipe(pluck('data')) as Observable<DropdownTreeNode<string>[]>;
  }

  getUomsAsTree(): Observable<DropdownTreeNode<string>[]> {
    return this.get('tree').pipe(pluck('data')) as Observable<DropdownTreeNode<string>[]>;
  }
}
