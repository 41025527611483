import { CommonModule, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AuthzModule } from '@supy/authz';
import { SupyCommonModule } from '@supy/common';
import {
  ButtonModule,
  ComboboxModule,
  DateInputModule,
  DateRangeModule,
  DialogModule,
  DropdownModule,
  DropdownTreeModule,
  FileUploadModule,
  FiltersModule,
  GridModule,
  IconModule,
  InputModule,
  LinkModule,
  PaginationModule,
  SelectModule,
  StatusBadgeModule,
  SwitchModule,
  TextareaModule,
  TooltipModule,
} from '@supy/components';

import {
  InventoryEventDetailsComponent,
  InventoryEventDetailsFooterComponent,
  InventoryEventDetailsHeaderComponent,
  InventoryEventEmptyDetailsComponent,
  InventoryEventGridComponent,
  InventoryEventHeaderComponent,
  InventoryEventStatusComponent,
} from './components';
import { InventoryWastageService } from './services';

const COMPONENTS = [
  InventoryEventHeaderComponent,
  InventoryEventGridComponent,
  InventoryEventDetailsComponent,
  InventoryEventDetailsFooterComponent,
  InventoryEventDetailsHeaderComponent,
  InventoryEventEmptyDetailsComponent,
  InventoryEventStatusComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DateRangeModule,
    SwitchModule,
    InputModule,
    GridModule,
    DropdownModule,
    ButtonModule,
    IconModule,
    DropdownTreeModule,
    TextareaModule,
    StatusBadgeModule,
    DialogModule,
    LinkModule,
    FileUploadModule,
    SelectModule,
    ComboboxModule,
    DateInputModule,
    SupyCommonModule,
    PaginationModule,
    RouterModule,
    TooltipModule,
    AuthzModule,
    FiltersModule,
  ],
  declarations: [...COMPONENTS],
  providers: [InventoryWastageService, TitleCasePipe],
  exports: [...COMPONENTS],
})
export class InventoryEventModule {}
