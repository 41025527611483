import { takeUntil } from 'rxjs';
import { ChangeDetectionStrategy, Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { Currency } from '@supy.api/dictionaries';

import { Destroyable } from '@supy/common';
import { ToggleButton } from '@supy/components';

import { computedFoodCost as calculatedComputedFoodCost, InventoryRecipeType } from '../../core';

export enum PriceSimulateOn {
  SellingPrice = 'Selling Price',
  FoodCost = 'Food Cost',
}

export interface RecipePriceSimulatorForm {
  readonly targetCost: number;
  readonly basedOn?: PriceSimulateOn;
}

@Component({
  selector: 'supy-recipe-price-simulator',
  templateUrl: './recipe-price-simulator.component.html',
  styleUrls: ['./recipe-price-simulator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecipePriceSimulatorComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RecipePriceSimulatorComponent),
      multi: true,
    },
  ],
})
export class RecipePriceSimulatorComponent
  extends Destroyable
  implements OnInit, OnChanges, ControlValueAccessor, Validator
{
  @Input() set averageFoodCost(value: number) {
    if (value) {
      this.averageFoodCostValue = value;
      this.init();
    }
  }

  @Input() set lastPurchaseFoodCost(value: number) {
    if (value) {
      this.lastPurchaseFoodCostValue = value;
      this.init();
    }
  }

  @Input() set sellingPrice(value: number) {
    this.sellingPriceValue = value;
    this.init();
  }

  @Input() set recipeCostingTotalTaxes(value: number) {
    this.recipeCostingTotalTaxesValue = value ?? 0;
    this.init();
  }

  @Input() readonly currency: Currency;
  @Input() readonly currencyPrecision: number;
  @Input() readonly isReadonly: boolean;
  @Input() readonly isTargetCostSetManually: boolean;
  @Input() readonly isValidate: boolean;
  @Input() readonly formSubmitted: boolean;

  protected readonly types: ToggleButton[] = [
    {
      label: $localize`:@@common.sellingPrice:Selling Price`,
      value: PriceSimulateOn.SellingPrice,
    },
    {
      label: $localize`:@@inventory.recipe.costing.foodCost:Food Cost`,
      value: PriceSimulateOn.FoodCost,
    },
  ];

  sellingPriceValue: number;
  averageFoodCostValue: number;
  lastPurchaseFoodCostValue: number;
  recipeCostingTotalTaxesValue: number;

  protected recipeTypeValue: InventoryRecipeType;
  protected readonly inventoryRecipeType = InventoryRecipeType;
  protected readonly basedOnEnum = PriceSimulateOn;

  protected readonly form = new FormGroup({
    targetCost: new FormControl<number | null>(null),
    basedOn: new FormControl<PriceSimulateOn>(PriceSimulateOn.SellingPrice),
  });

  get targetFoodCostValue(): number {
    return this.form.getRawValue().targetCost ?? 0;
  }

  get basedOn() {
    return this.form.value.basedOn;
  }

  get computedExpectedSellingPrice(): number {
    return this.averageFoodCostValue && this.targetFoodCostValue
      ? ((this.averageFoodCostValue * 100) / this.targetFoodCostValue) *
          (this.recipeCostingTotalTaxesValue ? 1 + this.recipeCostingTotalTaxesValue / 100 : 1)
      : 0;
  }

  get computedFoodCost(): number {
    return this.averageFoodCostValue;
  }

  get computedExpectedFoodCost(): number {
    return (this.sellingPriceValue / 100) * this.targetFoodCostValue;
  }

  get computedExpectedSellingProfit(): number {
    return this.computedExpectedSellingPrice ? this.computedExpectedSellingPrice - this.averageFoodCostValue : 0;
  }

  get computedExpectedFoodCostProfit(): number {
    return this.sellingPriceValue - this.computedExpectedFoodCost;
  }

  onTouched: () => void;
  onChange: (value: RecipePriceSimulatorForm) => void;

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(({ targetCost }) => {
      this.onChange?.({ targetCost });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isReadonly) {
      if (changes.isReadonly.currentValue) {
        this.form.disable();
      } else {
        this.form.enable();
      }
    }

    if (changes.isValidate) {
      this.form.get('targetCost')?.addValidators([Validators.required]);
      this.form.updateValueAndValidity();
    }
  }

  writeValue(value: RecipePriceSimulatorForm): void {
    if (value) {
      this.form.patchValue(value);
    }
  }

  registerOnChange(onChange: (value: RecipePriceSimulatorForm) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  validate(): ValidationErrors | null {
    if (this.form.valid) {
      return null;
    }

    return { valid: false };
  }

  private init(): void {
    if (this.isTargetCostSetManually) {
      return;
    }

    const targetCost = calculatedComputedFoodCost(
      this.averageFoodCostValue,
      this.recipeCostingTotalTaxesValue,
      this.sellingPriceValue,
    );

    this.form.patchValue({ targetCost: this.sellingPriceValue ? targetCost : 0 });
  }
}
