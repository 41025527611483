import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'supy-create-recipe-footer',
  templateUrl: './create-recipe-footer.component.html',
  styleUrls: ['./create-recipe-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateRecipeFooterComponent {
  @Input() readonly editMode?: boolean;
  @Input() readonly directToText?: string;
  @Input() readonly createDraftButton: boolean;
  @Input() readonly saveTitle: string = 'Save';
  @Input() readonly submitTitle: string = 'Submit';
  @Input() readonly nextMode: boolean;
  @Input() readonly isSaveLoading: boolean;
  @Input() readonly disableActions: boolean;

  @Output() readonly create = new EventEmitter<void>();
  @Output() readonly createAndRedirect = new EventEmitter<void>();
  @Output() readonly createDraft = new EventEmitter<void>();
  @Output() readonly save = new EventEmitter<void>();
  @Output() readonly next = new EventEmitter<void>();
}
