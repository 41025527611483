<div class="supy-create-recipe__price-simulator-chart">
  <supy-stacked-bar-chart-d3
    *ngIf="data().length"
    [data]="data()"
    [maxBars]="3"
    [horizontal]="false"
    [paddingInner]="0.2"
    [isBarClickable]="false"
    [isLabelClickable]="false"
    [isHoverable]="false"
    [xAxisShow]="true"
    [yAxisShow]="true"
    [dataAxisTotalOffset]="chartYAxisMaxValue()"
    [colors]="['rgba(241, 203, 48, 1)', 'rgba(97, 68, 191, 1)', 'rgba(61, 204, 135, 1)']"
    [showSeparateLabels]="false"
    [useGradient]="true"
    dataAxisSuffix="%"
  >
  </supy-stacked-bar-chart-d3>
</div>
