import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { INVENTORY_STOCK_COUNT_BFF_URI } from '../../../config';
import { InventoryVarianceReportExport } from '../core';

@Injectable({ providedIn: 'root' })
export class InventoryVarianceReportService {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(INVENTORY_STOCK_COUNT_BFF_URI) private readonly bffUri: string,
  ) {}

  getReport(stockCountId: string): Observable<InventoryVarianceReportExport> {
    return this.httpClient.get<InventoryVarianceReportExport>(`${this.bffUri}/${stockCountId}/variance-report/export`);
  }
}
