import { filter } from 'rxjs';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  Injector,
  OnInit,
  signal,
  untracked,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { hasActionsExecuting } from '@ngxs-labs/actions-executing';

import { Destroyable } from '@supy/common';
import { LoadingOverlayModule } from '@supy/components';
import { Writable } from '@supy/core';
import { CurrentRetailerState } from '@supy/retailers';
import { SettingsState } from '@supy/settings';
import { GetRetailerUsers } from '@supy/users';

import {
  costAdjustmentDetailPayload,
  CostAdjustmentFilters,
  CostAdjustmentGroup,
  costAdjustmentPayload,
  CostAdjustmentsGroupRowType,
  CostAdjustmentToggleRowParams,
  DetailRow,
  ToggleDetailsEvent,
} from '../../../core';
import {
  InventoryRecipeCostAdjustmentsDetails,
  InventoryRecipeCostAdjustmentsGetMany,
  InventoryRecipeCostAdjustmentsReset,
  InventoryRecipeState,
} from '../../../store';
import { CostAdjustmentsGridComponent } from '../cost-adjustments-grid';
import { CostAdjustmentsGridFiltersComponent } from '../cost-adjustments-grid-filters';

type RowDataType = Writable<CostAdjustmentGroup> | DetailRow;

@Component({
  selector: 'supy-cost-adjustments',
  templateUrl: './cost-adjustments.component.html',
  styleUrls: ['./cost-adjustments.component.scss'],
  standalone: true,
  imports: [CommonModule, CostAdjustmentsGridFiltersComponent, CostAdjustmentsGridComponent, LoadingOverlayModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CostAdjustmentsComponent extends Destroyable implements OnInit {
  readonly #store = inject(Store);
  readonly #router = inject(Router);
  readonly #injector = inject(Injector);

  readonly recipe = toSignal(this.#store.select(InventoryRecipeState.currentRecipe).pipe(filter(recipe => !!recipe)));

  protected readonly ianaTimeZone = this.#store.selectSignal(SettingsState.ianaTimeZone);
  protected readonly utcOffset = this.#store.selectSignal(SettingsState.utcOffset);
  protected readonly isLoading = this.#store.selectSignal(hasActionsExecuting([InventoryRecipeCostAdjustmentsGetMany]));
  protected readonly retailerId = this.#store.selectSignal(CurrentRetailerState.get);
  protected readonly currencyPrecision = this.#store.selectSignal(SettingsState.currencyPrecision);
  protected readonly currency = this.#store.selectSignal(SettingsState.currency);
  protected readonly loadingRowId = signal<string | null>(null);
  protected readonly locationIds = signal<string[]>(
    this.#router.parseUrl(this.#router.url).queryParams['locationIds']
      ? decodeURIComponent(String(this.#router.parseUrl(this.#router.url).queryParams['locationIds']))
          .split(',')
          .filter(Boolean)
      : [],
  );

  protected readonly date = signal<string>(this.#router.parseUrl(this.#router.url).queryParams['date'] as string);
  protected hasExpandedInitialRow = signal<boolean>(false);

  protected readonly locationsWithRegionsBranches = this.#store.selectSignal(
    CurrentRetailerState.locationsWithRegionsBranches,
  );

  protected salesTypes = this.#store.selectSignal(SettingsState.salesTypes);

  protected readonly activeSalesTypes = computed(() => {
    const active = this.recipe()?.activeSalesTypes;

    if (!active || !this.salesTypes()) return [];

    return this.salesTypes()
      .filter(type => active.some(activeType => activeType.id === type.id))
      .map(type => ({
        id: type.id,
        name: type.name,
      }));
  });

  protected recipePlace = signal<'repository' | 'inventory'>(
    this.#router.url.includes('repository') ? 'repository' : 'inventory',
  );

  protected readonly costAdjustments = this.#store.selectSignal(InventoryRecipeState.costAdjustments);
  protected readonly expandedRowId = signal<string | null>(null);
  protected readonly data = computed(() => {
    const baseAdjustments = structuredClone(this.costAdjustments());
    const expandedData: (CostAdjustmentGroup | DetailRow)[] = [];

    baseAdjustments.forEach(row => {
      expandedData.push(row);

      if (this.isGroupRow(row) && row.id === this.expandedRowId()) {
        const details = row.detailsData;

        if (details) {
          details.itemIngredients.forEach(ingredient => {
            expandedData.push({
              ...ingredient,
              rowType: 'ingredient',
              type: 'item',
              parentId: row.id,
              isLink: true,
            } as DetailRow);
          });

          if (details.detailsChanged) {
            expandedData.push({
              name: $localize`:@@costAdjustments.recipeDetailsChanged:Recipe details changed`,
              rowType: 'detail',
              type: 'detail',
              parentId: row.id,
              isLink: true,
              cost: 0,
              percentage: 0,
            } as DetailRow);
          }

          details.recipeIngredients.forEach(ingredient => {
            expandedData.push({
              ...ingredient,
              rowType: 'ingredient',
              type: 'recipe',
              parentId: row.id,
              isLink: true,
            } as DetailRow);
          });
        }
      }
    });

    return expandedData;
  });

  protected currentFilters: CostAdjustmentFilters = {
    dateFrom: null,
    dateTo: null,
    locationId: null,
    salesTypeId: null,
  };

  ngOnInit(): void {
    effect(
      () => {
        const recipe = this.recipe();

        if (recipe) {
          const basePayload = this.getBasePayload();

          if (basePayload.locationId) {
            this.fetchCostAdjustments(basePayload);
          } else {
            this.setCostAdjsutmentsEmpty();
          }
        }
      },
      {
        injector: this.#injector,
      },
    );

    effect(
      () => {
        if (this.costAdjustments().length) {
          this.loadingRowId.set(null);
        }
      },
      {
        injector: this.#injector,
        allowSignalWrites: true,
      },
    );

    effect(
      () => {
        if (this.date() && this.costAdjustments().length && !untracked(this.hasExpandedInitialRow)) {
          this.hasExpandedInitialRow.set(true);

          const matchingAdjustment = this.costAdjustments().find(adjustment => adjustment.date === this.date());

          if (matchingAdjustment) {
            this.onToggleRow({
              date: matchingAdjustment.date,
              groupRowId: matchingAdjustment.id,
              previouslyExpanded: false,
            });
          }
        }
      },
      {
        injector: this.#injector,
        allowSignalWrites: true,
      },
    );
    this.fetchRetailerUsers();
  }

  private isGroupRow(data: RowDataType): data is Writable<CostAdjustmentGroup> {
    return data.rowType === CostAdjustmentsGroupRowType;
  }

  private getBasePayload(): Omit<costAdjustmentPayload, 'dateFrom' | 'dateTo'> {
    return {
      retailerId: this.retailerId(),
      locationId: this.locationIds().at(0) ?? this.recipe()?.locations?.at(0)?.locationId ?? '',
      salesTypeId: this.activeSalesTypes()?.at(0)?.id ?? '',
    };
  }

  private createFilteredPayload(filters: Partial<CostAdjustmentFilters>): costAdjustmentPayload {
    const basePayload = this.getBasePayload();
    const payload: costAdjustmentPayload = {
      ...basePayload,
      locationId: filters.locationId ?? basePayload.locationId,
    };

    if (filters.salesTypeId || basePayload.salesTypeId) {
      payload.salesTypeId = filters.salesTypeId ?? basePayload.salesTypeId;
    }

    if (filters.dateFrom) {
      payload.dateFrom = filters.dateFrom;
    }

    if (filters.dateTo) {
      payload.dateTo = filters.dateTo;
    }

    return payload;
  }

  private fetchCostAdjustments(payload: costAdjustmentPayload): void {
    this.#store.dispatch(new InventoryRecipeCostAdjustmentsGetMany(this.recipe().id, payload));
  }

  private setCostAdjsutmentsEmpty(): void {
    this.#store.dispatch(new InventoryRecipeCostAdjustmentsReset());
  }

  protected onFiltersChange(filters: Partial<CostAdjustmentFilters>): void {
    const payload = this.createFilteredPayload(filters);

    this.currentFilters = { ...filters };
    this.fetchCostAdjustments(payload);
  }

  protected onFiltersClear(): void {
    const basePayload = this.getBasePayload();

    this.currentFilters = {
      dateFrom: null,
      dateTo: null,
      locationId: basePayload.locationId,
      salesTypeId: basePayload.salesTypeId,
    };
    this.fetchCostAdjustments(basePayload);
  }

  private fetchRetailerUsers(): void {
    this.#store.dispatch(new GetRetailerUsers(this.retailerId()));
  }

  protected onToggleRow({ date, groupRowId, previouslyExpanded }: CostAdjustmentToggleRowParams): void {
    const isCurrentlyExpanded = this.expandedRowId() === groupRowId;

    if (isCurrentlyExpanded) {
      this.expandedRowId.set(null);
      this.loadingRowId.set(null);
    } else {
      this.expandedRowId.set(groupRowId);
      this.loadingRowId.set(groupRowId);

      const recipe = this.recipe();

      if (!recipe?.id || previouslyExpanded) {
        this.loadingRowId.set(null);

        return;
      }

      const payload: costAdjustmentDetailPayload = {
        retailerId: this.retailerId(),
        locationId: this.currentFilters.locationId ?? this.getBasePayload().locationId ?? '',
        date: date,
      };

      const salesTypeId = this.currentFilters.salesTypeId ?? this.activeSalesTypes()?.at(0)?.id;

      if (salesTypeId) {
        payload.salesTypeId = salesTypeId;
      }

      this.#store.dispatch(new InventoryRecipeCostAdjustmentsDetails(recipe.id, payload, groupRowId));
    }
  }

  protected buildDetailUrl(config: {
    type: 'item' | 'detail' | 'recipe';
    itemId?: string;
    recipeId?: string;
    date?: string;
  }): string {
    const baseUrl = window.location.origin;
    const { locationId, dateFrom, dateTo } = this.currentFilters;

    const path = this.getDetailPath(config);

    if (!path) return '';

    const queryParams = new URLSearchParams();

    if (locationId || this.locationIds().at(0)) {
      if (config.type === 'item') {
        queryParams.set(
          'locationId',
          locationId ?? this.locationIds().at(0) ?? this.recipe()?.locations?.at(0)?.locationId ?? '',
        );
      } else {
        queryParams.set(
          'locationIds',
          locationId ?? this.locationIds().at(0) ?? this.recipe()?.locations?.at(0)?.locationId ?? '',
        );
      }
    }

    if (config?.date) {
      queryParams.set('date', config.date);
    }

    if (dateFrom) {
      queryParams.set('start', dateFrom);
    }

    if (dateTo) {
      queryParams.set('end', dateTo);
    }

    const queryString = queryParams.toString();

    return `${baseUrl}${path}${queryString ? `?${queryString}` : ''}`;
  }

  private getDetailPath(config: {
    type: 'item' | 'detail' | 'recipe';
    itemId?: string;
    recipeId?: string;
    date?: string;
  }): string {
    switch (config.type) {
      case 'item':
        return config.itemId ? `/inventory/stocks/${config.itemId}/stock-movement/list` : '';
      case 'detail': {
        const recipeId = this.recipe()?.id;

        return recipeId ? `/${this.recipePlace()}/recipes/${recipeId}/audit-log` : '';
      }
      case 'recipe': {
        const recipeId = config.recipeId;

        return recipeId ? `/${this.recipePlace()}/recipes/${recipeId}/cost-adjustments` : '';
      }
      default:
        return '';
    }
  }

  protected onToggleDetails(event: ToggleDetailsEvent): void {
    const url = this.buildDetailUrl(event);

    window.open(url, '_blank');
  }
}
