import { first } from 'rxjs';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  signal,
  ViewChild,
} from '@angular/core';

import { IdType } from '@supy/common';
import { ConfirmDialogWithIconComponent, DialogService, DrawerComponent, DropdownTreeNode } from '@supy/components';

@Component({
  selector: 'supy-update-recipes-category-drawer',
  templateUrl: './update-recipes-category-drawer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService],
})
export class UpdateRecipesCategoryDrawerComponent extends DrawerComponent {
  @ViewChild(DrawerComponent, { static: true }) private readonly drawer: DrawerComponent;
  @Input() readonly categories: DropdownTreeNode<string>[];
  @Output() readonly confirmed = new EventEmitter<IdType>();

  protected readonly category = signal<string>('');

  readonly #dialogService = inject(DialogService);

  protected save(): void {
    this.#dialogService
      .openDialog(ConfirmDialogWithIconComponent, {
        title: 'Update Category',
        message: 'Are you sure you want to update the category for the chosen recipes?',
        confirmText: 'Proceed',
      })
      .confirm.pipe(first())
      .subscribe(() => this.confirmed.emit({ id: this.category() }));
  }

  closeDialog(): void {
    this.drawer.closeDialog();
  }
}
