<supy-icon-status-badge
  *ngIf="circle; else rect"
  [status]="eventStatus"
  [text]="status | titlecase"
  width="100%"
  weight="normal"
></supy-icon-status-badge>
<ng-template #rect>
  <supy-status-badge [status]="eventStatus" [text]="status | titlecase" width="100%" weight="bold"></supy-status-badge>
</ng-template>
