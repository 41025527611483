import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Currency } from '@supy.api/dictionaries';

import { IdType } from '@supy/common';
import { DonutChartEntry, ToggleButton } from '@supy/components';

import { computedFoodCost, computedProfit, computedSellingPriceExclVat } from '../../core';

enum FoodCostChartType {
  Average = 'average',
  LastPurchase = 'lastPurchase',
}

@Component({
  selector: 'supy-recipe-costing-chart',
  templateUrl: './recipe-costing-chart.component.html',
  styleUrls: ['./recipe-costing-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecipeCostingChartComponent {
  @Input() readonly currency: Currency;
  @Input() readonly currencyPrecision: number;
  @Input() readonly averageFoodCost: number;
  @Input() readonly lastPurchaseFoodCost: number;
  @Input() readonly recipeCostingTotalTaxes: number;
  @Input() readonly sellingPrice: number;

  protected readonly chartTypes: ToggleButton<FoodCostChartType>[] = [
    {
      label: $localize`:@@inventory.recipe.costing.averageCost:Average Cost`,
      value: FoodCostChartType.Average,
    },
    {
      label: $localize`:@@inventory.recipe.costing.lastPurchaseCost:Last Purchase Cost`,
      value: FoodCostChartType.LastPurchase,
    },
  ];

  protected readonly form = new FormGroup({
    sellingPrice: new FormControl<number | null>(null),
    costThreshold: new FormControl<number | null>(null),
    taxes: new FormControl<IdType[]>([]),
  });

  protected readonly defaultSliceSelection = [
    {
      category: '',
      value: 100,
      summary: '100%',
    },
  ];

  selectedChartType = FoodCostChartType.Average;

  activeSlice = 0;

  protected readonly computedSellingPriceExclVat = computedSellingPriceExclVat;

  get chartData(): DonutChartEntry[] {
    return [
      {
        category: $localize`:@@inventory.recipe.costing.foodCost:Food Cost`,
        value: this.foodCostByType,
        summary: `${computedFoodCost(this.foodCostByType, this.recipeCostingTotalTaxes, this.sellingPrice)}%`,
      },
      {
        category: $localize`:@@inventory.recipe.costing.profit:Profit`,
        value: computedSellingPriceExclVat(this.recipeCostingTotalTaxes, this.sellingPrice) - this.foodCostByType,
        summary: `${computedProfit(this.foodCostByType, this.recipeCostingTotalTaxes, this.sellingPrice)}%`,
      },
    ];
  }

  get foodCostByType(): number {
    switch (this.selectedChartType) {
      case FoodCostChartType.Average:
        return this.averageFoodCost;
      case FoodCostChartType.LastPurchase:
        return this.lastPurchaseFoodCost;
    }
  }

  onSliceClicked(index: number): void {
    this.activeSlice = index;
  }
}
