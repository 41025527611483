export class GetUoms {
  static type = '[Uoms] GetUoms';

  constructor(public forceFetch = false) {}
}

export class GetUomsAsTree {
  static type = '[Uoms] GetUomsAsTree';

  constructor(public forceFetch = false) {}
}
