import { BaseSelectItem, IdType, LocalizedData, Query } from '@supy/common';
import { BadgeStatus } from '@supy/components';
import { PackagingUnit, PackagingUnitRequest } from '@supy/packaging';

import { BaseItemInventoryLocationRequest, CreateInventoryLocationRequest, InventoryItemType } from '../../../core';
import {
  InventoryIngredient,
  InventoryIngredientQuantity,
  InventoryRecipe,
  InventoryRecipeStateEnum,
  InventoryRecipeType,
} from './inventory-recipe.entity';

export class InventoryRecipeMetadataRequest {
  readonly cookTimeInMinutes?: number;
  readonly portionSize?: string;
  readonly servingPersons?: number;
  readonly difficulty?: number;
  readonly steps?: string[];
  readonly instructions?: string;
}

export class InventoryIngredientQuantityRequest {
  readonly net: number;
  readonly gross: number;
}

export class InventoryIngredientRequest {
  readonly item: IdType;
  readonly quantity: InventoryIngredientQuantityRequest;
  readonly remarks: string;
}

export class UpdateRecipePortionSizeRequest {
  readonly size: number;
  readonly uom: IdType;
}

export class InventoryRecipeBaseRequest {
  readonly id?: string;
  readonly code?: string;
  readonly imageUrl?: string;
  readonly name: LocalizedData;
  readonly type: InventoryRecipeType;
  readonly category?: IdType;
  readonly storages?: IdType[];
  readonly baseUnit?: IdType;
  readonly costThreshold?: number;
  readonly sellingPrice?: number;
  readonly targetCost?: number;
  readonly state?: InventoryRecipeStateEnum;
  readonly portionSize?: UpdateRecipePortionSizeRequest;
  readonly taxes?: IdType[];
  readonly isForRevenueTracking?: boolean;
  readonly location?: IdType;
  readonly activeSalesTypes?: string[];
}

export class InventoryRecipeCreateRequest extends InventoryRecipeBaseRequest {
  readonly ingredients: CreateIngredientRequest[];
  readonly retailer: IdType;
}

export interface IngredientQuantityRequest {
  readonly net: number;
  readonly wastagePercentage: number;
}

export interface IngredientScopeRequest {
  readonly referenceId: string;
  readonly type: InventoryItemType;
}

export class CreateIngredientRequest {
  readonly includedInCost: boolean;
  readonly modifier: boolean;
  readonly quantity: IngredientQuantityRequest;
  readonly scope: IngredientScopeRequest;
  readonly unit: PackagingUnitRequest;
}

export class UpdateIngredientRequest {
  readonly id: string;
  readonly includedInCost?: boolean;
  readonly item?: IdType;
  readonly modifier?: boolean;
  readonly quantity?: IngredientQuantityRequest;
  readonly unit?: PackagingUnitRequest;
  readonly scope?: IngredientScopeRequest;
}
export interface FinishedRecipedUpdateRequest extends InventoryRecipeBaseRequest {
  readonly ingredients: FinishedRecipeIngredientUpdateRequest[];
  readonly effectiveDate?: Date;
}

export interface FinishedRecipeIngredientUpdateRequest {
  readonly salesTypeId: string;
  readonly changes: UpdateInventoryIngredientType;
}

export interface SemiFinishedRecipedUpdateRequest extends InventoryRecipeBaseRequest {
  readonly ingredients: UpdateInventoryIngredientType;
  readonly effectiveDate?: Date;
}
export class InventoryRecipeUpdateRequest extends InventoryRecipeBaseRequest {
  readonly ingredients: UpdateInventoryIngredientType;
  readonly effectiveDate?: Date;
}
export class UpdateInventoryIngredientType {
  readonly create?: CreateIngredientRequest[];
  readonly update?: UpdateIngredientRequest[];
  readonly delete?: string[];
}

export class InventoryRecipeBaseInventoryPayload {
  readonly isStockable?: boolean;
  readonly portionSize: number;
}

export class CreateInventoryRecipeInventoryRequest extends InventoryRecipeBaseInventoryPayload {
  readonly locations?: CreateInventoryLocationRequest[];
}

export class UpdateRecipeInventoryRequest {
  locations?: BaseItemInventoryLocationRequest[];
  costCenters?: IdType[];
  state?: InventoryRecipeStateEnum;
}

export class UpdateRecipeCookbookRequest {
  readonly cookTimeInMinutes?: number;
  readonly servingPersons?: number;
  readonly difficulty?: number;
  readonly steps?: string[];
  readonly instructions?: string;
  readonly hidden?: boolean;
}

export interface InventoryRecipeRequestProps {
  readonly 'category.id': string;
  readonly 'createdBy.id': string;
  readonly 'ingredients.scope.referenceId': string;
  readonly 'locations.locationId': string;
  readonly 'name.en': string;
  readonly 'retailer.id': string;
  readonly 'locations.useInProduction': string;
  readonly createdAt: Date;
  readonly id: string;
  readonly tileType: string;
}

export interface InventoryRecipeStepGridItem {
  readonly id: string;
  readonly text: string;
}

export class InventoryIngredientNormalized {
  created?: boolean;
  item: RecipeIngredientItem | null;
  packagingUnit?: PackagingUnit;
  prepWastage?: number;
  quantityNet?: number;
  readonly id?: string;
  readonly includedInCost?: boolean;
  readonly modifier: boolean;
  readonly quantity?: InventoryIngredientQuantity;
  readonly remarks?: string;

  static default(): InventoryIngredientNormalized {
    return {
      id: crypto.randomUUID(),
      item: null,
      modifier: false,
      includedInCost: true,
    };
  }

  static fromEntity(ingredient: InventoryIngredient): InventoryIngredientNormalized {
    return {
      ...ingredient,
      prepWastage: ingredient.quantity?.wastagePercentage,
      quantityNet: ingredient.quantity?.net,
      packagingUnit: { ...(ingredient.quantity?.unit ?? ({} as PackagingUnit)) },
      item: {
        id: ingredient.scope?.referenceId,
        type: ingredient.scope?.type,
        packagings: ingredient.packagings,
        name: ingredient.name,
        packagingUnit: ingredient.quantity?.unit ?? ({} as PackagingUnit),
        wastagePercentage: ingredient.quantity?.wastagePercentage,
        cost: ingredient.cost,
        lastPurchaseCost: ingredient.lastPurchaseCost,
      },
      created: true,
    };
  }

  static fromEntityList(ingredients: InventoryIngredient[]): InventoryIngredientNormalized[] {
    return ingredients.map(ingredient => InventoryIngredientNormalized.fromEntity(ingredient));
  }
}

export interface RecipeIngredientItem {
  readonly id?: string;
  readonly name?: LocalizedData;
  readonly type?: InventoryItemType;
  readonly isStockable?: boolean;
  readonly packagingUnit?: PackagingUnit;
  readonly packagings?: PackagingUnit[];
  readonly wastagePercentage?: number;
  readonly cost?: number;
  readonly lastPurchaseCost?: number;
}

export interface RecipeIngredientItemsInput {
  readonly recipeId?: string;
  readonly retailerId: string;
  readonly term: string;
  readonly locationId?: string;
}

export interface RecipeStatisticsData {
  readonly recipeIds?: string[];
  readonly value: number;
}

export class RecipeStatisticsResponse {
  readonly recipesTotal: RecipeStatisticsData;
  readonly unmappedFinished: RecipeStatisticsData;
  readonly aboveFoodCost: RecipeStatisticsData;
  readonly aboveTargetCost: RecipeStatisticsData;
  readonly belowTargetCost: RecipeStatisticsData;

  constructor() {
    this.aboveFoodCost =
      this.aboveTargetCost =
      this.belowTargetCost =
      this.recipesTotal =
      this.unmappedFinished =
        { value: 0, recipeIds: [] };
  }
}

export enum RecipesStatisticsCardType {
  RecipesTotal = 'recipes-total',
  NotLinked = 'unmapped-finished',
  AboveFoodCost = 'above-food-cost',
  aboveTargetCost = 'above-target-cost',
  belowTargetCost = 'below-target-cost',
}

export enum RecipeStateAction {
  Archive = 'archive',
  Activate = 'activate',
}

export class BulkUpdateRecipesRequest {
  readonly query: Query<InventoryRecipe & InventoryRecipeRequestProps> | undefined;
}

export interface BulkUpdateRecipesCategoryRequest extends BulkUpdateRecipesRequest {
  readonly category: IdType;
}

export interface CostCenterPayload {
  readonly location: IdType;
  readonly branch: IdType;
  readonly targetCost?: number;
  readonly costThreshold?: number;
  readonly sellingPrice?: number;
  readonly revenuePercentage?: number;
  readonly taxes?: IdType[];
  readonly 'location.id'?: string;
  readonly 'branch.id'?: string;
}

export interface RecipeUpdateCostCenterRequest {
  readonly costCenters: CostCenterPayload[];
  readonly effectiveDate?: Date;
}

export class RecipeLocationPayload {
  readonly locationId: string;
  readonly useInProduction?: boolean;
}

export interface RecipeUpdateLocationsRequest {
  readonly locations: RecipeLocationPayload[];
  readonly effectiveDate?: Date;
}

export interface RecipeStatement {
  readonly recipeId: string;
  readonly locationId: string;
  readonly cost: number;
  readonly lastPurchaseCost: number;
}

export interface CloneRecipeRequest {
  readonly locations?: IdType[];
}

export interface ReplaceItemAffectedRecipe {
  readonly id: string;
  readonly ingredientId: string;
}

export interface ReplaceItemRequest {
  readonly oldItem: IdType;
  readonly newItem: IdType;
  readonly affectedRecipes?: ReplaceItemAffectedRecipe[];
  readonly modifications?: ReplaceItemInRecipeRequest[];
  readonly conversionRatio?: number;
  readonly effectiveDate?: Date;
}

export interface ReplaceItemInRecipeRequest {
  readonly recipeId: string;
  readonly ingredientId: string;
  readonly wastagePercentage: number;
  readonly netQuantity: number;
  readonly baseUnitId: string;
}

export interface AutocompleteFinishedRecipesQueryProps {
  readonly retailerId: string;
  readonly term: string;
  readonly locationId?: string;
  readonly effectiveDate?: string;
}

export interface RecipeStateMapper extends BaseSelectItem {
  readonly status: BadgeStatus;
}

export const recipeStatesMapper = {
  [InventoryRecipeStateEnum.Available]: {
    label: $localize`:@@statusPublished:Published`,
    value: InventoryRecipeStateEnum.Available,
    status: 'success',
  },
  [InventoryRecipeStateEnum.Draft]: {
    label: $localize`:@@draft:Draft`,
    value: InventoryRecipeStateEnum.Draft,
    status: 'warn',
  },
  [InventoryRecipeStateEnum.Archived]: {
    label: $localize`:@@statusArchived:Archived`,
    value: InventoryRecipeStateEnum.Archived,
    status: 'grey',
  },
} as Record<string, RecipeStateMapper>;

export interface BackdateRecipeRequest {
  readonly effectiveDate: Date;
}
