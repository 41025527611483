import { Query } from '@supy/common';

import { PriceHistoryRequestProps } from '../../core';
import { PriceHistoryFilters, PriceHistoryRequestMetadata } from '../state';

export class PriceHistoryGetMany {
  static readonly type = '[PriceHistory] GetMany';

  constructor(readonly query?: Query<PriceHistoryRequestProps>) {}
}

export class PriceHistoryInitFilters {
  static readonly type = '[PriceHistory] InitFilters';
}

export class PriceHistoryPatchFilter {
  static readonly type = '[PriceHistory] PatchFilter';
  constructor(readonly payload: Partial<PriceHistoryFilters>) {}
}

export class PriceHistoryResetFilter {
  static readonly type = '[PriceHistory] ResetFilter';
}

export class PriceHistoryPatchRequestMeta {
  static readonly type = '[PriceHistory] PatchRequestMeta';
  constructor(readonly payload: Partial<PriceHistoryRequestMetadata>) {}
}
