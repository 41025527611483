import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Currency } from '@supy.api/dictionaries';

import { trackByIndex, trackByProperty } from '@supy/common';
import { DropdownTreeNode } from '@supy/components';
import { TaxRate } from '@supy/settings';

import {
  CostCenterGridItem,
  CostCenterLocationGridItem,
  CostCenterPayload,
  MultipleCostCenterGridItem,
} from '../../core';

@Component({
  selector: 'supy-cost-centers-multiple-grid',
  templateUrl: 'cost-centers-multiple-grid.component.html',
  styleUrls: ['./cost-centers-multiple-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CostCentersMultipleGridComponent {
  @Input() currency: Currency;
  @Input() readonly currencyPrecision: number;

  @Input() set data(value: CostCenterGridItem[]) {
    this.multipleCostCentersData = value?.reduce(
      (arr: MultipleCostCenterGridItem[], costCenter: CostCenterGridItem) => {
        if (arr?.map(({ id }) => id)?.includes(costCenter.id)) {
          return arr;
        }

        const currentCostCenters = value.filter(({ branch }) => branch.id === costCenter.branch.id);

        arr.push({
          id: costCenter.id,
          branch: costCenter.branch,
          sellingPrice: currentCostCenters.reduce((sum, { sellingPrice }) => sum + (sellingPrice ?? 0), 0),
          taxes: currentCostCenters[0]?.taxes,
          locations: currentCostCenters
            .flatMap(costCenter => costCenter)
            .filter(Boolean) as CostCenterLocationGridItem[],
        });

        return arr;
      },
      [],
    );
  }

  @Input() taxes: TaxRate[];

  @Input() set defaultSellingPrice(value: number) {
    if (typeof value === 'number') {
      this.defaultSellingPriceValue = value;
      this.multipleCostCentersData.forEach(costCenter => {
        costCenter.sellingPrice = value;
      });

      this.onCellEditDone();
    }
  }

  @Input() set defaultTaxes(value: string[]) {
    if (Array.isArray(value)) {
      this.defaultTaxesValue = value;
      this.multipleCostCentersData.forEach(costCenter => {
        costCenter.taxes = value;
      });

      this.onCellEditDone();
    }
  }

  @Output() readonly costCenterChange = new EventEmitter<CostCenterPayload[]>();

  protected multipleCostCentersData: MultipleCostCenterGridItem[];
  protected defaultTaxesValue: string[];
  protected defaultSellingPriceValue: number;
  protected readonly trackById = trackByProperty<DropdownTreeNode<string>>('id');
  protected readonly trackByIndex = trackByIndex<CostCenterLocationGridItem>();

  onModify(branchId: string, costCenterIndex?: number): void {
    this.multipleCostCentersData.forEach(costCenter => {
      if (costCenter.id === branchId) {
        costCenter.locations =
          costCenterIndex || costCenterIndex === 0
            ? costCenter.locations?.filter((_, index) => costCenterIndex !== index)
            : [
                ...(costCenter.locations ?? []),
                { sellingPrice: this.defaultSellingPriceValue, taxes: this.defaultTaxesValue },
              ];
      }
    });

    this.onCellEditDone();
  }

  isRevenuePercentageValid(branchId: string): boolean {
    const percentage =
      this.multipleCostCentersData
        .find(({ id }) => branchId === id)
        ?.locations.reduce((acc, curr) => acc + +(curr.revenuePercentage ?? 0), 0) ?? 0;

    if (percentage > 0 && percentage !== 100) {
      return false;
    }

    return true;
  }

  getLocationsLength(branch: MultipleCostCenterGridItem): number {
    return branch.locations?.filter(({ location }) => location?.id)?.length ?? 0;
  }

  getCostCenterSellingPrice(revenuePercentage: number | undefined, branchSellingPrice: number): number {
    if (!revenuePercentage || !branchSellingPrice) {
      return 0;
    }

    return (branchSellingPrice * revenuePercentage) / 100;
  }

  onCellEditDone(): void {
    const payload = this.multipleCostCentersData
      .flatMap(costCenter =>
        costCenter.locations.map(({ location, revenuePercentage }) => ({
          location: { id: location?.id },
          branch: { id: costCenter.branch.id },
          targetCost: 0,
          revenuePercentage,
          sellingPrice: this.getCostCenterSellingPrice(revenuePercentage, costCenter.sellingPrice),
          taxes: costCenter.taxes?.map(id => ({ id })),
        })),
      )
      .filter(costCenter => costCenter?.location?.id);

    for (const costCenter of payload) {
      if (!this.isRevenuePercentageValid(costCenter.branch.id)) {
        this.costCenterChange.emit(null);

        return;
      }
    }

    this.costCenterChange.emit(payload);
  }

  onLocationSelect(
    location: DropdownTreeNode<string>,
    costCenterGridItem: CostCenterLocationGridItem,
    gridItem: MultipleCostCenterGridItem,
  ): void {
    costCenterGridItem.location = location;

    const selectedCostCenters = gridItem.locations?.filter(locationGridItem => locationGridItem.location?.id).length;

    if (selectedCostCenters === 1) {
      costCenterGridItem.revenuePercentage = 100;
    }
  }
}
