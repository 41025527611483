import { Attachment, IdType, LocalizedData, SimpleUser, Uom } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';
import { PackagingUnit } from '@supy/packaging';

import { InventoryItemType } from '../../../core';
import { InventoryEventStateEnum } from './inventory-event.model';
import { InventoryTransferStateEnum } from './inventory-transfer-event.model';

export abstract class InventoryEvent {
  readonly id: string;
  readonly name?: string;
  readonly retailer: IdType;
  readonly state: InventoryEventStateEnum | InventoryTransferStateEnum;
  readonly remarks?: string;
  readonly items: InventoryEventItem[];
  readonly cost: number;
  readonly createdBy?: SimpleUser;
  readonly updatedBy?: SimpleUser;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly eventDate: Date;
  readonly attachments?: Attachment[];

  constructor(args: NonFunctionProperties<InventoryEvent>) {
    this.id = args.id;
    this.name = args.name;
    this.retailer = args.retailer;
    this.state = args.state;
    this.remarks = args.remarks;
    this.items = args.items;
    this.cost = args.cost;
    this.createdAt = args.createdAt;
    this.updatedAt = args.updatedAt;
    this.eventDate = args.eventDate;
    this.attachments = args.attachments;
  }

  static default(): InventoryEvent {
    return {
      id: crypto.randomUUID(),
      retailer: IdType.default(),
      state: InventoryEventStateEnum.Submitted,
      cost: 12,
      createdAt: new Date(),
      updatedAt: new Date(),
      eventDate: new Date(),
      items: [],
    };
  }
}

export class InventoryEventItem {
  readonly id: string;
  readonly referenceId: string;
  readonly name: LocalizedData;
  readonly quantity: number;
  readonly packagingUnit: PackagingUnit | undefined;
  readonly cost: number;
  readonly packagings: PackagingUnit[];
  readonly lastStockCountIncludedDate?: number;
  readonly type: InventoryItemType;

  constructor(args: NonFunctionProperties<InventoryEventItem>) {
    this.id = args.id;
    this.name = args.name;
    this.quantity = args.quantity;
    this.packagingUnit = args.packagingUnit && PackagingUnit.deserialize(args.packagingUnit);
    this.cost = args.cost;
    this.packagings = args.packagings ?? [];
    this.lastStockCountIncludedDate = args.lastStockCountIncludedDate;
    this.type = args.type;
  }

  static default(): InventoryEventItem {
    return {
      id: crypto.randomUUID(),
      referenceId: crypto.randomUUID(),
      name: { en: '' },
      quantity: 0,
      packagingUnit: undefined,
      cost: 0,
      packagings: [],
      type: InventoryItemType.Item,
    };
  }

  static deserialize(args: NonFunctionProperties<InventoryEventItem>): InventoryEventItem {
    return new InventoryEventItem({ ...args });
  }

  static deserializeList(data: NonFunctionProperties<InventoryEventItem>[]): InventoryEventItem[] {
    return data.map(inventoryEventItem => InventoryEventItem.deserialize(inventoryEventItem));
  }
}

export class InventoryEventSearchItem {
  readonly id: string;
  readonly name: LocalizedData;
  readonly packagingUnit: PackagingUnit | undefined;
  readonly packagings: PackagingUnit[];
  readonly cost?: number;
  readonly lastStockCountIncludedDate?: number;
  readonly type: InventoryItemType;

  constructor(args: NonFunctionProperties<InventoryEventSearchItem>) {
    this.id = args.id;
    this.name = args.name;
    this.packagingUnit = args.packagingUnit && PackagingUnit.deserialize(args.packagingUnit);
    this.cost = args.cost;
    this.packagings = args.packagings ?? [];
    this.lastStockCountIncludedDate = args.lastStockCountIncludedDate;
    this.type = args.type;
  }

  static deserialize(data: NonFunctionProperties<InventoryEventSearchItem>): InventoryEventSearchItem {
    return new InventoryEventSearchItem({ ...data });
  }

  static from(args: NonFunctionProperties<InventoryEventItem>): InventoryEventSearchItem {
    return InventoryEventSearchItem.deserialize({
      id: args.referenceId,
      name: args.name,
      packagingUnit: args.packagingUnit && PackagingUnit.deserialize(args.packagingUnit),
      packagings: args.packagings && PackagingUnit.deserializeList(args.packagings),
      cost: args.cost,
      lastStockCountIncludedDate: args.lastStockCountIncludedDate,
      type: args.type,
    });
  }

  checkIfUsedAsPiece(): boolean {
    return this.packagings?.some(({ isPiece }) => isPiece) ?? false;
  }

  getUsedAsPiecePackaging(): PackagingUnit | undefined {
    return this.packagings?.find(({ isPiece }) => isPiece);
  }

  getAllowedUoms(uoms: Uom[]): Uom[] {
    const pieceUom = uoms.find(({ isPiece }) => isPiece);

    return pieceUom && this.packagingUnit?.uomId === pieceUom?.id
      ? [pieceUom]
      : this.checkIfUsedAsPiece()
      ? uoms
      : uoms.filter(({ isPiece }) => !isPiece);
  }
}
