import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Query } from '@supy/common';
import {
  CountingPackaging,
  CreatePackagingRequest,
  Packaging,
  PackagingGroup,
  PackagingRequestProps,
  UpdatePackagingRequest,
  UpdatePackagingResponse,
} from '@supy/packaging';

import { PACKAGING_BFF_URI } from '../config';

@Injectable({ providedIn: 'root' })
export class PackagingService {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(PACKAGING_BFF_URI) private readonly bffUri: string,
  ) {}

  create(body: CreatePackagingRequest): Observable<PackagingGroup> {
    return this.httpClient.post<PackagingGroup>(this.bffUri, body);
  }

  update(id: string, body: UpdatePackagingRequest): Observable<UpdatePackagingResponse> {
    return this.httpClient.patch<UpdatePackagingResponse>(`${this.bffUri}/${id}`, body);
  }

  delete(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.bffUri}/${id}`);
  }

  getMany(query: Query<Packaging & PackagingRequestProps>): Observable<CountingPackaging[]> {
    // filter by retailer & state
    return this.httpClient.get<CountingPackaging[]>(`${this.bffUri}`, {
      params: new HttpParams({
        fromObject: query.toQueryParams(),
      }),
    });
  }
}
