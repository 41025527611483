import { distinctUntilKeyChanged, filter, takeUntil, tap } from 'rxjs';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, OnInit, signal, ViewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Store } from '@ngxs/store';

import { Destroyable } from '@supy/common';
import { ButtonModule, DrawerComponent, DropdownTreeNode, TabsModule } from '@supy/components';
import { CurrentRetailerState } from '@supy/retailers';
import { getLocalizedName } from '@supy/settings';

import { getRecipeActionDrawerTabs } from '../../core/action-drawer.tabs';
import { InventoryRecipeGet, InventoryRecipeState } from '../../store';

@Component({
  selector: 'supy-recipe-action-drawer',
  templateUrl: './recipe-action-drawer.component.html',
  styleUrl: './recipe-action-drawer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ButtonModule, TabsModule, DrawerComponent, RouterModule],
})
export class RecipeActionDrawerComponent extends Destroyable implements OnInit {
  @ViewChild(DrawerComponent, { static: true }) readonly drawer: DrawerComponent;
  readonly #router = inject(Router);
  readonly #route = inject(ActivatedRoute);
  readonly #store = inject(Store);

  readonly #recipe = toSignal(this.#store.select(InventoryRecipeState.currentRecipe).pipe(filter(recipe => !!recipe)));
  protected readonly headerName = computed(() => getLocalizedName(this.#recipe()?.name));
  protected recipePlace = signal(this.#router.url.includes('repository') ? 'repository' : 'inventory');
  readonly #detailSection = computed(() => this.#router.url.includes('/details/'));

  protected readonly locationIds = signal<string[]>(
    this.#router.parseUrl(this.#router.url).queryParams['locationIds']
      ? decodeURIComponent(String(this.#router.parseUrl(this.#router.url).queryParams['locationIds']))
          .split(',')
          .filter(Boolean)
      : [],
  );

  protected readonly tabs = computed(() => {
    if (this.recipePlace() && this.#recipe()?.id) {
      return getRecipeActionDrawerTabs(
        this.recipePlace(),
        this.#recipe()?.id,
        this.#detailSection(),
        this.locationIds(),
      );
    } else {
      return [];
    }
  });

  readonly #locationsWithRegionsBranches = this.#store.selectSignal(CurrentRetailerState.locationsWithRegionsBranches);

  protected readonly locationInfo = computed(() => {
    const locationId = this.locationIds().at(0) ?? this.#recipe()?.locations?.[0]?.locationId;

    if (!locationId) return '';

    const findLocationPath = (nodes: DropdownTreeNode<string>[], parentName?: string): string | undefined => {
      for (const node of nodes) {
        if (node.id === locationId) {
          return parentName ? `${parentName} / ${node.name}` : node.name;
        }

        if (node.children) {
          const found = findLocationPath(node.children, node.name);

          if (found) return found;
        }
      }
    };

    return findLocationPath(this.#locationsWithRegionsBranches() || []) || '';
  });

  ngOnInit(): void {
    this.#route.params
      .pipe(
        takeUntil(this.destroyed$),
        distinctUntilKeyChanged('recipeId'),
        tap((params: { recipeId: string }) => {
          this.#store.dispatch(new InventoryRecipeGet({ id: params.recipeId }));
        }),
      )
      .subscribe();
  }

  protected async closeDialog(): Promise<void> {
    const queryParams = this.#route.snapshot.queryParams;
    const currentUrl = this.#router.url;

    if (this.#detailSection()) {
      const baseUrl = currentUrl.split('/').slice(0, -1).join('/');

      await this.#router.navigate([baseUrl], {
        queryParams,
        queryParamsHandling: 'preserve',
      });

      return;
    }

    const baseUrl = `/${this.recipePlace()}/recipes`;

    await this.#router.navigate([baseUrl], {
      queryParams,
      queryParamsHandling: 'preserve',
    });
  }
}
