import { BaseRequestMetadata } from '@supy/common';

import {
  CreateInventoryWastageRequest,
  InventoryEventFilters,
  InventoryEventItemProps,
  InventoryEventRepeatStandardProps,
  InventoryWastageEventItem,
  UpdateInventoryWastageRequest,
  UploadInventoryEventAttachmentQueryParams,
} from '../../../core';

export class InventoryWastageGet {
  static readonly type = '[InventoryWastage] Get';
  constructor(public payload: { id: string; fromCache?: boolean }) {}
}

export class InventoryWastageRepeat {
  static readonly type = '[InventoryWastage] Repeat';
  constructor(public payload: { id: string; values: InventoryEventRepeatStandardProps }) {}
}
export class InventoryWastageRepeatSuccess {
  static readonly type = '[InventoryWastage] Repeat Success';
  constructor(public payload: { id: string; skippedItems: InventoryWastageEventItem[] }) {}
}

export class InventoryWastageGetMany {
  static readonly type = '[InventoryWastage] GetMany';
}

export class InventoryWastageListExport {
  static readonly type = '[InventoryWastage] ListExport';
}

export class InventoryWastageCreate {
  static readonly type = '[InventoryWastage] Create';
  constructor(readonly payload: CreateInventoryWastageRequest) {}
}

export class InventoryWastageUpdate {
  static readonly type = '[InventoryWastage] Update';
  constructor(readonly payload: UpdateInventoryWastageRequest) {}
}

export class InventoryWastageDelete {
  static readonly type = '[InventoryWastage] Delete';
  constructor(readonly id: string) {}
}

export class InventoryWastageInitFilters {
  static readonly type = '[InventoryWastage] InitFilters';
}

export class InventoryWastagePatchFilter {
  static readonly type = '[InventoryWastage] PatchFilter';
  constructor(public payload: Partial<InventoryEventFilters>) {}
}

export class InventoryWastageResetFilter {
  static readonly type = '[InventoryWastage] ResetFilter';
}

export class InventoryWastagePatchRequestMeta {
  static readonly type = '[InventoryWastage] PatchRequestMeta';
  constructor(public payload: Partial<BaseRequestMetadata>) {}
}

export class InventoryWastageSearch {
  static readonly type = '[InventoryWastage] Search';
  constructor(readonly searchTerm: string) {}
}

export class InventoryWastageGetItems {
  static readonly type = '[InventoryWastage] GetItems';

  constructor(readonly query: InventoryEventItemProps) {}
}

export class InventoryWastageResetSequence {
  static readonly type = '[InventoryWastage] ResetSequence';
}

export class InventoryWastageCheckExistence {
  static readonly type = '[InventoryWastage] CheckExistence';
}

export class InventoryWastageUploadAttachment {
  static readonly type = '[InventoryWastage] UploadAttachment';

  constructor(
    readonly payload: FormData,
    readonly queryParams: UploadInventoryEventAttachmentQueryParams,
  ) {}
}
