<supy-dialog
  position="center"
  initialState="open"
  (dialogClosed)="onDialogClosed($event)"
  (dialogClosing)="onDialogClosing($event)"
  (dialogOpened)="onDialogOpened($event)"
  (dialogOpening)="onDialogOpening($event)"
>
  <supy-dialog-header>
    <supy-icon (click)="dialog.closeDialog()" name="close" size="medium"></supy-icon>
    <h2 i18n="@@inventory.event.repeat.duplicate">Repeat {{ eventType | titlecase }} Event</h2>
  </supy-dialog-header>
  <supy-dialog-content [formGroup]="form">
    <div class="inventory-event-repeat">
      @if (eventType === inventoryEventType.Transfer) {
        <div class="inventory-event-drepeat__input-group">
          <small i18n="@@inventory.event.repeat.transferFrom">Transfer From*</small>
          <supy-dropdown-tree
            name="from-location"
            formControlName="fromLocation"
            i18n-placeholder="@@common.selectLocation"
            placeholder="Select Location"
            displayStrategy="path"
            [data]="locations"
            (valueChange)="onSelectToLocation($event)"
          ></supy-dropdown-tree>

          <ng-template #fromLocationInput>
            <supy-input formControlName="name" name="from-location"></supy-input>
          </ng-template>
        </div>

        <div class="inventory-event-repeat__input-group">
          <small i18n="@@inventory.event.repeat.transferTo">Transfer To*</small>
          <supy-dropdown-tree
            name="to-location"
            formControlName="toLocation"
            placeholder="Select Location"
            i18n-placeholder="@@common.selectLocation"
            displayStrategy="path"
            [data]="toLocations"
            (valueChange)="onSelectToLocation($event)"
          ></supy-dropdown-tree>

          <ng-template #toLocationInput>
            <supy-input formControlName="name" name="to-location"></supy-input>
          </ng-template>
        </div>
      } @else {
        <div class="inventory-event-repeat__input-group">
          <label>Location*</label>
          <supy-dropdown-tree
            name="location"
            formControlName="location"
            placeholder="Select Location"
            i18n-placeholder="@@common.selectLocation"
            displayStrategy="path"
            [data]="locations"
          ></supy-dropdown-tree>
        </div>
      }

      <div class="inventory-event-repeat__input-group">
        <p class="inventory-event-repeat__label--flex">
          <label i18n="@@inventory.event.repeat.eventDate">Event Date*</label>
          <supy-tooltip [content]="getRetailerTimeZoneHelperMessage(utcOffset)">
            <supy-icon name="info" size="small"></supy-icon>
          </supy-tooltip>
        </p>
        <supy-date-input
          formControlName="eventDate"
          displayFormat="dd MMMM yyyy"
          [ianaTimeZone]="ianaTimeZone"
          [minValue]="minDate"
          [maxValue]="maxDate"
        >
        </supy-date-input>
      </div>
      @if (eventType !== inventoryEventType.Transfer) {
        <div class="inventory-event-repeat__input-group">
          <label i18n="@@eventName">Event Name</label>
          <supy-input
            id="event-name"
            name="event-name"
            formControlName="name"
            i18n-placeholder="@@eventName"
            placeholder="Event Name"
          ></supy-input>
        </div>
      }
    </div>
  </supy-dialog-content>
  <supy-dialog-footer>
    <div class="inventory-event-repeat__submit">
      <supy-button (buttonClick)="dialog.closeDialog()" color="default-outline" name="cancel" i18n="@@cancel"
        >Cancel</supy-button
      >
      <supy-button
        name="createRepeat"
        (buttonClick)="onConfirmClick()"
        [disabled]="form.invalid"
        color="primary"
        i18n="@@createEvent"
      >
        Create Event
      </supy-button>
    </div>
  </supy-dialog-footer>
</supy-dialog>
