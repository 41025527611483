import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IDialogCancellableEventArgs, IDialogEventArgs } from '@infragistics/igniteui-angular';

import { Destroyable, InputValidators, StorageLocation, trackByProperty, ViewBranch } from '@supy/common';
import { DialogComponent, IDialogComponent } from '@supy/components';
import { Span } from '@supy/opentelemetry';

import { InventoryLocationGridItem } from '../../core';

@Component({
  selector: 'supy-add-location-dialog',
  templateUrl: './add-location-dialog.component.html',
  styleUrls: ['./add-location-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddLocationDialogComponent extends Destroyable implements OnInit, IDialogComponent {
  @Output() readonly dialogClosing = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogClosed = new EventEmitter<IDialogEventArgs>();
  @Output() readonly dialogOpening = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogOpened = new EventEmitter<IDialogEventArgs>();
  @Output() readonly submitted = new EventEmitter<InventoryLocationGridItem>();

  @Input() readonly branches: ViewBranch[] = [];

  @ViewChild(DialogComponent, { static: true }) readonly dialog: DialogComponent;

  form: UntypedFormGroup;
  locations: StorageLocation[] = [];
  protected readonly trackById = trackByProperty<ViewBranch>('id');

  ngOnInit(): void {
    this.form = new UntypedFormGroup(
      {
        branch: new UntypedFormGroup({
          id: new UntypedFormControl(null, Validators.required),
        }),
        storages: new UntypedFormControl([], Validators.required),
        price: new UntypedFormControl(null),
        quantity: new UntypedFormControl(null, Validators.min(0)),
        min: new UntypedFormControl(null, Validators.min(0)),
        max: new UntypedFormControl(null),
      },
      { validators: InputValidators.minMax },
    );
  }

  openDialog(): void {
    this.dialog.openDialog();
  }

  closeDialog(): void {
    this.dialog.closeDialog();
  }

  onDialogClosing(event: IDialogCancellableEventArgs): void {
    this.dialogClosing.emit(event);
  }

  onDialogClosed(event: IDialogEventArgs): void {
    this.dialogClosed.emit(event);
  }

  onDialogOpening(event: IDialogCancellableEventArgs): void {
    this.dialogOpening.emit(event);
  }

  onDialogOpened(event: IDialogEventArgs): void {
    this.dialogOpened.emit(event);
  }

  onBranchSelect(branchId: string): void {
    const branch = this.branches.find(({ id }) => id === branchId);

    this.locations = branch?.storageLocations ?? [];
  }

  onLocationsChange(storageIds: string[]): void {
    this.form.patchValue({
      storages: storageIds.map(id => ({
        id,
      })),
    });
  }

  @Span()
  onAddLocation(): void {
    if (this.form.valid) {
      this.submitted.emit(this.form.value as InventoryLocationGridItem);
    }
  }
}
