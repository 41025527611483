import { BaseSelectItem, SkeletonObjectType } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';

import { InventoryEvent, InventoryEventItem, InventoryEventSearchItem } from './inventory-event.entity';
import { InventoryTransferMode, InventoryTransferStateEnum } from './inventory-transfer-event.model';

export class InventoryTransferEvent extends InventoryEvent {
  readonly fromLocation: SkeletonObjectType;
  readonly toLocation: SkeletonObjectType;
  readonly items: InventoryTransferEventItem[];
  readonly state: InventoryTransferStateEnum;
  readonly deliveredOn: Date;
  readonly code: string;

  constructor(args: NonFunctionProperties<InventoryTransferEvent>) {
    super(args);

    this.items = args.items;
  }

  static getStatusesFilter(mode: InventoryTransferMode): BaseSelectItem[] {
    const incomingStatuses: BaseSelectItem[] = [
      { label: 'Submitted', value: InventoryTransferStateEnum.Submitted },
      { label: 'Received', value: InventoryTransferStateEnum.Received },
      { label: 'Archived', value: InventoryTransferStateEnum.Archived },
    ];

    switch (mode) {
      case InventoryTransferMode.Incoming:
        return incomingStatuses;
      case InventoryTransferMode.Outgoing:
        return [{ label: 'Draft', value: InventoryTransferStateEnum.Draft }, ...incomingStatuses];
      case InventoryTransferMode.Requested:
        return [
          { label: 'Requested', value: InventoryTransferStateEnum.Requested },
          { label: 'Rejected', value: InventoryTransferStateEnum.Rejected },
        ];
    }
  }
}

export class InventoryTransferEventItem extends InventoryEventItem {
  readonly isStockable: boolean;

  static default(): InventoryTransferEventItem {
    return { ...super.default(), isStockable: true };
  }

  static deserialize(args: NonFunctionProperties<InventoryTransferEventItem>): InventoryTransferEventItem {
    return new InventoryTransferEventItem({ ...args, ...InventoryEventItem.deserialize(args) });
  }

  static deserializeList(data: NonFunctionProperties<InventoryTransferEventItem>[]): InventoryTransferEventItem[] {
    return data.map(inventoryTransferEventItem => InventoryTransferEventItem.deserialize(inventoryTransferEventItem));
  }
}

export class InventoryTransferSearchItem extends InventoryEventSearchItem {
  readonly isStockable: boolean;

  constructor(args: NonFunctionProperties<InventoryTransferSearchItem>) {
    super(args);
    Object.assign(this, args);
  }

  static deserialize(data: NonFunctionProperties<InventoryTransferSearchItem>): InventoryTransferSearchItem {
    return new InventoryTransferSearchItem({ ...data });
  }

  static deserializeList(data: NonFunctionProperties<InventoryTransferSearchItem>[]): InventoryTransferSearchItem[] {
    return data.map(inventoryTransferSearchItem =>
      InventoryTransferSearchItem.deserialize(inventoryTransferSearchItem),
    );
  }

  static from(args: NonFunctionProperties<InventoryTransferEventItem>): InventoryTransferSearchItem {
    return InventoryTransferSearchItem.deserialize({
      ...InventoryEventSearchItem.from(args),
      isStockable: args.isStockable,
    });
  }
}

export class TransferItemGrid extends InventoryTransferEventItem {
  readonly item?: InventoryTransferSearchItem;

  constructor(args: NonFunctionProperties<TransferItemGrid>) {
    super(args);
    Object.assign(this, args);
  }

  static deserialize(args: NonFunctionProperties<TransferItemGrid>): TransferItemGrid {
    return new TransferItemGrid({ ...args });
  }

  static deserializeList(data: NonFunctionProperties<TransferItemGrid>[]): TransferItemGrid[] {
    return data.map(transferItemGrid => TransferItemGrid.deserialize(transferItemGrid));
  }

  static fromItem(item: NonFunctionProperties<InventoryTransferEventItem>): TransferItemGrid {
    return TransferItemGrid.deserialize({
      ...InventoryTransferEventItem.deserialize(item),
      item: InventoryTransferSearchItem.from(item),
    });
  }

  static fromItemList(items: NonFunctionProperties<InventoryTransferEventItem>[]): TransferItemGrid[] {
    return items.map(item => TransferItemGrid.fromItem(item));
  }
}
