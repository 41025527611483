import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IDialogCancellableEventArgs, IDialogEventArgs } from '@infragistics/igniteui-angular';

import { Destroyable } from '@supy/common';
import { DialogComponent, IDialogComponent } from '@supy/components';
import { Span } from '@supy/opentelemetry';

import { InventoryLocationGridItem, InventoryLocationLevels } from '../../core';

@Component({
  selector: 'supy-edit-location-dialog',
  templateUrl: './edit-location-dialog.component.html',
  styleUrls: ['./edit-location-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditLocationDialogComponent extends Destroyable implements OnInit, IDialogComponent {
  @Input() readonly location: InventoryLocationGridItem;

  @Output() readonly dialogClosing = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogClosed = new EventEmitter<IDialogEventArgs>();
  @Output() readonly dialogOpening = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogOpened = new EventEmitter<IDialogEventArgs>();

  @Output() readonly submitted = new EventEmitter<InventoryLocationGridItem>();

  @ViewChild(DialogComponent, { static: true }) readonly dialog: DialogComponent;

  form: UntypedFormGroup;
  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      min: new UntypedFormControl(this.location.min),
      max: new UntypedFormControl(this.location.max),
    });
  }

  openDialog(): void {
    this.dialog.openDialog();
  }

  closeDialog(): void {
    this.dialog.closeDialog();
  }

  onDialogClosing(event: IDialogCancellableEventArgs): void {
    this.dialogClosing.emit(event);
  }

  onDialogClosed(event: IDialogEventArgs): void {
    this.dialogClosed.emit(event);
  }

  onDialogOpening(event: IDialogCancellableEventArgs): void {
    this.dialogOpening.emit(event);
  }

  onDialogOpened(event: IDialogEventArgs): void {
    this.dialogOpened.emit(event);
  }

  @Span()
  onEditLocation(): void {
    const levels = this.form.value as InventoryLocationLevels;

    this.submitted.emit({ ...this.location, min: +levels.min, max: +levels.max });
  }
}
