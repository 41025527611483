import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { IQueryResponse, Query, removeEmpty } from '@supy/common';
import { SalesType } from '@supy/settings';

import { INVENTORY_RECIPE_BFF_URI } from '../../../config';
import {
  CostAdjustmentData,
  CostAdjustmentDetailResponse,
  costAdjustmentPayload,
  MinimumEffectiveDateResponse,
} from '../../../core';
import {
  AutocompleteFinishedRecipe,
  AutocompleteFinishedRecipesQueryProps,
  BackdateRecipeRequest,
  BulkUpdateRecipesCategoryRequest,
  BulkUpdateRecipesRequest,
  CloneRecipeRequest,
  CreateInventoryRecipeInventoryRequest,
  InventoryRecipe,
  InventoryRecipeBaseRequest,
  InventoryRecipeRequestProps,
  RecipeIngredientItem,
  RecipeIngredientItemsInput,
  RecipeStatement,
  RecipeStatisticsResponse,
  RecipeUpdateCostCenterRequest,
  RecipeUpdateLocationsRequest,
  ReplaceItemRequest,
  UpdateRecipeCookbookRequest,
  UpdateRecipeInventoryRequest,
} from '../core';

@Injectable({ providedIn: 'root' })
export class InventoryRecipeService {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(INVENTORY_RECIPE_BFF_URI) private readonly bffUri: string,
  ) {}

  getByIdBff(id: string, locationId?: string): Observable<InventoryRecipe> {
    const url = locationId ? `${this.bffUri}/${id}?locationId=${locationId}` : `${this.bffUri}/${id}`;

    return this.httpClient.get<InventoryRecipe>(url);
  }

  getManyBff(query: Query<InventoryRecipe & InventoryRecipeRequestProps>): Observable<IQueryResponse<InventoryRecipe>> {
    return this.httpClient.get<IQueryResponse<InventoryRecipe & InventoryRecipeRequestProps>>(this.bffUri, {
      params: new HttpParams({
        fromObject: query.toQueryParams(),
      }),
    });
  }

  create(body: InventoryRecipeBaseRequest): Observable<InventoryRecipe> {
    return this.httpClient.post<InventoryRecipe>(`${this.bffUri}`, body);
  }

  update(id: string, body: Partial<InventoryRecipeBaseRequest>): Observable<void> {
    return this.httpClient.patch<void>(`${this.bffUri}/${id}`, body);
  }

  updateCookbook(id: string, body: UpdateRecipeCookbookRequest): Observable<InventoryRecipe> {
    return this.httpClient.patch<InventoryRecipe>(`${this.bffUri}/${id}/cookbook`, body);
  }

  createInventory(id: string, body: CreateInventoryRecipeInventoryRequest): Observable<InventoryRecipe> {
    return this.httpClient.post<InventoryRecipe>(`${this.bffUri}/${id}/inventory`, body);
  }

  updateInventory(id: string, body: UpdateRecipeInventoryRequest): Observable<InventoryRecipe> {
    return this.httpClient.patch<InventoryRecipe>(`${this.bffUri}/${id}/inventory`, body);
  }

  markAsStock(id: string): Observable<InventoryRecipe> {
    return this.httpClient.patch<InventoryRecipe>(`${this.bffUri}/${id}/stock`, null);
  }

  getIngredientItems(params: RecipeIngredientItemsInput): Observable<IQueryResponse<RecipeIngredientItem>> {
    return this.httpClient.get<IQueryResponse<RecipeIngredientItem>>(`${this.bffUri}/ingredients`, {
      params: new HttpParams({
        fromObject: { ...removeEmpty<RecipeIngredientItemsInput>(params) },
      }),
    });
  }

  getStatisticsBff(locationId: string): Observable<RecipeStatisticsResponse> {
    return this.httpClient.get<RecipeStatisticsResponse>(`${this.bffUri}/${locationId}/stats`);
  }

  bulkArchiveBff(body: BulkUpdateRecipesRequest): Observable<void> {
    return this.httpClient.patch<void>(`${this.bffUri}/bulk/archive`, body);
  }

  bulkActivateBff(body: BulkUpdateRecipesRequest): Observable<void> {
    return this.httpClient.patch<void>(`${this.bffUri}/bulk/activate`, body);
  }

  bulkUpdateCategory(body: BulkUpdateRecipesCategoryRequest): Observable<void> {
    return this.httpClient.patch<void>(`${this.bffUri}/bulk/category`, body);
  }

  updateCostCenters(id: string, body: RecipeUpdateCostCenterRequest): Observable<void> {
    return this.httpClient.put<void>(`${this.bffUri}/${id}/cost-centers`, body);
  }

  updateLocations(id: string, body: RecipeUpdateLocationsRequest): Observable<void> {
    return this.httpClient.put<void>(`${this.bffUri}/${id}/locations`, body);
  }

  delete(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.bffUri}/${id}`);
  }

  publish(id: string, effectiveDate?: Date): Observable<void> {
    return this.httpClient.patch<void>(`${this.bffUri}/${id}/publish`, { effectiveDate });
  }

  getStatements(id: string, ids: string[]): Observable<RecipeStatement[]> {
    const params = new HttpParams({
      fromObject: {
        ids: JSON.stringify(ids),
      },
    });

    return this.httpClient.get<RecipeStatement[]>(`${this.bffUri}/${id}/statements`, {
      params,
      headers: new HttpHeaders({
        'X-Version': '2',
      }),
    });
  }

  clone(id: string, body?: CloneRecipeRequest): Observable<InventoryRecipe> {
    return this.httpClient.post<InventoryRecipe>(`${this.bffUri}/${id}/clone`, body);
  }

  replaceItem(body: ReplaceItemRequest): Observable<void> {
    return this.httpClient.put<void>(`${this.bffUri}/item/replace`, body);
  }

  autocompleteFinishedRecipes(
    params: AutocompleteFinishedRecipesQueryProps,
  ): Observable<IQueryResponse<AutocompleteFinishedRecipe>> {
    return this.httpClient.get<IQueryResponse<AutocompleteFinishedRecipe>>(`${this.bffUri}/autocomplete-finished`, {
      params: new HttpParams({ fromObject: { ...params } }),
    });
  }

  getMinimumEffectiveDate(recipeId: string): Observable<MinimumEffectiveDateResponse> {
    return this.httpClient.get<MinimumEffectiveDateResponse>(`${this.bffUri}/${recipeId}/effective-date`);
  }

  backdate(recipeId: string, body: BackdateRecipeRequest): Observable<void> {
    return this.httpClient.post<void>(`${this.bffUri}/${recipeId}/backdate`, body);
  }

  salesTypeByRecipeId(recipeId: string): Observable<SalesType[]> {
    return this.httpClient.get<SalesType[]>(`${this.bffUri}/sales-types/${recipeId}`);
  }

  getPDF(id: string): Observable<{ signedUrl: string }> {
    return this.httpClient.get<{ signedUrl: string }>(`${this.bffUri}/${id}/cookbook/download`);
  }

  getCostAdjustments(recipeId: string, payload: costAdjustmentPayload): Observable<IQueryResponse<CostAdjustmentData>> {
    const url = `${this.bffUri}/${recipeId}/statementChange`;
    const params = new HttpParams({ fromObject: payload as unknown as Record<string, string> });

    return this.httpClient.get<IQueryResponse<CostAdjustmentData>>(url, { params });
  }

  getCostAdjustmentsDetails(
    recipeId: string,
    payload: costAdjustmentPayload,
  ): Observable<CostAdjustmentDetailResponse> {
    const url = `${this.bffUri}/${recipeId}/statementDetails`;
    const params = new HttpParams({ fromObject: payload as unknown as Record<string, string> });

    return this.httpClient.get<CostAdjustmentDetailResponse>(url, { params });
  }
}
