import { getPackagingUnitPayload, PackagingUnit } from '@supy/packaging';

import { InventoryItemType } from '../../../core';
import { CreateIngredientRequest, InventoryIngredientNormalized, UpdateIngredientRequest } from '../core';

export function getCreateIngredientsPayload(
  newIngredients: InventoryIngredientNormalized[],
  oldIngredients: InventoryIngredientNormalized[] = [],
): CreateIngredientRequest[] {
  const idToOldIngredient = new Map<string, InventoryIngredientNormalized>(
    oldIngredients.map(ingredient => [ingredient.id ?? '', ingredient]),
  );

  return newIngredients.reduce<CreateIngredientRequest[]>((acc, cur) => {
    const oldIngredient = idToOldIngredient.get(cur.id ?? '');

    if ((!oldIngredient && cur.item) || (oldIngredient && oldIngredient.item?.id !== cur.item?.id)) {
      acc.push({
        includedInCost: !!cur.includedInCost,
        quantity: {
          net: cur.quantityNet ?? 0,
          wastagePercentage: cur.prepWastage ?? cur.item?.wastagePercentage ?? 0,
        },
        modifier: !!cur.modifier,
        unit: getPackagingUnitPayload(cur.packagingUnit ?? PackagingUnit.default(), cur.item?.packagings ?? []),
        scope: { referenceId: cur.item?.id ?? '', type: cur.item?.type ?? InventoryItemType.Item },
      });
    }

    return acc;
  }, []);
}

export function getUpdateIngredientsPayload(
  newIngredients: InventoryIngredientNormalized[],
  oldIngredients: InventoryIngredientNormalized[],
): UpdateIngredientRequest[] {
  const idToOldIngredient = new Map<string, InventoryIngredientNormalized>(
    oldIngredients.map(ingredient => [ingredient.id ?? '', ingredient]),
  );

  return newIngredients.reduce<UpdateIngredientRequest[]>((acc, cur) => {
    const oldIngredient = idToOldIngredient.get(cur.id ?? '');

    if (oldIngredient && oldIngredient.item?.id === cur.item?.id) {
      const hasChanged =
        oldIngredient.modifier !== cur.modifier ||
        oldIngredient.includedInCost !== cur.includedInCost ||
        oldIngredient.quantityNet !== cur.quantityNet ||
        oldIngredient.prepWastage !== cur.prepWastage ||
        JSON.stringify(oldIngredient.packagingUnit) !== JSON.stringify(cur.packagingUnit);

      if (hasChanged) {
        acc.push({
          id: cur.id ?? '',
          scope: { referenceId: cur.item?.id ?? '', type: cur.item?.type ?? InventoryItemType.Item },
          modifier: !!cur.modifier,
          includedInCost: !!cur.includedInCost,
          quantity: {
            net: cur.quantityNet ?? 0,
            wastagePercentage: cur.prepWastage ?? cur.item?.wastagePercentage ?? 0,
          },
          unit: getPackagingUnitPayload(cur.packagingUnit ?? PackagingUnit.default(), cur.item?.packagings ?? []),
        });
      }
    }

    return acc;
  }, []);
}
export function getDeleteIngredientsPayload(
  newIngredients: InventoryIngredientNormalized[],
  oldIngredients: InventoryIngredientNormalized[],
): string[] {
  return oldIngredients.reduce<string[]>((acc, cur) => {
    const existingOldIngredient = newIngredients.find(ingredient => ingredient.id === cur.id);

    if (!existingOldIngredient || existingOldIngredient.item?.id !== cur.item?.id) {
      acc.push(cur.id ?? '');
    }

    return acc;
  }, []);
}
