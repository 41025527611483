import { getApiDetailsDecorator } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';
import { PackagingUnit } from '@supy/packaging';

import { InventoryStockCountPackagingResponse } from './stock-count.model';

const ApiProperty = getApiDetailsDecorator<InventoryStockCountPackagingResponse>();

export const StockCountPackagingRowType = 'packaging';

export class InventoryStockCountPackaging {
  private constructor(args: NonFunctionProperties<InventoryStockCountPackaging>) {
    this.id = args.id;
    this.itemRowId = args.itemRowId;
    this.name = args.name;
    this.level = args.level;
    this.partialCount = args.partialCount;
    this.initialPartialCount = args.initialPartialCount;
    this.totalCount = args.totalCount;
    this.isDefault = args.isDefault;
    this.externalTotalCount = args.externalTotalCount;
    this.unit = args.unit;
    this.rowId = args.rowId;
    this.rowType = args.rowType;
    this.packageName = args.packageName;
  }

  @ApiProperty() readonly id: string;
  @ApiProperty() readonly name: string;
  @ApiProperty() readonly level: number;
  @ApiProperty() partialCount: number;
  @ApiProperty() totalCount: number;
  @ApiProperty() readonly isDefault: boolean;
  @ApiProperty() readonly unit: PackagingUnit;
  @ApiProperty() readonly packageName: string;
  readonly initialPartialCount: number;
  readonly externalTotalCount: number;
  readonly itemRowId: string;
  readonly rowId: string;
  readonly rowType: string;

  static deserialize(
    data: InventoryStockCountPackagingResponse,
    { itemRowId }: DeserializedArgs,
  ): InventoryStockCountPackaging {
    const totalCount = data.totalCount ?? 0;
    const partialCount = data.partialCount;

    return new InventoryStockCountPackaging({
      id: data.id,
      itemRowId,
      name: data.name,
      level: data.level,
      partialCount,
      isDefault: data.isDefault,
      initialPartialCount: partialCount,
      totalCount,
      externalTotalCount: partialCount === totalCount ? 0 : totalCount - partialCount,
      unit: data.unit,
      rowId: crypto.randomUUID().slice(0, 6),
      rowType: StockCountPackagingRowType,
      packageName: data.packageName,
    });
  }
}

interface DeserializedArgs {
  readonly itemRowId: string;
}
