import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { SupyCommonModule } from '@supy/common';
import { ComboboxModule, GridModule, InputModule } from '@supy/components';

import { PriceHistoryGridComponent, StockMovementGridComponent } from './components';

const COMPONENTS = [StockMovementGridComponent, PriceHistoryGridComponent];

@NgModule({
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  imports: [CommonModule, SupyCommonModule, ReactiveFormsModule, GridModule, InputModule, ComboboxModule],
})
export class InventoryStocksModule {}
