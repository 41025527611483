import { Pipe, PipeTransform } from '@angular/core';

import { Packaging } from '../../core';

@Pipe({
  name: 'supyPackageLevel',
})
export class PackageLevelPipe implements PipeTransform {
  transform(packageItems: Packaging[], level: Packaging['level']): Packaging[] {
    return packageItems.filter(item => item.level === level);
  }
}
