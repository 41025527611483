import { ChangeDetectionStrategy, Component, effect, inject, Injector, input, OnInit, output } from '@angular/core';

import {
  DropdownTreeNode,
  FilterChange,
  FilterGroup,
  FiltersModule,
  getWeeksQuartersRange,
  SelectType,
} from '@supy/components';

import { costAdjustmentPayload, InventoryRecipeType, RepositoryRecipeLocation } from '../../..';
import { CostAdjustmentsGridMappedFilters, getCostAdjustmentsGridFiltersConfig } from './filters-config';

@Component({
  selector: 'supy-cost-adjustments-grid-filters',
  templateUrl: './cost-adjustments-grid-filters.component.html',
  styleUrls: ['./cost-adjustments-grid-filters.component.scss'],
  standalone: true,
  imports: [FiltersModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CostAdjustmentsGridFiltersComponent implements OnInit {
  readonly #injector = inject(Injector);

  protected readonly retailerId = input<string | null>(null);
  protected readonly locations = input<RepositoryRecipeLocation[]>([]);
  protected readonly locationsWithRegionsBranches = input<DropdownTreeNode<string>[]>([]);
  protected readonly recipePlace = input<'repository' | 'inventory'>('inventory');
  protected readonly recipeType = input<InventoryRecipeType | null>(null);
  protected readonly activeSalesTypes = input<{ id: string; name: string }[]>([]);
  protected readonly defaultLocationId = input<string | null>(null);
  protected readonly filtersChanged = output<Partial<costAdjustmentPayload>>();
  protected readonly filtersCleared = output<void>();

  protected filtersGroup = new FilterGroup<FilterChange<CostAdjustmentsGridMappedFilters>>(
    getCostAdjustmentsGridFiltersConfig(),
  );

  protected readonly predefinedDateRanges = getWeeksQuartersRange();

  ngOnInit(): void {
    this.patchLocationsFilter();
    this.patchSalesTypesFilter();
  }

  protected onFiltersChange({
    dateRange,
    locationId,
    salesTypeId,
  }: FilterChange<CostAdjustmentsGridMappedFilters>): void {
    this.filtersChanged.emit({
      dateFrom: dateRange?.start ? new Date(dateRange.start).toISOString() : null,
      dateTo: dateRange?.end ? new Date(dateRange.end).toISOString() : null,
      locationId: locationId,
      salesTypeId: salesTypeId,
    });
  }

  private patchSalesTypesFilter(): void {
    effect(
      () => {
        if (this.recipeType() === InventoryRecipeType.Finished && this.activeSalesTypes()) {
          this.filtersGroup.updateSelectionProperty('salesTypeId', {
            options: this.activeSalesTypes(),
            value: this.activeSalesTypes()?.at(0)?.id,
            name: 'salesTypeId',
            placeholder: $localize`:@@common.filters.salesType:Sales Type`,
            selectType: SelectType.DropdownTree,
            width: '20rem',
            multipleSelectionStrategy: 'node',

            clearable: false,
          });
        }
      },
      {
        injector: this.#injector,
      },
    );
  }

  private patchLocationsFilter(): void {
    effect(
      () => {
        if (this.recipePlace() === 'repository') {
          const locations = this.locations();
          const regionsTree = this.locationsWithRegionsBranches();

          if (!locations || !regionsTree) return;

          const filteredTree = regionsTree.map(node => this.filterTreeNode(node, locations));

          this.filtersGroup.updateSelectionProperty('locationId', {
            options: filteredTree.filter(node => node !== null),
            value: this.defaultLocationId() ?? locations[0]?.locationId,

            name: 'locationId',
            placeholder: $localize`:@@common.filters.location:Location`,
            selectType: SelectType.DropdownTree,
            width: '20rem',
            returnStrategy: 'key',
            displayStrategy: 'path',
            multipleSelectionStrategy: 'node',
            clearable: false,
          });
        }
      },
      {
        injector: this.#injector,
      },
    );
  }

  private filterTreeNode(
    node: DropdownTreeNode<string>,
    locations: RepositoryRecipeLocation[],
  ): DropdownTreeNode<string> | null {
    const hasMatchingLocation =
      locations.some(loc => loc.locationId === node.id) || node.id === this.defaultLocationId();

    if (node.children) {
      const filteredChildren = node.children
        .map(child => this.filterTreeNode(child, locations))
        .filter((child): child is DropdownTreeNode<string> => child !== null);

      if (hasMatchingLocation || filteredChildren.length > 0) {
        return {
          ...node,
          children: filteredChildren.length > 0 ? filteredChildren : undefined,
        };
      }
    } else if (hasMatchingLocation) {
      return node;
    }

    return null;
  }
}
