import { Packaging, PackagingGroup, PackagingUnit, PackagingUnitRequest, PackagingUnitScope } from '../core';

export function getUsedAsPiecePackage<T extends { packages: PackagingGroup[] }>(item: T): Packaging | undefined {
  return item.packages.flatMap(({ items }) => items).find(({ usedAsPiece }) => usedAsPiece);
}

export function getUsedAsPiecePackagingUnit(packagings: PackagingUnit[]): PackagingUnit | undefined {
  return packagings.find(({ isPiece }) => isPiece);
}

export function getPreferredPackaging(packagings: PackagingUnit[]): PackagingUnit | undefined {
  return packagings.find(({ isPreferred }) => isPreferred);
}

export function getPackagingUnitPayload(
  packagingUnit: PackagingUnit,
  packagings: PackagingUnit[],
): PackagingUnitRequest {
  let payload: PackagingUnitRequest;

  if (packagingUnit.isPiece && getUsedAsPiecePackagingUnit(packagings)) {
    payload = {
      id: getUsedAsPiecePackagingUnit(packagings)?.packagingId ?? '',
      type: PackagingUnitScope.Packaging,
    };
  } else if (packagingUnit.packagingId) {
    payload = { id: packagingUnit.packagingId, type: PackagingUnitScope.Packaging };
  } else {
    payload = { id: packagingUnit.uomId, type: PackagingUnitScope.Uom };
  }

  return payload;
}
