import { of, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Action, createSelector, Selector, State, StateContext, StateToken } from '@ngxs/store';

import { Uom, UomType } from '@supy/common';
import { DropdownTreeNode } from '@supy/components';

import { UomService } from '../../services';
import { GetUoms, GetUomsAsTree } from '../actions';

interface UomsStoreState {
  readonly uoms: Uom[];
  readonly uomsAsTree: DropdownTreeNode<string>[];
}

const UOMS_STATE_TOKEN = new StateToken<UomsStoreState>('uoms');

@State<UomsStoreState>({
  name: UOMS_STATE_TOKEN,
  defaults: { uoms: [], uomsAsTree: [] },
})
@Injectable()
export class UomsState {
  constructor(private readonly uomsService: UomService) {}

  @Selector()
  static uoms(state: UomsStoreState) {
    return state.uoms;
  }

  @Selector()
  static baseUoms(state: UomsStoreState) {
    return state.uoms.filter(uom => uom.type === UomType.Base);
  }

  @Selector()
  static uomsAsTree(state: UomsStoreState) {
    return state.uomsAsTree;
  }

  @Selector()
  static pieceUom(state: UomsStoreState) {
    return state.uoms.find(({ isPiece }) => isPiece);
  }

  @Selector()
  static kgUom(state: UomsStoreState) {
    return state.uoms.find(({ name }) => name.toLowerCase() === 'kg');
  }

  static uomValidator(sheetValues: string[][], colIndex?: number) {
    return createSelector([UOMS_STATE_TOKEN], (state: UomsStoreState) => {
      return (_: AbstractControl): ValidationErrors | null => {
        const uomList = state.uoms.map(uom => uom.name.toLowerCase());
        const uomValues = sheetValues.map(row => row[colIndex ?? 5]);

        const column = uomValues.shift() ?? 'unit';

        for (let i = 0; i < uomValues.length; i++) {
          if (uomValues[i] && !uomList.includes(uomValues[i].toLowerCase())) {
            return { uomValidator: `The ${column} column has an invalid value at row ${i + 2}` };
          }
        }

        return null;
      };
    });
  }

  static uomById(uomId: string) {
    return createSelector([UOMS_STATE_TOKEN], (state: UomsStoreState) => {
      return state.uoms.find(({ id }) => id === uomId);
    });
  }

  @Action(GetUoms)
  getUoms(ctx: StateContext<UomsStoreState>, { forceFetch }: GetUoms) {
    const uoms = ctx.getState().uoms;

    return uoms.length && !forceFetch
      ? of(uoms)
      : this.uomsService.getUoms().pipe(tap(uoms => ctx.patchState({ uoms })));
  }

  @Action(GetUomsAsTree)
  getUomsAsTree(ctx: StateContext<UomsStoreState>, { forceFetch }: GetUomsAsTree) {
    const uomsAsTree = ctx.getState().uomsAsTree;

    return uomsAsTree.length && !forceFetch
      ? of(uomsAsTree)
      : this.uomsService
          .getUomsAsTree()
          .pipe(
            tap(uomsAsTree =>
              ctx.patchState({ uomsAsTree: uomsAsTree.map(node => ({ ...node, unselectable: true })) }),
            ),
          );
  }
}
