<div class="inventory-event-header">
  <supy-filter-group
    [filterGroup]="filterGroup"
    [predefinedDateRanges]="predefinedRanges"
    (filtersReset)="onClearFilters()"
  >
  </supy-filter-group>
  <div class="inventory-event-header__actions">
    <supy-dropdown-toggle
      [dropdownRef]="supyDropdown"
      position="bottom-left"
      class="inventory-event-header__dropdown"
      action
    >
      <supy-button
        class="inventory-event-header__action"
        type="button"
        color="secondary"
        name="actions"
        [disabled]="!hasData"
      >
        <span i18n="@@actions">Actions</span>
        <supy-icon name="arrow-down"></supy-icon>
      </supy-button>
    </supy-dropdown-toggle>
    <supy-dropdown #supyDropdown action>
      <supy-dropdown-item [disabled]="!hasData" (click)="onExport()">
        <span i18n="@@common.actions.export">Export</span>
      </supy-dropdown-item>
    </supy-dropdown>
    <supy-button
      *ngIf="canCreate"
      type="button"
      color="primary"
      [name]="eventType"
      routerLink="create"
      queryParamsHandling="preserve"
    >
      <supy-icon name="plus-flat" size="small"></supy-icon>
      <span i18n="@@common.actions.createEntity">Create {{ eventType | titlecase }}</span>
    </supy-button>
  </div>
</div>
