import { formatDate, TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IANATimezone } from '@supy.api/dictionaries';

import { getDateInTimeZone, InventoryEventType, ViewBranch } from '@supy/common';

import {
  InventoryEvent,
  InventoryEventStateEnum,
  InventoryTransferEvent,
  InventoryTransferMode,
  InventoryTransferStateEnum,
} from '../../core';

@Component({
  selector: 'supy-inventory-event-details-header',
  templateUrl: './inventory-event-details-header.component.html',
  styleUrls: ['./inventory-event-details-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryEventDetailsHeaderComponent<T extends InventoryEvent> {
  @Input() readonly selectedEvent: T;
  @Input() readonly eventType: InventoryEventType;
  @Input() readonly previousDisabled: boolean;
  @Input() readonly nextDisabled: boolean;
  @Input() readonly canDelete: boolean;
  @Input() readonly canDownload: boolean;
  @Input() readonly ianaTimeZone: IANATimezone;
  @Input() readonly isRequest: boolean;
  @Input() readonly idToUserLocation: Map<string, ViewBranch>;
  @Input() readonly eventState: InventoryEventStateEnum | InventoryTransferStateEnum;
  @Input() readonly mode: InventoryTransferMode;

  @Output() readonly deleteClicked = new EventEmitter<string>();
  @Output() readonly downloadClicked = new EventEmitter<string>();
  @Output() readonly archiveClicked = new EventEmitter<string>();
  @Output() readonly navigationClicked = new EventEmitter<boolean>();
  @Output() readonly repeatClicked = new EventEmitter<void>();

  protected readonly inventoryTransferStateEnum = InventoryTransferStateEnum;
  protected readonly inventoryEventStateEnum = InventoryEventStateEnum;
  protected readonly inventoryEventType = InventoryEventType;
  protected readonly inventoryTransferMode = InventoryTransferMode;

  get eventTitle(): string {
    if (!this.selectedEvent) {
      return `${this.isRequest ? 'Request' : 'Create'} ${this.titleCasePipe.transform(this.eventType)}`;
    }

    if (this.selectedEvent.eventDate) {
      const dateInRetailerTimeZone = getDateInTimeZone(this.selectedEvent.eventDate, this.ianaTimeZone);
      const formattedEventDate = formatDate(dateInRetailerTimeZone, 'dd MMMM yyyy', 'en-GB');

      if (this.eventType === InventoryEventType.Transfer) {
        return `${formattedEventDate} - ${(this.selectedEvent as unknown as InventoryTransferEvent).code}`;
      }

      return `${formattedEventDate} - ${this.selectedEvent.name ?? ''}`;
    }
  }

  protected get eventStatus(): string {
    return this.eventType === InventoryEventType.Transfer
      ? this.getTransferStatus(this.selectedEvent as unknown as InventoryTransferEvent)
      : this.selectedEvent.state;
  }

  protected getTransferStatus(transfer: InventoryTransferEvent): string {
    switch (transfer.state) {
      case InventoryTransferStateEnum.Requested:
        return this.idToUserLocation.has(transfer.fromLocation.id)
          ? InventoryTransferMode.Incoming
          : InventoryTransferMode.Outgoing;

      default:
        return transfer.state;
    }
  }

  constructor(private readonly titleCasePipe: TitleCasePipe) {}
}
