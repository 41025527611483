import { capital } from 'case';
import { IANATimezone } from '@supy.api/dictionaries';

import {
  createWorksheet,
  DataColumn,
  DEFAULT_IANA_TIMEZONE,
  getUTCOverriddenDateTime,
  saveWorkbook,
} from '@supy/common';
import { getLocalizedName, TaxRate } from '@supy/settings';

import { computedSellingPriceExclVat, InventoryRecipe, InventoryRecipeType } from '../core';

export async function downloadInventoryRecipesList(
  items: InventoryRecipe[],
  options: { locationId?: string; currency: string; taxes?: TaxRate[] },
  ianaTimeZone: IANATimezone = DEFAULT_IANA_TIMEZONE,
): Promise<void> {
  const columns: DataColumn[] = [
    { header: 'Recipe Code', key: 'code' },
    { header: 'Recipe Name', key: 'name' },
    { header: `Category`, key: 'category' },
    { header: 'Type', key: 'type' },
    { header: 'Stockable', key: 'isStockable' },
    { header: 'Locations', key: 'locationCount' },
    { header: 'Ingredients', key: 'ingredientCount' },
    { header: 'Revenue Tracking Only', key: 'isForRevenueTracking' },
    { header: 'Portion Size', key: 'portionSize' },
    { header: 'Portion Unit', key: 'portionUnit' },
    { header: 'Status', key: 'state' },
    { header: 'Created By', key: 'createdBy' },
    { header: 'Created On', key: 'createdAt' },
  ];

  if (options.locationId) {
    columns.splice(
      7,
      0,
      ...[
        { header: `Cost (${options.currency})`, key: 'cost' },
        { header: 'Food Cost %', key: 'foodCost' },
        { header: 'Selling Price', key: 'sellingPrice' },
      ],
    );
  }

  const currencyCols = new Set(['cost', 'sellingPrice']);
  const percentCols = new Set(['foodCost']);
  const qtyCols = new Set(['portionSize']);
  const dateCols = new Set(['createdAt']);
  const { Workbook } = (await import('exceljs')).default;
  const workbook = new Workbook();

  const data = items.map(item => {
    return {
      ...item,
      code: item.code,
      name: getLocalizedName(item.name),
      category: item.category?.name,
      type: item.type === InventoryRecipeType.Finished ? 'Finished' : 'Semi-Finished',
      locationCount: item.locations.length,
      ingredientCount: item.ingredientCount,
      portionSize: item.portionSize?.size,
      portionUnit: item.portionSize?.uom.name,
      state: capital(item.state ?? ''),
      createdAt: item.createdAt && getUTCOverriddenDateTime(new Date(item.createdAt), ianaTimeZone),
      createdBy: `${item.createdBy?.firstName} ${item.createdBy?.lastName}`,
      cost: (options.locationId && getRecipeCost(item, options.locationId)) || 0,
      foodCost: getFoodCost(item, options.locationId, options.taxes),
      sellingPrice: getSellingPrice(item, options.locationId ?? ''),
    };
  });

  await createWorksheet(workbook, `Recipes`, { data, columns, qtyCols, dateCols, currencyCols, percentCols });

  const fileName = `recipes-${Date.now()}.xlsx`;

  saveWorkbook(workbook, fileName);
}

function getFoodCost(recipe: InventoryRecipe, locationId: string, taxes?: TaxRate[]): number {
  const sellingPriceWithoutTaxes = getSellingPriceWithoutTaxes(recipe, locationId, taxes);

  return sellingPriceWithoutTaxes ? getRecipeCost(recipe, locationId) / sellingPriceWithoutTaxes : 0;
}

function getRecipeCost(recipe: InventoryRecipe, locationId: string): number {
  const recipeLocation = recipe.locations.find(location => location.locationId === locationId);

  return (recipeLocation?.cost ?? 0) * (recipe?.baseUnit?.conversionToAtom ?? 1) * (recipe?.portionSize?.size ?? 1);
}

function getSellingPrice(recipe: InventoryRecipe, locationId: string): number {
  return recipe.costCenters?.find(({ location }) => location.id === locationId)?.sellingPrice ?? 0;
}

function getSellingPriceWithoutTaxes(recipe: InventoryRecipe, locationId: string, taxes?: TaxRate[]): number {
  const costCenter = recipe.costCenters.find(({ location }) => location.id === locationId);
  const recipeTaxIds = new Set<string>((costCenter?.taxes ?? []).map(({ id }) => id));
  const recipeTotalTaxes = (taxes ?? []).reduce<number>(
    (acc, cur) => acc + (recipeTaxIds.has(cur.id) ? cur.rate : 0),
    0,
  );

  return computedSellingPriceExclVat(recipeTotalTaxes, getSellingPrice(recipe, locationId));
}
