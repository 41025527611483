import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';

import { InventoryVarianceReportExport } from '../../core';
import { InventoryVarianceReportService } from '../../services/variance-report.service';
import { GetInventoryVarianceFullReport } from '../actions/inventory-variance-report.actions';

export interface InventoryVarianceReportStateModel {
  report: InventoryVarianceReportExport;
}

const INVENTORY_VARIANCE_REPORT_STATE_TOKEN = new StateToken<InventoryVarianceReportStateModel[]>(
  'inventoryVarianceReport',
);

@State<InventoryVarianceReportStateModel>({
  name: INVENTORY_VARIANCE_REPORT_STATE_TOKEN,
  defaults: {
    report: undefined,
  },
})
@Injectable()
export class InventoryVarianceReportState {
  constructor(private readonly inventoryVarianceReportService: InventoryVarianceReportService) {}

  @Selector()
  static report(state: InventoryVarianceReportStateModel) {
    return state.report;
  }

  @Action(GetInventoryVarianceFullReport)
  getFullReport(
    ctx: StateContext<InventoryVarianceReportStateModel>,
    { stockCountId }: GetInventoryVarianceFullReport,
  ) {
    return this.inventoryVarianceReportService.getReport(stockCountId).pipe(
      tap(report => {
        ctx.patchState({
          report,
        });
      }),
    );
  }
}
