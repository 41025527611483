import {
  CellClassParams,
  CellClickedEvent,
  ColDef,
  ICellRendererParams,
  RowClassParams,
  ValueFormatterParams,
  ValueGetterParams,
} from '@ag-grid-community/core';
import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  Injector,
  input,
  output,
  signal,
  ViewChild,
} from '@angular/core';

import { PrecisionPipe } from '@supy/common';
import { ActionsCellRendererComponent, GridPocComponent, GridPocModule } from '@supy/components';
import { Writable } from '@supy/core';

import {
  CostAdjustmentGroup,
  CostAdjustmentsGroupRowType,
  CostAdjustmentToggleRow,
  CostAdjustmentToggleRowParams,
  DetailRow,
  ToggleDetailsEvent,
} from '../../../../../core';

type RowDataType = Writable<CostAdjustmentGroup> | DetailRow;

@Component({
  selector: 'supy-cost-adjustments-grid',
  templateUrl: './cost-adjustments-grid.component.html',
  styleUrls: ['./cost-adjustments-grid.component.scss'],
  standalone: true,
  imports: [GridPocModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DatePipe, PrecisionPipe],
})
export class CostAdjustmentsGridComponent {
  @ViewChild(GridPocComponent) private readonly grid: GridPocComponent;

  readonly #injector = inject(Injector);
  readonly #datePipe = inject(DatePipe);
  readonly #precision = inject(PrecisionPipe);

  protected readonly currencyPrecision = input<number | null>(null);
  protected readonly currencySymbol = input<string | null>(null);
  protected readonly expandedRowId = input<string>(null);
  protected readonly data = input<(CostAdjustmentGroup | DetailRow)[]>([]);

  protected readonly toggleRow = output<CostAdjustmentToggleRowParams>();
  protected readonly toggleDetails = output<ToggleDetailsEvent>();
  protected readonly loadingRowId = input<string | null>(null);
  protected readonly columnDefs = signal<ColDef<RowDataType>[]>([]);

  protected readonly getRowClass: (params: RowClassParams<CostAdjustmentGroup>) => string | string[] = ({
    data,
  }: RowClassParams<CostAdjustmentGroup>) => (this.isGroupRow(data) ? 'supy-cost-adjustments-grid--group' : '');

  protected readonly defaultColDefs: ColDef<CostAdjustmentGroup> = {
    cellStyle: { border: 'none' },
  };

  protected setColumnDefs(): void {
    effect(
      () => {
        this.columnDefs.set([
          {
            field: 'date',
            headerName: $localize`:@@common.date:Date`,
            flex: 1,
            valueGetter: ({ data }) => (this.isGroupRow(data) ? this.#datePipe.transform(data.date, 'dd/MM/yyyy') : ''),
          },
          {
            field: 'details',
            headerName: $localize`:@@common.details:Details`,
            flex: 2,
            valueGetter: ({ data }: ValueGetterParams<RowDataType>) => (this.isGroupRow(data) ? '' : data.name),
            onCellClicked: (event: CellClickedEvent<RowDataType>) => {
              const { data } = event;

              let parentDate: string | undefined = undefined;

              if ('recipeId' in data) {
                const parent = this.data().find(
                  (row: CostAdjustmentGroup) => row.id === data.parentId,
                ) as CostAdjustmentGroup;

                parentDate = parent?.date as unknown as string;
              }

              if (!this.isGroupRow(data)) {
                this.toggleDetails.emit({
                  type: data.type,
                  itemId: 'itemId' in data ? data.itemId : undefined,
                  recipeId: 'recipeId' in data ? data.recipeId : undefined,
                  date: parentDate,
                });
              }
            },
            cellClass: 'supy-cost-adjustments-grid-url',
          },
          {
            field: 'cost',
            headerName: $localize`:@@common.costSymbol:Cost (${this.currencySymbol()})`,
            flex: 1,
            valueGetter: ({ data }: ValueGetterParams<RowDataType>) => {
              if (data.rowType === CostAdjustmentsGroupRowType || data.rowType === 'ingredient') {
                const value = data.cost ?? 0;
                const transformed = this.#precision.transform(value, this.currencyPrecision() + 1);

                return transformed;
              }

              return null;
            },
          },
          {
            field: 'percentage',
            headerName: '% Change',
            flex: 1,
            valueGetter: ({ data }: ValueGetterParams<RowDataType>) => {
              if (data.rowType === CostAdjustmentsGroupRowType || data.rowType === 'ingredient') {
                const value = data.percentage ?? 0;
                const transformed = this.#precision.transform(value, this.currencyPrecision());

                return transformed;
              }

              return null;
            },
            valueFormatter: ({ value }: ValueFormatterParams) =>
              value == null ? '' : `${value > 0 ? '+' : ''}${value}%`,
            cellClassRules: {
              'supy-cost-adjustments-grid-positive': ({ value }: CellClassParams) => value < 0,
              'supy-cost-adjustments-grid-negative': ({ value }: CellClassParams) => value > 0,
            },
          },
          {
            field: 'updatedAt',
            headerName: 'Updated At',
            flex: 1,
            valueGetter: ({ data }) =>
              this.isGroupRow(data) ? this.#datePipe.transform(data.updatedAt, 'hh:mma, dd/MM/yyyy') : '',
          },
          {
            headerName: '',
            flex: 0.25,
            cellRenderer: ActionsCellRendererComponent,
            cellRendererParams: ({ data }: ICellRendererParams<CostAdjustmentGroup>) => ({
              context: {
                actions: this.isGroupRow(data) && [
                  {
                    icon:
                      this.loadingRowId() === data.id
                        ? 'loading'
                        : this.expandedRowId() === data.id
                          ? 'arrow-up'
                          : 'arrow-down',
                    callback: (rowData: CostAdjustmentGroup) =>
                      this.isGroupRow(rowData) ? this.onToggleCostAdjustmentsGroupRow(rowData) : null,
                  },
                ],
              },
            }),
          },
        ]);
      },
      {
        injector: this.#injector,
        allowSignalWrites: true,
      },
    );
  }

  private onToggleCostAdjustmentsGroupRow(groupRow: Writable<CostAdjustmentToggleRow>): void {
    if (!this.isGroupRow(groupRow)) {
      return;
    }
    groupRow.isExpanded = !groupRow.isExpanded;

    this.toggleRow.emit({
      date: groupRow.date,
      groupRowId: groupRow.id,
      previouslyExpanded: !!groupRow?.detailsData,
    });

    this.grid?.api?.refreshCells();
  }

  private isGroupRow(data: RowDataType): data is Writable<CostAdjustmentGroup> {
    return data.rowType === CostAdjustmentsGroupRowType;
  }
}
