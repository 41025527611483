import { TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { Router } from '@angular/router';

import { IconModule, TooltipModule } from '@supy/components';
import { getLocalizedName } from '@supy/settings';

import { InventoryRecipeAncestor } from '../../core';

@Component({
  selector: 'supy-recipe-parent-details',
  standalone: true,
  templateUrl: './recipe-parent-details.component.html',
  styleUrl: './recipe-parent-details.component.scss',
  imports: [TitleCasePipe, TooltipModule, IconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecipeParentDetailsComponent {
  readonly #router = inject(Router);

  protected readonly getLocalizedName = getLocalizedName;
  protected readonly parentRecipes = input<InventoryRecipeAncestor[]>([]);
  protected readonly locationType = input<'inventory' | 'repository'>();
  protected readonly locationId = input<string>();
  protected readonly parentRecipeTooltipMessage = $localize`:@@recipes.details.parentRecipeTooltip:This list includes all parent recipes at every level (direct and indirect). Any updates to the cost of this recipe will automatically impact the costs of these parent recipes.`;

  protected onRecipeClick(recipeId: string): void {
    const url = this.#router.serializeUrl(
      this.#router.createUrlTree([`/${this.locationType()}/recipes`, recipeId, 'details'], {
        queryParams: { locationId: this.locationId() ?? '' },
      }),
    );

    window.open(url, '_blank');
  }
}
