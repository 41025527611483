import { TabItem } from '@supy/components';

export enum RecipeActionDrawerTabs {
  AuditLogs = 'audit-log',
  CostAdjustments = 'cost-adjustments',
}

export function getRecipeActionDrawerTabs(
  recipePlace: string,
  recipeId: string,
  detailSection?: boolean,
  locationIds: string[] = [],
): TabItem[] {
  const prefix = `/${recipePlace}/recipes/${recipeId}${detailSection ? '/details' : ''}`;
  const tabs: TabItem[] = [
    {
      label: $localize`:@@actionDrawer.tabs.auditLogs: Audit Logs`,
      path: `${prefix}/${RecipeActionDrawerTabs.AuditLogs}`,
    },
  ];

  if (locationIds.length) {
    tabs.push({
      label: $localize`:@@actionDrawer.tabs.costAdjustments: Cost Adjustments`,
      path: `${prefix}/${RecipeActionDrawerTabs.CostAdjustments}`,
    });
  }

  return tabs;
}
