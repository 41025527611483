<div class="supy-create-recipe__creation-details-row">
  <div>
    <label class="supy-create-recipe__creation-details-label" i18n="@@inventory.recipe.createdBy">Created By</label>
    <div class="supy-create-recipe__creation-details-content">
      {{ details?.createdBy?.firstName }} {{ details?.createdBy?.lastName }}
    </div>
  </div>
  <div>
    <label class="supy-create-recipe__creation-details-label" i18n="@@inventory.recipe.createdAt">Created At</label>
    <div class="supy-create-recipe__creation-details-content">
      {{ details && (details.createdAt | timezone: ianaTimeZone | date) }}
    </div>
  </div>
  <div>
    <label class="supy-create-recipe__creation-details-label" i18n="@@inventory.recipe.lastModifiedBy"
      >Last Modified By</label
    >
    <div class="supy-create-recipe__creation-details-content">
      {{ details?.lastModifiedBy?.firstName }} {{ details?.lastModifiedBy?.lastName }}
    </div>
  </div>
  <div>
    <label class="supy-create-recipe__creation-details-label" i18n="@@inventory.recipe.lastModifiedAt"
      >Last Modified At</label
    >
    <div class="supy-create-recipe__creation-details-content">
      {{ details && (details.lastModifiedAt | timezone: ianaTimeZone | date) }}
    </div>
  </div>
</div>
