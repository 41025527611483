import { SkeletonObjectType } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';
import { WastageType } from '@supy/settings';

import { InventoryEvent, InventoryEventItem, InventoryEventSearchItem } from './inventory-event.entity';
import { InventoryEventStateEnum } from './inventory-event.model';

export class InventoryWastageEvent extends InventoryEvent {
  readonly location: SkeletonObjectType;
  readonly items: InventoryWastageEventItem[];
  readonly state: InventoryEventStateEnum;

  constructor(args: NonFunctionProperties<InventoryWastageEvent>) {
    super(args);

    this.items = args.items;
  }
}

export class InventoryWastageEventItem extends InventoryEventItem {
  readonly wastageType: WastageType | undefined;
  readonly isStockable: boolean;

  constructor(args: NonFunctionProperties<InventoryWastageEventItem>) {
    super(args);

    this.wastageType = args.wastageType;
    this.isStockable = args.isStockable;
  }

  static default(): InventoryWastageEventItem {
    return {
      ...super.default(),
      wastageType: undefined,
      isStockable: false,
    };
  }

  static deserialize(args: NonFunctionProperties<InventoryWastageEventItem>): InventoryWastageEventItem {
    return new InventoryWastageEventItem({ ...args });
  }

  static deserializeList(data: NonFunctionProperties<InventoryWastageEventItem>[]): InventoryWastageEventItem[] {
    return data.map(inventoryWastageEventItem => InventoryWastageEventItem.deserialize(inventoryWastageEventItem));
  }
}

export class InventoryWastageSearchItem extends InventoryEventSearchItem {
  readonly isStockable: boolean;

  constructor(args: NonFunctionProperties<InventoryWastageSearchItem>) {
    super(args);

    Object.assign(this, args);
  }

  static deserialize(args: NonFunctionProperties<InventoryWastageSearchItem>): InventoryWastageSearchItem {
    return new InventoryWastageSearchItem({ ...args, ...InventoryEventSearchItem.deserialize(args) });
  }

  static deserializeList(data: NonFunctionProperties<InventoryWastageSearchItem>[]): InventoryWastageSearchItem[] {
    return data.map(inventoryWastageSearchItem => InventoryWastageSearchItem.deserialize(inventoryWastageSearchItem));
  }

  static from(args: NonFunctionProperties<InventoryWastageEventItem>): InventoryWastageSearchItem {
    return InventoryWastageSearchItem.deserialize({
      ...InventoryEventSearchItem.from(args),
      isStockable: args.isStockable,
    });
  }
}

export class WastageItemGrid extends InventoryWastageEventItem {
  readonly item?: InventoryWastageSearchItem;

  constructor(args: NonFunctionProperties<WastageItemGrid>) {
    super(args);
    Object.assign(this, args);
  }

  static deserialize(args: NonFunctionProperties<WastageItemGrid>): WastageItemGrid {
    return new WastageItemGrid({ ...args });
  }

  static deserializeList(data: NonFunctionProperties<WastageItemGrid>[]): WastageItemGrid[] {
    return data.map(wastageItemGrid => WastageItemGrid.deserialize(wastageItemGrid));
  }

  static from(args: NonFunctionProperties<InventoryWastageEventItem>): WastageItemGrid {
    return WastageItemGrid.deserialize({
      ...InventoryWastageEventItem.deserialize(args),
      item: InventoryWastageSearchItem.from(args),
    });
  }
}
