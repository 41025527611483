import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Query } from '@supy/common';

import { INVENTORY_STOCK_COUNT_BFF_URI } from '../../../config';
import {
  InventoryVariance,
  InventoryVarianceReport,
  InventoryVarianceRequestProps,
  SaveVarianceReportRequest,
  SubmitVarianceReportRequest,
  VarianceReportTotals,
} from '../core';

@Injectable({ providedIn: 'root' })
export class InventoryVarianceService {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(INVENTORY_STOCK_COUNT_BFF_URI) private readonly bffUri: string,
  ) {}

  getReport(query: Query<InventoryVariance & InventoryVarianceRequestProps>): Observable<InventoryVarianceReport> {
    return this.httpClient.get<InventoryVarianceReport>(`${this.bffUri}/variance-report`, {
      params: new HttpParams({
        fromObject: query.toQueryParams(),
      }),
    });
  }

  submitReport(stockCountId: string, body: SubmitVarianceReportRequest): Observable<void> {
    return this.httpClient.patch<void>(`${this.bffUri}/${stockCountId}/submit`, body);
  }

  saveReport(stockCountId: string, body: SaveVarianceReportRequest): Observable<void> {
    return this.httpClient.patch<void>(`${this.bffUri}/${stockCountId}/save`, body);
  }

  getTotals(stockCountId: string): Observable<VarianceReportTotals> {
    return this.httpClient.get<VarianceReportTotals>(`${this.bffUri}/${stockCountId}/variance-report/totals`);
  }
}
