<supy-dialog
  class="supy-edit-location"
  (dialogClosed)="onDialogClosed($event)"
  (dialogOpened)="onDialogOpened($event)"
  (dialogOpening)="onDialogOpening($event)"
  (dialogClosing)="onDialogClosing($event)"
  width="40rem"
>
  <supy-dialog-header class="supy-edit-location__header">
    <h3 class="supy-edit-location__header-title">Edit location(s)</h3>
  </supy-dialog-header>
  <supy-dialog-content>
    <div class="supy-edit-location__form">
      <form [formGroup]="form">
        <div class="supy-edit-location__form-control">
          <span>PAR Level</span>
          <supy-input placeholder="PAR Level" name="min" formControlName="min"></supy-input>
        </div>

        <div class="supy-edit-location__form-control">
          <span>Max Level</span>
          <supy-input placeholder="Max Level" name="max" formControlName="max"></supy-input>
        </div>
      </form>
    </div>
  </supy-dialog-content>
  <supy-dialog-footer class="supy-edit-location__footer">
    <supy-button
      type="button"
      class="supy-edit-location__danger-btn"
      color="secondary"
      name="cancel"
      (buttonClick)="dialog.closeDialog()"
    >
      <supy-icon name="close" size="extra-small"></supy-icon>
      <span>Cancel</span>
    </supy-button>
    <supy-button color="primary" name="save-changes" (buttonClick)="onEditLocation()"> Save Changes </supy-button>
  </supy-dialog-footer>
</supy-dialog>
