import {
  EventTime,
  getApiDetailsDecorator,
  IdType,
  IQueryResponse,
  LocalizedData,
  LocalizedSkeletonObjectType,
  SimpleUser,
  Uom,
} from '@supy/common';
import { NonFunctionProperties } from '@supy/core';

import {
  InventoryStockCountEventState,
  InventoryStockCountItemResponse,
  InventoryStockCountItemScope,
  InventoryStockCountPackagingResponse,
} from './stock-count.model';
import { InventoryStockCountPackaging } from './stock-count-packaging.entity';

export const StockCountEventTimeMap: Record<EventTime, string> = {
  [EventTime.StartOfDay]: 'SOD',
  [EventTime.EndOfDay]: 'EOD',
};

export class InventoryEventDate {
  readonly creationDate: Date;
  readonly time: EventTime;
}

export class BaseInventoryStockCount {
  readonly id: string;
  readonly state: InventoryStockCountEventState;
  readonly createdBy?: SimpleUser;
  readonly updatedBy?: SimpleUser;
  readonly createdAt?: Date;
  readonly updatedAt?: Date;
}

const ApiProperty = getApiDetailsDecorator<InventoryStockCountItemResponse>();

export class InventoryStockCountItem {
  protected constructor(args: NonFunctionProperties<InventoryStockCountItem>) {
    this.id = args.id;
    this.name = args.name;
    this.category = args.category;
    this.cost = args.cost;
    this.atomCost = args.atomCost;
    this.scope = args.scope;
    this.baseUnit = args.baseUnit;
    this.hasHiddenPackaging = args.hasHiddenPackaging;
    this.onHandQuantity = args.onHandQuantity;
    this.onHandValue = args.onHandValue;
    this.countedQuantity = args.countedQuantity;
    this.externalTotalCount = args.externalTotalCount;
    this.localTotalCount = args.localTotalCount;
    this.countedValue = args.countedValue;
    this.packagings = args.packagings;
    this.defaultPackaging = args.defaultPackaging;
    this.otherPackages = args.otherPackages;
    this.compoundId = args.compoundId;
    this.rowId = args.rowId;
    this.retailerItemId = args.retailerItemId;
    this.code = args.code;
  }

  @ApiProperty() readonly id: string | null;
  @ApiProperty() readonly name: LocalizedData;
  @ApiProperty() readonly category: LocalizedSkeletonObjectType;
  @ApiProperty() readonly cost: number;
  @ApiProperty() readonly scope: InventoryStockCountItemScope;
  @ApiProperty() readonly baseUnit: Uom;
  @ApiProperty() readonly onHandQuantity: number;
  @ApiProperty() readonly onHandValue: number;
  @ApiProperty() countedQuantity: number;
  @ApiProperty() readonly countedValue: number;
  @ApiProperty() readonly retailerItemId: string;
  @ApiProperty() readonly hasHiddenPackaging: boolean;
  @ApiProperty() readonly packagings: InventoryStockCountPackaging[];
  @ApiProperty() readonly code?: string;
  readonly compoundId: string[];
  readonly rowId: string;
  readonly atomCost?: number;
  externalTotalCount: number;
  localTotalCount: number;
  defaultPackaging: InventoryStockCountPackaging;
  otherPackages: InventoryStockCountPackaging[];
  isExpanded: boolean;

  static deserialize(data: InventoryStockCountItemResponse): InventoryStockCountItem {
    const rowId = crypto.randomUUID().slice(0, 6);
    const packagings = data.packagings.map(packaging =>
      InventoryStockCountPackaging.deserialize(packaging, {
        itemRowId: rowId,
      }),
    );

    const compoundId = packagings.map(({ unit }) =>
      data.scope?.referenceId
        ? `${data.scope.referenceId}_${unit.packagingId ?? unit.uomId}`
        : `${unit.packagingId ?? unit.uomId}`,
    );

    const parsedPackages = packagings.reduce(
      (acc, item) => {
        if (item.isDefault && !acc.default) {
          acc.default = item;
        }

        if (!acc.uom && item.unit.uomId && !item.unit.packagingId) {
          acc.uom = item;
        }

        acc.totalCount += item.partialCount * item.unit.toBaseUom;

        return acc;
      },
      { default: null, uom: null, totalCount: 0 } as {
        default?: InventoryStockCountPackaging;
        uom: InventoryStockCountPackaging;
        totalCount: number;
      },
    );

    const packagingsTotalCount = parsedPackages.totalCount;

    const defaultPackaging = parsedPackages.default ?? parsedPackages.uom ?? packagings[0];
    const otherPackages = packagings.filter(itemPackaging => itemPackaging !== defaultPackaging);
    const conversionToAtom = defaultPackaging?.unit?.toAtomUom ?? data.baseUnit.conversionToAtom;
    const totalCount = data.countedQuantity ?? 0;

    return new InventoryStockCountItem({
      id: data.id ?? null,
      code: data.code,
      scope: data.scope,
      name: data.name,
      baseUnit: data.baseUnit,
      retailerItemId: data.retailerItemId,
      hasHiddenPackaging: data.hasHiddenPackaging ?? false,
      cost: data.cost * conversionToAtom,
      atomCost: data.cost,
      onHandQuantity: data.onHandQuantity ?? 0,
      onHandValue: data.onHandValue ?? 0,
      countedQuantity: totalCount,
      localTotalCount: packagingsTotalCount,
      externalTotalCount: packagingsTotalCount === totalCount ? 0 : totalCount - packagingsTotalCount,
      countedValue: data.countedValue ?? 0,
      category: data.category,
      packagings,
      compoundId: compoundId ?? [],
      defaultPackaging,
      otherPackages,
      rowId,
      isExpanded: false,
    });
  }

  static deserializeList(data: InventoryStockCountItemResponse[]): InventoryStockCountItem[] {
    return data.map(item => this.deserialize(item));
  }

  static computedPackagingsTotalCount(packagings: InventoryStockCountPackagingResponse[]): number {
    return packagings.reduce((acc, { partialCount, unit }) => acc + partialCount * unit.toBaseUom, 0);
  }
}

export class InventorySubStockCountSnapShot {
  readonly id: string;
  readonly name: string;
  readonly stockValue: number;
  readonly state: InventoryStockCountEventState;
  readonly itemsNumber: number;
}

export class InventoryStockCount extends BaseInventoryStockCount {
  readonly retailer: IdType;
  readonly location: IdType;
  readonly eventDate: InventoryEventDate;
  readonly subStockCounts: InventorySubStockCountSnapShot[];
  readonly subStockCount: InventorySubStockCountSnapShot;
  readonly isOpening?: boolean;
}

export interface GetStockCountItemsResponse {
  readonly id: string;
  readonly state: InventoryStockCountEventState;
  readonly eventDate: InventoryEventDate;
  readonly isOpening: boolean;
  readonly retailerId: string;
  readonly location: IdType;
  readonly name?: string;
  readonly items: IQueryResponse<InventoryStockCountItem>;
}

interface StockCountSnapshot {
  readonly id: string;
  readonly state: InventoryStockCountEventState;
  readonly eventDate: Date;
  readonly timing?: EventTime;
  readonly isOpening: boolean;
}

export interface GetSubStockCountItemsResponse {
  readonly id: string;
  readonly name?: string;
  readonly retailerId: string;
  readonly stockCount: StockCountSnapshot;
  readonly location: IdType;
  readonly items: IQueryResponse<InventoryStockCountItem>;
}

export interface GetCommonStockCountDatesRequest {
  readonly locations: IdType[];
}

export interface DateGroupedStockCounts {
  readonly eventDate: Date;
  readonly isOpening?: boolean;
}
export interface GetCommonStockCountDatesOutput {
  readonly allCountDates: DateGroupedStockCounts[];
  readonly someCountDates: DateGroupedStockCounts[];
}

export interface GetFirstEventDateResponse {
  readonly firstEventDate: Date;
}

export interface GetLastOpeningCountDateResponse {
  readonly lastOpeningCountDate: Date;
}
